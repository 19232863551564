import { Subscription } from "rxjs";
import { ReportComponent } from "src/app/dataset/datapoints/datapoints-analytics/report.component";
import { DatapointsPageStateService } from "src/app/dataset/datapoints/datapoints-page-state.service";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import { ReportRow } from "src/app/model/datapoint/report/count/report-row";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { DatapointProjection } from "src/app/model/datapoint/projection/datapoint-projection";
import { DatapointFilterObject } from "src/app/model/datapoint/datapoint-filter-object";
import { SortOrder } from "src/app/model/filter/draft-filter-sort";
import { DatapointsService } from "src/app/data-access-layer/datapoints/datapoints.service";
import { ProjectedDatapoint } from "src/app/model/datapoint/projected-datapoint";
import { DateUtils } from "../utils/date-utils";
import { NumberUtils } from "../utils/number-utils";
import { ReportType } from "src/app/model/analytics/report-type";
import { Sort } from "@angular/material/sort";
import { DownloadReportTableRequest } from "src/app/model/download/download-report-table-request";
import {
    TableColumn,
    TableColumnAlignment,
    TableColumnType,
} from "src/app/model/upload/table/table-column";
import { TableRow } from "src/app/model/upload/table/table-row";
import { TableCell } from "src/app/model/upload/table/table-cell";
import { DatasetUtils } from "../utils/dataset-utils";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { ObjectUtils } from "../utils/object-utils";
import { isUndefined } from "../utils/util-master";
import { TreeStructureUtils } from "../utils/tree-structure-utils";
import { Datapoints } from "src/app/dataset/datapoints/datapoints";
import { DatasetFieldSpecificType } from "src/app/model/dataset/dataset-field-specific.type";

export class Ranking implements ReportComponent {
    downloadReportParams: {
        selectedFields: any;
        selectedRankingField: any;
        dynamicColumns: any;
        totalCount: any;
        downloadReportData: any;
        reportName: any;
        selectedFormula: any;
        sumsByFieldId: any;
    };
    sort: Sort = {
        active: null,
        direction: null
    };
    datapointObject = new Datapoints();
    constructor(
        public readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly datapointService: DatapointsService
    ) {}

    rankingData: {
        id: number;
        reportName: string;
        reportType: string;
        sequence: number;
        selectedRankingName: string;
        reportSubType:
            | ReportDisplayType.TABLE
            | ReportDisplayType.PIE_CHART
            | ReportDisplayType.BAR_CHART;
        noOfItems: number;
        selectedRankingField: DatasetField;
        selectedFormula: WorkspaceItem;
        table: {
            dynamicColumns: Map<string, string>; // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
            columnsToDisplay: string[];
            sumsByFieldId: Map<string, number>;
            reportData: ReportRow[];
            downloadReportData: ReportRow[];
        };
        selectedFields: DatasetField[];
        datasetFields: DatasetField[];
        groupByDatasetFields: DatasetField[];
        tessadataFields: {
            nriFields: DatasetField[];
            externalFields: DatasetField[];
        };
        tessadataGroupedFields: any[];
        formulas: WorkspaceItem[];
        tableDownloadRequest: DownloadReportItem;
        isTwoDimensionReport: boolean;
        sort: Sort,
        rankingTreeStrcuture: any,
        rankingTreeControl: any,
        rankingDataSource: any,
        columnsToDisplayTreeStrcuture: any,
        columnsToDisplayTreeControl: any,
        columnsToDisplayDataSource: any
    }[] = [
        {
            id: 0,
            reportName: "Ranking",
            reportType: ReportType.RANKING,
            sequence: 0,
            selectedRankingName: "",
            reportSubType: ReportDisplayType.TABLE,
            noOfItems: 0,
            selectedRankingField: undefined,
            table: {
                dynamicColumns: new Map(), // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
                columnsToDisplay: [],
                sumsByFieldId: new Map<string, number>(),
                reportData: [],
                downloadReportData: [],
            },
            selectedFields: [],
            datasetFields: [],
            groupByDatasetFields: [],
            tessadataFields: { nriFields: [], externalFields: [] },
            tessadataGroupedFields: [],
            formulas: [],
            selectedFormula: undefined,
            tableDownloadRequest: null,
            isTwoDimensionReport: this.isTwoDimensionReport(),
            sort: {
                active: null,
                direction: null
            },
            rankingTreeStrcuture: null,
            rankingTreeControl: null,
            rankingDataSource: null,
            columnsToDisplayTreeStrcuture: null,
            columnsToDisplayTreeControl: null,
            columnsToDisplayDataSource: null
        },
    ];

    readonly RANKING_COLUMN_ID = "ranking";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    private readonly subscriptions: Subscription = new Subscription();

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public process(
        dataset: Dataset,
        aggregateCount: number,
        analytic,
        data,
        datasetFields: DatasetField[],
        formulas: WorkspaceItem[],
        rankingFieldsData: any,
        groupsIds: any
    ) {
        this.rankingData[aggregateCount] = {
            id: 0,
            reportName: analytic.name,
            reportType: ReportType.RANKING,
            sequence: analytic.sequence,
            selectedRankingName: "",
            reportSubType: ReportDisplayType.TABLE,
            noOfItems: 0,
            selectedRankingField: undefined,
            table: {
                dynamicColumns: new Map(), // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
                columnsToDisplay: [],
                sumsByFieldId: new Map<string, number>(),
                reportData: [],
                downloadReportData: [],
            },
            selectedFields: [],
            datasetFields: [],
            groupByDatasetFields: [],
            tessadataFields: { nriFields: [], externalFields: [] },
            tessadataGroupedFields: [],
            formulas: [],
            selectedFormula: undefined,
            tableDownloadRequest: null,
            isTwoDimensionReport: this.isTwoDimensionReport(),
            sort: isUndefined(data.sort) ? this.sort : data.sort,
            rankingTreeStrcuture: null,
            rankingTreeControl: null,
            rankingDataSource: null,
            columnsToDisplayTreeStrcuture: null,
            columnsToDisplayTreeControl: null,
            columnsToDisplayDataSource: null
        };
        this.rankingData[aggregateCount].rankingTreeStrcuture = new TreeStructureUtils();
        this.rankingData[aggregateCount].rankingTreeControl = this.rankingData[aggregateCount].rankingTreeStrcuture.getTreeControl();
        this.rankingData[aggregateCount].rankingDataSource =this.rankingData[aggregateCount].rankingTreeStrcuture.getDataSource();
        this.rankingData[aggregateCount].columnsToDisplayTreeStrcuture = new TreeStructureUtils();
        this.rankingData[aggregateCount].columnsToDisplayTreeControl = this.rankingData[aggregateCount].columnsToDisplayTreeStrcuture.getTreeControl();
        this.rankingData[aggregateCount].columnsToDisplayDataSource =this.rankingData[aggregateCount].columnsToDisplayTreeStrcuture.getDataSource();
        
        this.rankingData[aggregateCount].tessadataFields =
            rankingFieldsData.tessadataFields;
        this.rankingData[aggregateCount].tessadataGroupedFields =
            rankingFieldsData.tessadataGroupedFields;
        this.rankingData[aggregateCount].rankingDataSource.data = [
            ...this.datapointObject.prepareDataset([dataset], dataset, {type: DatasetFieldSpecificType.NUMBER_FIELD})
        ];
        let tessadataFieldsByDataset = {};
        tessadataFieldsByDataset[dataset.id] = {nriFields: []};
        tessadataFieldsByDataset[dataset.id].nriFields = rankingFieldsData.tessadataFields.nriFields;
        this.rankingData[aggregateCount].columnsToDisplayDataSource.data = [
            ...this.datapointObject.prepareDataset([dataset], dataset),
            ...this.datapointObject.prepareTesadata(this.rankingData[aggregateCount].tessadataGroupedFields, dataset),
            ...this.datapointObject.prepareNRIFields(
                [dataset],
                tessadataFieldsByDataset,
                true,
                dataset
            )
        ]
        this.rankingData[aggregateCount].columnsToDisplayDataSource.data = this.datapointObject.filterAndDelete(this.rankingData[aggregateCount].columnsToDisplayDataSource.data, {isBothTypeCheck: true});
        if (data.selectedRankingField) {
            this.rankingData[aggregateCount].datasetFields = datasetFields;
            this.rankingData[aggregateCount].groupByDatasetFields =
                datasetFields;
            this.rankingData[aggregateCount].datasetFields.forEach((field) => {
                if (field.id === data.selectedRankingField.id) {
                    field.selected = true;
                    this.rankingData[aggregateCount].rankingDataSource.data = this.datapointObject.marksSelected(this.rankingData[aggregateCount].rankingDataSource.data, field.id);
                    this.rankingData[aggregateCount].selectedRankingField =
                        field;
                }
            });
        }

        this.rankingData[aggregateCount].formulas = formulas;
        if (data.selectedFormula) {
            let selectedFormula = formulas.find(
                (f) => f.id === data.selectedFormula.id
            );
            this.rankingData[aggregateCount].selectedFormula.push(
                selectedFormula
            );
        }

        if (data.selectedFields) {
            data.selectedFields.forEach((field) => {
                let datasetField = datasetFields.find((f) => f.id === field.id);
                if (datasetField) {
                    datasetField.selected = true;
                    this.rankingData[aggregateCount].columnsToDisplayDataSource.data = this.datapointObject.marksSelected(this.rankingData[aggregateCount].columnsToDisplayDataSource.data, datasetField.id);
                    this.rankingData[aggregateCount].selectedFields.push(
                        datasetField
                    );
                }
            });
        }
        const selectedFieldIds = data.selectedFields.map(function (
            result
        ) {
            return result["id"];
        })
        if (selectedFieldIds.length) {
            this.markSelectedFields(rankingFieldsData.tessadataFields.nriFields, selectedFieldIds)
        }
        if (this.rankingData[aggregateCount].tessadataGroupedFields !== undefined && Object.keys(this.rankingData[aggregateCount].tessadataGroupedFields).length) {
            for (const key in this.rankingData[aggregateCount].tessadataGroupedFields) {
                if (Object.prototype.hasOwnProperty.call(this.rankingData[aggregateCount].tessadataGroupedFields, key)) {
                    const element = this.rankingData[aggregateCount].tessadataGroupedFields[key];
                    this.markSelectedFields(element, ObjectUtils.clone(selectedFieldIds));
                }
            }
        }
        this.rankingData[aggregateCount].noOfItems = data.noOfItems;

        if (
            data.selectedFields.length > 0 &&
            (data.selectedRankingField || data.selectedFormula) &&
            data.noOfItems
        ) {
            return this.generateReport(
                this.rankingData[aggregateCount],
                dataset,
                groupsIds
            );
        }
    }

    markSelectedFields(array: any[], selectedIds: string[]): void {
        for (const item of array) {
            if (item.child && Array.isArray(item.child)) {
                this.markSelectedFields(item.child, selectedIds);
            } else if (item.child && selectedIds.includes(item.child.id)) {
                item.child.selected = true;
            } else  if (selectedIds.includes(item.id)) {
                item.selected = true;
            }
        }
    }


    generateReport(report: any, dataset: Dataset, groupsIds: any) {
        // if there is selected a formula but not a numeric field
        if (report.selectedFormula && !report.selectedRankingField) {
            //   this.notif.error('For a selected formula - Please select a Locations field too.');
            return;
        }
        const projection = this.prepareProjection(report, dataset);
        this.populateTableColumnsList(report);
        let reportRequest = this.createDatapointRequest(
            report,
            dataset,
            projection,
            groupsIds
        );

        this.datapointService
            .getDatapointsByFilter(reportRequest)
            .subscribe((datapoints) => {
                this.computeTotalSums(report, datapoints);
                this.convertDataToTableFormat(report, datapoints);
                this.populateTableColumnsList(report);
                if (report.sort && !isUndefined(report.sort)) {
                    report = this.sortData(report.sort, report);
                } else {
                    report = this.prepareDownloadReportParams(report);
                }
            });
        return report;
    }

    private populateTableColumnsList(report: any) {
        report.table.dynamicColumns = new Map();
        report.table.columnsToDisplay = [this.RANKING_COLUMN_ID];

        report.selectedFields.forEach((field) => {
            report.table.dynamicColumns.set(field.id, field.name);
            report.table.columnsToDisplay.push(field.id);
        });

        if (report.selectedRankingField) {
            report.table.dynamicColumns.set(
                report.selectedRankingField.id,
                report.selectedRankingField.name
            );
            report.table.columnsToDisplay.push(report.selectedRankingField.id);
        }

        if (report.selectedFormula) {
            report.table.dynamicColumns.set(
                report.selectedFormula.id,
                report.selectedFormula.name
            );
            report.table.columnsToDisplay.push(report.selectedFormula.id);
        }
    }

    private prepareProjection(report: any, dataset: Dataset) {
        let datapointProjection: DatapointProjection = {
            datasetID: dataset.id,
            fields: [],
            geometryPrecision: 25,
            links: [],
        };
        report.selectedFields.forEach((field) => {
            datapointProjection.fields.push(field.id);
        });
        if (report.selectedRankingField) {
            datapointProjection.fields.push(report.selectedRankingField.id);
        }
        if (report.selectedFormula) {
            datapointProjection.formulas.push(report.selectedFormula);
        }
        return datapointProjection;
    }

    private createDatapointRequest(
        report: any,
        dataset: Dataset,
        datapointProjection: DatapointProjection,
        groupsIds: any
    ): DatapointFilterObject {
        return {
            filter: { datasetID: dataset.id, groups: groupsIds },
            limit: report.noOfItems ? report.noOfItems : 0,
            skip: 0,
            projection: datapointProjection,
            sort: {
                datasetID: dataset.id,
                fields: [
                    {
                        id: report.selectedRankingField.id,
                        sortOrder: SortOrder.DESCENDANT,
                    },
                ],
            },
        };
    }

    private computeTotalSums(report: any, datapoints: ProjectedDatapoint[]) {
        report.table.sumsByFieldId = new Map<string, number>();
        datapoints.forEach((datapoint) => {
            datapoint.fields.forEach((field) => {
                if (field.numberValue) {
                    if (!report.table.sumsByFieldId.get(field.id)) {
                        report.table.sumsByFieldId.set(
                            field.id,
                            field.numberValue
                        );
                    } else {
                        report.table.sumsByFieldId.set(
                            field.id,
                            report.table.sumsByFieldId.get(field.id) +
                                field.numberValue
                        );
                    }
                }
            });
            if (datapoint.formulaResults) {
                if (
                    !report.table.sumsByFieldId.get(
                        report.selectedFormula.id.toString()
                    )
                ) {
                    report.table.sumsByFieldId.set(
                        report.selectedFormula.id.toString(),
                        datapoint.formulaResults[0]
                    );
                } else {
                    report.table.sumsByFieldId.set(
                        report.selectedFormula.id.toString(),
                        report.table.sumsByFieldId.get(
                            report.selectedFormula.id.toString()
                        ) + datapoint.formulaResults[0]
                    );
                }
            }
        });
        return report;
    }

    private convertDataToTableFormat(
        report: any,
        datapoints: ProjectedDatapoint[]
    ) {
        report.table.reportData = [];
        report.table.downloadReportData = [];
        let index = 1;
        datapoints.forEach((datapoint) => {
            let tableEntry: ReportRow = {
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    report,
                    datapoint
                ),
                ranking: index,
            };
            index++;
            report.table.downloadReportData.push(tableEntry);
        });
        report.table.reportData = report.table.downloadReportData.slice(0, 247);
        if (report.sort && !isUndefined(report.sort)) {
            report = this.sortData({
                active: report.sort.active,
                direction: report.sort.direction
            }, report);
        }
    }

    getDynamicFieldValuesByIds(
        report: any,
        datapoint: ProjectedDatapoint
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        datapoint.fields.forEach((field) => {
            let value;
            if (field.numberValue) {
                value = field.numberValue;
            }
            if (field.textValue) {
                value = field.textValue;
            }
            if (field.datetimeValue) {
                value = DateUtils.parseDate(field.datetimeValue);
            }
            if (field.textArrayValue) {
                value = field.textArrayValue;
            }
            if (field.numberArrayValue) {
                value = NumberUtils.formatNumber(field.numberArrayValue);
            }
            if (field.datetimeArrayValue) {
                value = DateUtils.parseDate(field.datetimeArrayValue);
            }
            if (!value) {
                value = "N/A";
            }

            tableEntries.set(field.id, value);
        });
        if (datapoint.formulaResults) {
            let value;
            value = datapoint.formulaResults[0];
            if (!value) {
                value = "N/A";
            }
            tableEntries.set(report.selectedFormula.id.toString(), value);
        }
        return tableEntries;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        let reportHeader = this.getTableReportHeader();
        let reportRows = this.getTableReportRows();
        let reportFooter = this.getTableReportFooter();
        let title = this.downloadReportParams.reportName || "Ranking";

        return new DownloadReportTableRequest(
            title,
            reportHeader,
            reportRows,
            reportFooter
        );
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        throw new Error("Method not implemented.");
    }
    getReportDownloadRequest(): DownloadReportItem {
        throw new Error("Method not implemented.");
    }
    isTwoDimensionReport(): boolean {
        return false;
    }
    getDisplayType(): ReportDisplayType {
        throw new Error("Method not implemented.");
    }

    sortData(sort: Sort, report: any) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        report.sort.direction = sort.direction;
        report.sort.active = fieldId;
        let sortedData = report.table.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.RANKING_COLUMN_ID:
                    return this.compare(a.ranking, b.ranking, isAsc);
                default: {
                    let sanatizeValue = (item: any) => {
                        let value = item.dynamicFieldValuesByIds.get(fieldId);
                        return value === 'N/A' ? "" : value;
                    };

                    let aValue = sanatizeValue(a);
                    let bValue = sanatizeValue(b);
                    return this.compare(aValue, bValue, isAsc);
                }
            }
        });

        report.table.reportData = [...sortedData];
        report.table.downloadReportData = [...sortedData];
        report = this.prepareDownloadReportParams(report);
        return report;
    }

    private compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];
        columns.push({
            id: this.RANKING_COLUMN_ID,
            name: "Ranking",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        this.downloadReportParams.selectedFields.forEach((field) => {
            columns.push({
                id: field.id,
                name: field.name,
                type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                horizontalAlignment: TableColumnAlignment.RIGHT,
            });
        });
        columns.push({
            id: this.downloadReportParams.selectedRankingField.id,
            type: TableColumnType.DECIMAL,
            name: this.downloadReportParams.selectedRankingField.name,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        if (this.downloadReportParams.selectedFormula) {
            columns.push({
                id: this.downloadReportParams.selectedFormula.id.toString(),
                type: TableColumnType.DECIMAL,
                name: this.downloadReportParams.selectedFormula.name,
                horizontalAlignment: TableColumnAlignment.RIGHT,
            });
        }
        return columns;
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportParams.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            columns.push({
                id: this.RANKING_COLUMN_ID,
                value: row.ranking,
            });
            this.downloadReportParams.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            rows.push({ cells: columns });
        });

        return rows;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.downloadReportParams.selectedFields.forEach((field) => {
            if (
                DatasetUtils.getDatasetFieldType(field) ===
                DatasetFieldType.NUMBER
            ) {
                cells.push({
                    id: field.id,
                    value: this.downloadReportParams.sumsByFieldId.get(
                        field.id
                    ),
                });
            } else {
                cells.push({ id: this.BLANK_COLUMN_ID, value: "" });
            }
        });
        cells.push({
            id: this.downloadReportParams.selectedRankingField.id,
            value: this.downloadReportParams.sumsByFieldId.get(
                this.downloadReportParams.selectedRankingField.id
            ),
        });
        if (this.downloadReportParams.selectedFormula) {
            cells.push({
                id: this.downloadReportParams.selectedFormula.id.toString(),
                value: this.downloadReportParams.sumsByFieldId.get(
                    this.downloadReportParams.selectedFormula.id.toString()
                ),
            });
        }
        // cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces

        return { cells: cells };
    }

    prepareDownloadReportParams(report) {
        this.downloadReportParams = {
            selectedFields: report.selectedFields,
            selectedRankingField: report.selectedRankingField,
            dynamicColumns: report.table.dynamicColumns,
            totalCount: report.table.totalCount,
            downloadReportData: report.table.downloadReportData,
            reportName: report.reportName,
            selectedFormula: report.selectedFormula,
            sumsByFieldId: report.table.sumsByFieldId,
        };
        report.tableDownloadRequest =
            this.getTableReportDownloadRequest();
        return report;
    }
}

interface Child {
    id: string;
    selected: boolean;
}