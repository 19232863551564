import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { LatLngLiteral } from '@agm/core/services/google-maps-types';
import { DatapointsPageStateService } from '../dataset/datapoints/datapoints-page-state.service';
import { GISUtils } from '../core/utils/gis-utils';
import { BoundsFilter } from '../model/geometry/bounds-filter';
import { Type } from '../model/geometry/geometry-filter-shape';
import { DatasetType } from '../model/dataset/dataset-type';
import { DatapointFilterObject } from '../model/datapoint/datapoint-filter-object';
import { Dataset } from '../model/dataset/dataset';
import { DatapointsService } from '../data-access-layer/datapoints/datapoints.service';
import { DatapointFilter } from '../model/datapoint/filter/datapoint-filter';
import {percentageForColorIntervals, thematicMapColors, thematicMapOverlayRenderingOptions} from '../core/map/map-thematic-overlay.constants';
import { DatasetStylingOptions } from '../model/dataset/rendering/dataset-styling-options';
import { NotifService } from '../core/notification/notif.service';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class MapThematicOverlayService {
    datasetsByParents: Map<string, Dataset> = new Map();
    datasetIndexes: Map<string, number> = new Map();
    configsHistory: ThematicMapConfig[] = [];
    currentConfig: ThematicMapConfig = {} as ThematicMapConfig;
    store: ReplaySubject<ThematicMapConfig> = new ReplaySubject<ThematicMapConfig>(1);

    mapCoords: BehaviorSubject<any> = new BehaviorSubject([]);
    private readonly customizedLegends: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        private readonly datapoinsPageStateService: DatapointsPageStateService,
        private readonly datapointsService: DatapointsService,
        private readonly notifService: NotifService
    ) {
    }

    onCustomizedLegends(): Observable<any> {
       return this.customizedLegends.asObservable();
    }

    emitCustomizedLegends(CustomizedpercentageForColorIntervals) {
        this.customizedLegends.next(CustomizedpercentageForColorIntervals);
    }


    disableThmeaticMap() {
        this.configsHistory = [];
        this.currentConfig = {} as ThematicMapConfig;
        this.store.next(this.currentConfig);
    }

    onMapClick(zoom: number, coords: LatLngLiteral): void {
        const dataset = this.datasetsByParents.get(this.currentConfig.parentDatasetId);
        if (dataset) {
            this.getGeometryClickData(dataset, zoom, coords);
        }
    }

    onMapRightClick(): void {
        if (this.currentConfig.parentDatasetId === null) {
            return;
        }

        // Remove current
        this.configsHistory.pop();

        const previousLevel = this.configsHistory[this.configsHistory.length - 1];
        this.currentConfig = previousLevel;
        this.setActive(previousLevel);
    }

    enableThematicMap(): void {
        this.prepareDatasetIndexes();
        this.setThematicFirstLevel();
    }

    prepareThematicMapDatasets() {
        this.datapoinsPageStateService.accountOverlays.forEach(overlay => {
            if (overlay.thematicMapSettings && overlay.thematicMapSettings.isThematicMapDataset) {
                let parentId = overlay.thematicMapSettings.parentDatasetId;
                this.datasetsByParents.set(parentId, overlay);
            }
        });
    }

    getGeometryClickData(dataset: Dataset, zoom: number, coords: LatLngLiteral): void {
        let newThematicDataset = this.datasetsByParents.get(dataset.id);
        if (!newThematicDataset) {
            console.log('No child found for thematic map. Stopping here', this.datasetsByParents);
            return; // we are on the last level
        }
        let tolerance = GISUtils.calculateMapClickToleranceDegrees(zoom, dataset.geometryType);
        let boundsFilter: BoundsFilter = {
            top: coords.lat + tolerance,
            bottom: coords.lat - tolerance,
            left: coords.lng - tolerance,
            right: coords.lng + tolerance,
            type: Type.BOUNDS
        };
        let groups = dataset.type === DatasetType.ACCOUNT_APPLICATION ? this.datapoinsPageStateService.activeGroups : [];
        let projectionFields = this.getProjectionFields(dataset);
        let request: DatapointFilterObject = {
            filter: {
                datasetID: dataset.id,
                groups: groups,
                geometryFilter: { intersectionShapes: [boundsFilter], unionShapes: [], coords: coords }
            },
            projection: { datasetID: dataset.id, fields: projectionFields, geometryPrecision: zoom },
            sort: null,
            limit: 20,
            skip: 0
        };


        // here we get the geometry we clicked on
        this.datapointsService.getDatapointsByFilter(request).subscribe(datapoints => {
            let newConfig: ThematicMapConfig;
            if (!datapoints || !datapoints.length) {
                console.log('No datapoints found by map click');
                return;
            } else {
                datapoints[0].fields.some(field => {
                    if (field.id === dataset.thematicMapSettings.mainThematicFieldId) {
                        newConfig = {
                            textValue: field.textValue,
                            dataset: newThematicDataset,
                            parentDatasetId: dataset.id,
                            filter: {
                                datasetID: newThematicDataset.id,
                                fields: []
                            },
                            parentFilter: {
                                datasetID: newThematicDataset.id,
                                fields: [{
                                    id: newThematicDataset.thematicMapSettings.parentLinkFieldId,
                                    textValues: [field.textValue]
                                }]
                            },
                            stylingOptions: {
                                ...newThematicDataset.stylingOptions,
                                type: thematicMapOverlayRenderingOptions.datasetStylingOptions.type,
                                colorizationFieldIndex: thematicMapOverlayRenderingOptions.datasetStylingOptions.colorizationFieldIndex,
                            },
                            geometryID: datapoints[0].geometryID
                        };
                        this.configsHistory.push(newConfig);
                        this.currentConfig = newConfig;
                        return true;
                    }
                    return false;
                });
            }
            this.setActive(newConfig);
        });
    }

    getThematicMapIntervals(min: number, max: number, total: number) {
        let customRange = this.customizedLegends.getValue();
        min = Math.round(min);
        max = Math.round(max);
        let intervals = [];
        let units = 0;
        if (min === max) {
            intervals.push({
                color: thematicMapColors[thematicMapColors.length - 1],
                minValue: min,
                maxValue: Math.round(max + 1),
                total: max
            });
        } else {
            let updatedIntervals = customRange.length > 0 ? customRange : percentageForColorIntervals;
            units = (updatedIntervals[0] / 100) * max;
            // Interval for green
            const maxUnitForGreen = Math.round(units);
            intervals.push({
                color: thematicMapColors[0],
                minValue: 0,
                maxValue: maxUnitForGreen,
                total: max
            });
            units = (updatedIntervals[1] / 100) * max;
            // Interval for yellow
            const maxUnitForYellow = Math.round(units);
            intervals.push({
                color: thematicMapColors[1],
                minValue: maxUnitForGreen,
                maxValue: maxUnitForYellow,
                total: max
            });
            units = (updatedIntervals[2] / 100) * max;
            // Interval for orange
            const maxUnitForOrange = Math.round(units);
            intervals.push({
                color: thematicMapColors[2],
                minValue: maxUnitForYellow,
                maxValue: maxUnitForOrange,
                total: max
            });
            // interval for red
            intervals.push({
                color: thematicMapColors[3],
                minValue: maxUnitForOrange,
                maxValue: null,
                total: max
            });
        }
        return intervals;
    }

    getProjectionFields(dataset: Dataset): string[] {
        let mainSummaryFieldId = dataset.fields[dataset.mainSummaryFieldIndex].id;
        let summaryFieldIds = dataset.fields.filter(field => field.isSummary && field.id !== mainSummaryFieldId).map(field => field.id);
        let thematicFieldId = dataset.fields.filter(field => field.id === dataset.thematicMapSettings.mainThematicFieldId)[0].id;
        return [mainSummaryFieldId, ...summaryFieldIds, thematicFieldId];
    }

    getActive(): Observable<ThematicMapConfig> {
        return this.store.asObservable();
    }

    setActive(data: ThematicMapConfig): void {
        this.store.next(data);
    }

    prepareDatasetIndexes() {
        let root = this.datasetsByParents.get(null).id;
        let lvl2 = this.datasetsByParents.get(root).id;
        let lvl3 = this.datasetsByParents.get(lvl2).id;
        let lvl4 = this.datasetsByParents.get(lvl3).id;
        this.datasetIndexes.set(root, 0);
        this.datasetIndexes.set(lvl2, 1);
        this.datasetIndexes.set(lvl3, 2);
        this.datasetIndexes.set(lvl4, 3);
    }

    setThematicFirstLevel(): void {
        const dataset = this.datasetsByParents.get(null);
        const thematicConfig: ThematicMapConfig = {
            textValue: '',
            parentDatasetId: null,
            dataset: dataset,
            filter: null,
            parentFilter: {
                datasetID: dataset.id,
                fields: []
            },
            stylingOptions: {
                ...dataset.stylingOptions,
                type: thematicMapOverlayRenderingOptions.datasetStylingOptions.type,
                colorizationFieldIndex: thematicMapOverlayRenderingOptions.datasetStylingOptions.colorizationFieldIndex,
            }
        };

        this.currentConfig = thematicConfig;
        this.configsHistory = [thematicConfig];
        this.setActive(thematicConfig);
    }

    reset(): void {
        this.datasetsByParents = new Map();
        this.configsHistory = [];
        this.currentConfig = {} as ThematicMapConfig;
        this.store = new ReplaySubject<ThematicMapConfig>(1);
    }

    setMapCoords = (zoom,coords) => {
        this.mapCoords.next({
            zoom : zoom,
            coords : coords
        });
    }

    setCustomLegendThematicMapIntervals(customizedLegendsFormRef: UntypedFormGroup) {
        let intervals = [];
        const controls = customizedLegendsFormRef.controls;
        intervals.push({
            color: thematicMapColors[0],
            minValue: 0,
            maxValue: controls.green_max.value,
            total: controls.aggregate_total.value
        });
        intervals.push({
            color: thematicMapColors[1],
            minValue: controls.yellow_min.value,
            maxValue: controls.yellow_max.value,
            total: controls.aggregate_total.value
        });
        intervals.push({
            color: thematicMapColors[2],
            minValue: controls.orange_min.value,
            maxValue: controls.orange_max.value,
            total: controls.aggregate_total.value
        });
        intervals.push({
            color: thematicMapColors[3],
            minValue: controls.orange_max.value,
            maxValue: null,
            total: controls.aggregate_total.value
        });
        return intervals;
    }
}

export interface ThematicMapConfig {
    textValue: string;
    parentDatasetId: string;
    dataset: Dataset;
    filter: null | DatapointFilter;
    parentFilter?: null | DatapointFilter;
    stylingOptions: DatasetStylingOptions;
    geometryID?: {
        geometryID: string,
        shardID: number
    }
}
