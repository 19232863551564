import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { IndicatorField } from "src/app/model/dashboard/indicators-data";
import { DatasetFieldFloatingOption } from "src/app/model/dataset/dataset-field-floating-option";

@Component({
    selector: "map-indicators-edit-dialog",
    templateUrl: "./indicators-edit-dialog.component.html",
    styleUrls: ["./indicators-edit-dialog.component.scss"],
})
export class IndicatorsEditDialog {
    readonly TENSORFLIGHT_PREFIX = "tensorflight";
    readonly MARITIME_PREFIX = "maritime";
    readonly LIGHTNING_PREFIX = "lightning";
    readonly MARINE_TRAFFIC_PREFIX = "marine_traffic";
    title: string;
    cancelButton: string;
    confirmButton: string;
    displayedColumns: string[];
    dataSource: MatTableDataSource<any>;
    indicators: any[];

    get indicatorOptions() {
        return Object.keys(DatasetFieldFloatingOption);
    }

    constructor(
        public dialogRef: MatDialogRef<IndicatorsEditDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = "Manage Indicators";
        this.cancelButton = "Cancel";
        this.confirmButton = "Save";
        this.indicators = data.indicators;

        this.dataSource = new MatTableDataSource<any>(
            this.indicators.filter((indicator) =>
                this.filterExternalIndicatorOut(indicator)
            )
        );

        this.displayedColumns = [
            "id",
            "name",
            "isIndicators",
            "indicatorOptions",
        ];
    }

    filterExternalIndicatorOut(indicator: any) {
        const prefixes = [
            this.TENSORFLIGHT_PREFIX,
            this.MARITIME_PREFIX,
            this.LIGHTNING_PREFIX,
            this.MARINE_TRAFFIC_PREFIX,
        ];

        for (const prefix of prefixes) {
            if (indicator.id.includes(prefix)) {
                return false;
            }
        }

        return true;
    }

    setIndicatorOption(field: IndicatorField) {
        if (field.isIndicators) {
            field.indicatorsOption = DatasetFieldFloatingOption.SUM;
        } else {
            field.indicatorsOption = null;
        }
    }

    onConfirm(): void {
        this.dialogRef.close(this.indicators);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}
