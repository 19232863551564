<div class="public-content">
	<div class="public-text">
		<img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
	</div>
	<div class="public-form">
		<form [formGroup]="loginForm">
			<mat-form-field>
				<mat-label>Email</mat-label>
				<input matInput trimValue autocomplete="off" [errorStateMatcher]="matcher" required formControlName="email">
				<mat-error *ngIf="loginForm.controls.email.hasError('email') && !loginForm.controls.email.hasError('required')">
					Please enter a valid email address
				</mat-error>
				<mat-error *ngIf="loginForm.controls.email.hasError('required')">
					Email is required
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Password</mat-label>
				<input matInput autocomplete="off" [errorStateMatcher]="matcher" required formControlName="password" type="password">
				<mat-error *ngIf="loginForm.controls.email.hasError('required')">
					Password is required
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="show2FACodeInput">
				<mat-label>2FA Code</mat-label>
				<input matInput autocomplete="off" formControlName="code" type="text">
			</mat-form-field>
			<div class="link-button"
			     [routerLink]="['/public/forgot-password']">Forgot password?
			</div>
			<button mat-stroked-button
			        color="primary"
					class="lg"
			        [disabled]="loginForm.invalid || isLoading"
			        (click)="onSubmit()">Login
			</button>
		</form>
	</div>
</div>
