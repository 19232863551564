import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { isEnabled } from "../../../environments/environment";
import { EventNotificationSettings } from "../../model/notifications/event-notification-settings";
import { AuthService } from "../../auth/auth.service";
import { EventNotificationService } from "../../data-access-layer/notification/event-notification.service";
import { Account } from "../../model/account/account";
import { AccountMember } from "../../model/member/account-member";
import { Dataset } from "../../model/dataset/dataset";
import { Constants, DistanceUnit } from "../../constants";
import { DatapointsPageStateService } from "../datapoints/datapoints-page-state.service";
import { DatasetGeometryType } from "../../model/dataset/dataset-geometry-type";
import { Functionalities } from "../../../environments/app-functionalities";
import { AlertsType } from "src/app/model/notifications/alerts-type";

const IS_PART_OF = Functionalities.DATAPOINTS;
@Component({
    selector: "map-event-notifications",
    templateUrl: "./event-notifications.component.html",
    styleUrls: ["./event-notifications.component.scss"],
})
export class EventNotificationsComponent implements OnInit {
    @Input() account: Account;
    @Input() dataset: Dataset;
    @Input() groupsIds: number[];
    @Input() externalOverlays: Dataset[];
    @Input() isOpenedInProfile: boolean;
    @Input() MMSI: string = null;

    isEnabled = isEnabled(IS_PART_OF);
    notifications: EventNotificationSettings[] = [];
    eligibleOverlays: Dataset[] = [];
    currencies: string[];

    private accountMember: AccountMember;

    constructor(
        private readonly eventNotifService: EventNotificationService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly authService: AuthService,
        private readonly changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const pointAndComplexOverlays = this.getEligibleLinkedOverlays();
        this.externalOverlays = this.formatExternalOverlays();

        this.eligibleOverlays = [
            ...pointAndComplexOverlays,
            ...this.externalOverlays,
        ];
        let principal = this.authService.getPrincipal();
        if (!principal.isSuperadmin) {
            this.accountMember = principal.membership.find(
                (member) => member.accountID === this.account.id
            );

            if (!this.isOpenedInProfile) {
                this.fetchAllNotifications();
            } else {
                this.fetchNotificationsWithMMSI();
            }
        }
        this.eventNotifService
            .getCurrencies()
            .subscribe((currencies) => (this.currencies = currencies));
    }

    fetchAllNotifications() {
        this.eventNotifService
            .getNotificationsForMember(this.accountMember.id)
            .subscribe((notifications) => {
                this.notifications = notifications;
                this.changeDetector.detectChanges();
            });
    }

    fetchNotificationsWithMMSI() {
        this.eventNotifService
            .getNotificationsWithMMSI(this.accountMember.id, this.MMSI)
            .subscribe((notifications) => {
                this.notifications = notifications;
                this.changeDetector.detectChanges();
            });
    }

    getEligibleLinkedOverlays() {
        const eligibleLinkedOverlays = this.datapointsPageStateService
            .getLinkedAccountOverlays()
            ?.filter((overlay) => {
                return (
                    (overlay.isRefreshable &&
                        overlay.geometryType === DatasetGeometryType.POINT) ||
                    overlay.geometryType === DatasetGeometryType.COMPLEX
                );
            });

        if (!eligibleLinkedOverlays?.length) {
            return [];
        }

        return eligibleLinkedOverlays.map((overlay) => {
            return {
                ...overlay,
                overlayAlertType: this.getOverlayAlertType(overlay),
            };
        });
    }

    getOverlayAlertType(overlay) {
        switch (overlay.geometryType) {
            case DatasetGeometryType.COMPLEX:
                return AlertsType.COMPLEX;
            case DatasetGeometryType.POINT:
            default:
                return AlertsType.POINT;
        }
    }

    formatExternalOverlays(): Dataset[] {
        const formattedOverlays = [];

        this.externalOverlays.forEach((overlay) => {
            const formattedFields = [];
            if (overlay?.fields?.length) {
                overlay.fields.forEach((field) => {
                    formattedFields.push({
                        id: field?.fieldId,
                        name: field?.fieldLabel,
                        type: field?.fieldType,
                        baseType: field?.fieldType,
                        isGenrated: false,
                        isHighCardinality: true,
                        tags: [],
                        selected: false,
                    });
                });
            }
            formattedOverlays.push({
                ...overlay,
                overlayAlertType: AlertsType.EXTERNAL,
                fields: formattedFields,
            });
        });

        return formattedOverlays;
    }

    addNotification() {
        this.notifications.push({
            datasetId: this.dataset.id,
            locationBreakdownFieldId: "",
            memberId: this.accountMember.id,
            notificationName: "",
            overlayId: "",
            currency: Constants.DEFAULT_CURRENCY,
            radius: Constants.DEFAULT_RADIUS,
            distanceUnit: DistanceUnit.KM,
            fieldID: "",
            regions: [],
            locationDatapoints: [],
            emails: [],
            type: AlertsType.POINT,
        });
    }

    deleteEventNotif(notif: EventNotificationSettings) {
        let index = this.notifications.indexOf(notif);
        this.notifications.splice(index, 1);
    }
}
