import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    SimpleChanges,
    ViewContainerRef,
} from "@angular/core";
import { Subscription, fromEvent } from "rxjs";
import { take, throttleTime } from "rxjs/operators";
import { NotifService } from "src/app/core/notification/notif.service";
import { AerisService } from "src/app/data-access-layer/aeris.service";
import {
    ExternalFieldsPrefix,
    IsImageUploaded,
    ShipImage,
    ShipProfilePanel,
    VesselImageExtension,
    VesselInternalKeys,
} from "./models";
import { DateUtils } from "src/app/core/utils/date-utils";
import { FormControl } from "@angular/forms";
import { DatapointsService } from "src/app/data-access-layer/datapoints/datapoints.service";
import { DatapointField } from "src/app/model/datapoint/datapoint-field";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { DatasetFieldSpecificType } from "src/app/model/dataset/dataset-field-specific.type";
import {
    ID_FIELD_TAG,
    MMSI_FIELD_TAG,
} from "src/app/fields/field/field.constants";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { environment, isEnabled } from "src/environments/environment";
import { ShipsService } from "src/app/services/ships/ships.service";
import { Account } from "@aerisweather/javascript-sdk/dist";
import { Dataset } from "src/app/model/dataset/dataset";
import { DownloadService } from "src/app/data-access-layer/download/download.service";
import { AttachmentUtils } from "src/app/core/utils/attachment-utils";
import { PoiClosestFilter } from "src/app/model/datapoint/filter/poi-closest-filter";
import { DistanceUnit, SIDE_PANEL_WIDTH } from "src/app/constants";
import { UserStateService } from "src/app/auth/user-state-service";
import { DatapointsPageStateService } from "../datapoints-page-state.service";
import { Functionalities } from "src/environments/app-functionalities";
import { Datapoint } from "src/app/model/datapoint/datapoint";
import { SidePanelComponent } from "src/app/core/side-panel/side-panel.component";
import { SidePanelService } from "src/app/shared/services/side-panel.service";
import { SidePanels } from "src/app/shared/services/side-panel.helper";
import { DataPointsServiceState } from "src/app/shared/services/datapoints-service-state";
import { MatDialog } from "@angular/material/dialog";
import { DialogModel } from "src/app/model/dialog/dialog-model";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";

type UpdateDataPointComponentType = {
    dataset: Dataset;
};

@Component({
    selector: "map-vessel-profile",
    templateUrl: "./vessel-profile.component.html",
    styleUrls: ["./vessel-profile.component.scss"],
})
export class VesselProfileComponent {
    @Input() vesselDetails: any = null;
    @Input() account: Account;
    @Input() dataset: Dataset;
    @Input() groupsIds: number[];
    @Input() externalOverlays: Dataset[];

    @Output() closed = new EventEmitter();
    @Output() datapointDeleted = new EventEmitter();
    @Output() onMoveModeEnabled: EventEmitter<Datapoint> = new EventEmitter();
    moveModeEnabled = false;

    isExpanded = false;
    vessel: any;
    // expand panel
    oldX = 0;
    grabber = false;
    subscription = new Subscription();
    PANEL_WIDTH = SIDE_PANEL_WIDTH;
    width: number;
    dragWidth: number;
    isAppliedWrap: boolean;
    isAccountAdmin: boolean;
    previousSelectedValue: any;

    isProfileDataFetching: boolean;

    readonly shipProfilePanel = ShipProfilePanel;
    profilePanels = [
        ShipProfilePanel.INTERNAL,
        ShipProfilePanel.MARINE_TRAFFIC,
        ShipProfilePanel.MARITIME,
    ];
    selectedProfilePanel = new FormControl(this.profilePanels[0]);

    datasetFields: { [key: string]: DatasetField } = {};
    fields: any[];
    shipImage: string | null;
    vesselMMSI: string;
    poiClosestSubmittedFilter: PoiClosestFilter;
    hasWriteAccess = false;
    alterDatapointsEnabled = isEnabled(Functionalities.ALTER_DATAPOINTS);
    updateDatapointPanel: ComponentRef<SidePanelComponent>;
    updating: boolean;

    isImageUploaded: boolean;

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldScope() {
        return DatasetFieldScope;
    }

    constructor(
        private readonly changeDetector: ChangeDetectorRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly notifService: NotifService,
        private readonly dpService: DatapointsService,
        private readonly shipService: ShipsService,
        private readonly downloadService: DownloadService,
        private readonly userStateService: UserStateService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly sidePanelService: SidePanelService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly datapointsServiceState: DataPointsServiceState,
        public readonly dialog: MatDialog
    ) {}

    ngOnInit() {
        this.width = this.PANEL_WIDTH;
        this.dragWidth = this.width;
        this.hasWriteAccess = this.userStateService.hasWriteAccess(
            this.datapointsPageStateService.activeAccountId
        );
        const activeAccount = this.userStateService.principal.membership.find(
            (params) =>
                params.accountID ==
                this.datapointsPageStateService.activeAccountId
        );
        this.isAccountAdmin =
            activeAccount !== undefined && activeAccount.isAccountAdmin
                ? true
                : false;

        if (!this.userStateService.isSuperadmin) {
            this.profilePanels.push(ShipProfilePanel.NOTIFICATIONS);
        }
    }

    private fetchDatapoint() {
        this.dpService
            .getDatapoint(
                this.vesselDetails?.datapointID,
                this.vesselDetails?.dataset.id
            )
            .subscribe((datapoint) => {
                datapoint.fields = this.getFilteredDatapointFields(
                    datapoint.fields
                );
                this.vessel = datapoint;
                this.fields = [];
                datapoint.fields.forEach((field) => {
                    let value =
                        field.textValue ||
                        field.numberValue ||
                        field.datetimeValue;

                    if (
                        value &&
                        !this.datasetFields[field.id].isGenerated &&
                        !this.datasetFields[field.id].tags.includes(
                            ID_FIELD_TAG
                        )
                    ) {
                        if (
                            this.datasetFields[field.id].tags.includes(
                                MMSI_FIELD_TAG
                            )
                        ) {
                            this.vesselMMSI = field.textValue;
                            this.fetchShipImage(field.textValue);
                        }
                        this.fields.push(
                            this.formatField(field, this.datasetFields)
                        );
                    }
                });

                this.vessel.marineTrafficFields = this.transformFieldsByPrefix(
                    ExternalFieldsPrefix.MARINE_TRAFFIC
                );
                this.vessel.maritimeFields = this.transformFieldsByPrefix(
                    ExternalFieldsPrefix.MARITIME
                );
                this.changeDetector.detectChanges();
            });
    }

    private fetchShipImage(MMSI: string) {
        this.shipService
            .getShipImageByMMSI(this.account.id, MMSI)
            .subscribe((response: ShipImage) => {
                this.shipImage = response.imageUrl || null;
                this.isImageUploaded =
                    response.isImageUploaded === IsImageUploaded.YES;

                if (response) this.changeDetector.detectChanges();
            });
    }

    transformFieldsByPrefix(prefix) {
        const fields = this.vessel.fields.filter((field) =>
            field.id.startsWith(prefix)
        );

        const formattedFields = fields.map((field) => {
            return this.formatField(field, this.datasetFields);
        });

        return formattedFields?.filter((field) => field.value);
    }

    formatField(field, datasetFields) {
        let value = field.textValue || field.numberValue || field.datetimeValue;
        return {
            id: field.id,
            value,
            name:
                datasetFields[field.id].displayName ??
                datasetFields[field.id].name,
            type: datasetFields[field.id].type,
            isSummary: datasetFields[field.id].isSummary,
            scope: datasetFields[field.id].scope,
        };
    }

    getFilteredDatapointFields(datapointFields): DatapointField[] {
        let fields: DatapointField[] = [];
        const datasetFieldIds = new Set(
            this.vesselDetails?.dataset.fields.map((field) => field.id)
        );

        if (datapointFields?.length) {
            datapointFields.forEach((element) => {
                if (datasetFieldIds.has(element.id)) {
                    fields.push(element);
                }
            });
        } else {
            fields = datapointFields;
        }

        return fields;
    }

    onExpandedClick(expanded?: boolean) {
        this.isExpanded = expanded ?? !this.isExpanded;
        if (!expanded) {
            this.closed.emit();
        }
        this.width = this.PANEL_WIDTH;
        this.dragWidth = this.width;
        this.isAppliedWrap = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updating = false;
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                if (propName === "vesselDetails") {
                    this.initContext();
                }
            }
        }
    }

    initContext() {
        if (this.vesselDetails) {
            this.selectedProfilePanel = new FormControl(this.profilePanels[0]);
            this.vessel = {
                longitude: this.vesselDetails?.location.x,
                latitude: this.vesselDetails?.location.y,
            };
            this.vesselDetails?.dataset.fields.forEach((field) => {
                this.datasetFields[field.id] = field;
            });
            this.shipImage = null;
            this.fetchDatapoint();
        }
    }

    formatExternalField(field: any): string {
        let fieldValue = field.value || "";

        if (fieldValue) {
            if (field.type === DatasetFieldSpecificType.DATE_FIELD) {
                return DateUtils.parseShipProfileDateAndTime(fieldValue);
            }
        }

        return fieldValue;
    }

    downloadLocationProfile($event) {
        $event.preventDefault();
        this.poiClosestSubmittedFilter = this.generatePOIClosestFilter();
        this.downloadService
            .downloadLocationProfile(
                this.vesselDetails.datapointID,
                this.dataset.id,
                this.poiClosestSubmittedFilter
            )
            .subscribe(
                (response) => AttachmentUtils.downloadFile(response),
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }

    generatePOIClosestFilter(): PoiClosestFilter {
        return {
            latitude: this.vesselDetails.location.y,
            longitude: this.vesselDetails.location.x,
            externalDatasetId: "",
            distanceInMeters: 100000,
            distanceUnit: DistanceUnit.KM,
            limit: 5,
        };
    }

    enableEditMode(): void {
        if (this.alterDatapointsEnabled) {
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            if (this.updateDatapointPanel) {
                this.updateDatapointPanel.instance.hidePanel();
            }
            this.updating = true;
            this.updateDatapointPanel =
                this.sidePanelService.open<UpdateDataPointComponentType>(
                    SidePanels.DATAPOINT_PROFILE,
                    {
                        id: "update-datapoint-panel",
                        width: 400,
                        panelTitle: "Edit Datapoint",
                        panelIcon: "fa-pencil",
                    },
                    {
                        dataset: this.vesselDetails.dataset,
                    }
                );

            this.datapointsServiceState.onUpdateDataPointInit$.subscribe(() => {
                const { componentRef } = this.updateDatapointPanel.instance;
                if (componentRef) {
                    componentRef.instance.setDataset(this.vesselDetails);
                }
            });
            this.datapointsServiceState.datapointUpdate$.subscribe((value) => {
                if (value === true) {
                    this.onUpdated();
                    this.updateDatapointPanel.instance.closePanel();
                } else {
                    this.notUpdated();
                }
            });
        }
    }

    onUpdated() {
        this.updating = false;
        this.changeDetector.detectChanges();
        this.fetchDatapoint();
        if (this.updateDatapointPanel)
            this.updateDatapointPanel.instance.hidePanel();
    }

    notUpdated() {
        this.updating = false;
        this.changeDetector.detectChanges();
        if (this.updateDatapointPanel)
            this.updateDatapointPanel.instance.hidePanel();
    }

    deleteDatapoint() {
        this.dpService
            .deleteDatapoint(this.dataset.id, this.vesselDetails.datapointID)
            .subscribe((response) => {
                if (response === null) {
                    this.notifService.error(
                        "Datapoint is deleted successfully"
                    );
                    this.onExpandedClick(false);
                    this.datapointDeleted.emit();
                }
            });
    }

    onRemoveVesselImageDialog(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete vessel image?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.removeVesselImage();
                }
            });
    }

    removeVesselImage() {
        const prevImage = this.shipImage;
        this.shipService
            .removeVesselImage(this.account.id, this.vesselMMSI)
            .subscribe(
                (response) => {
                    this.shipImage = response.imageUrl;
                    this.isImageUploaded = false;

                    this.changeDetector.detectChanges();
                    this.notifService.success(
                        "Vessel image removed successfully"
                    );
                },
                (onError) => {
                    this.shipImage = prevImage;
                    this.changeDetector.detectChanges();
                    this.notifService.error(
                        "Something went wrong during vessel image removing"
                    );
                }
            );
    }

    onVesselImageUpload(event: any) {
        const prevImage = this.shipImage;
        this.shipImage = null;
        let file = event.target.files;
        this.shipService
            .uploadVesselImage(file, this.account.id, this.vesselMMSI)
            .subscribe(
                (response) => {
                    this.shipImage = response.imageUrl;
                    this.isImageUploaded = true;
                    this.changeDetector.detectChanges();
                    this.notifService.success(
                        "Vessel image uploaded successfully"
                    );
                },
                (onError) => {
                    this.shipImage = prevImage;
                    this.changeDetector.detectChanges();
                    this.notifService.error(
                        "Something went wrong during vessel image upload"
                    );
                }
            );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // resize panel
    @HostListener("document:mouseup", ["$event"])
    onMouseUp(event: MouseEvent) {
        if (this.width) {
            this.dragWidth = Math.min(
                Math.max(this.dragWidth, this.width),
                window.innerWidth
            );
        }

        this.grabber = false;
        this.subscription.unsubscribe();
        if (this.dragWidth === this.PANEL_WIDTH) {
            this.isAppliedWrap = false;
        }
    }

    resizer(offsetX: number) {
        this.dragWidth -= offsetX;
    }

    onMouseDown(event: MouseEvent) {
        this.grabber = true;
        this.oldX = event.clientX;
        this.subscription.unsubscribe();
        const mousemoveGlobal = fromEvent<MouseEvent>(document, "mousemove");
        this.subscription = mousemoveGlobal
            .pipe(throttleTime(10))
            .subscribe((e) => this.onMouseMove(e));
    }

    onMouseMove(event: MouseEvent) {
        if (!this.grabber) {
            return;
        }
        this.resizer(event.clientX - this.oldX);
        this.oldX = event.clientX;
        this.applyChangeDetection();
        this.isAppliedWrap = true;
    }

    applyChangeDetection() {
        this.changeDetectorRef.detectChanges();
    }
}
