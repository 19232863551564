import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AerisType } from "../core/aeris/AerisType";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class AerisService {
    baseUrl = "https://api.aerisapi.com/";

    constructor(private readonly http: HttpClient) {}

    fetchLocationProfile(
        type: AerisType,
        lat: number,
        long: number
    ): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseUrl}${type}/${lat},${long}?format=json&client_id=${environment.aerisClient}&client_secret=${environment.aerisSecret}`
        );
    }

    fetchAerisDataWithinCircle(
        type: AerisType,
        lat: number,
        lng: number,
        radius: number,
        limit: number
    ) {
        return this.http.get<any[]>(
            `${this.baseUrl}${type}/within?p=${lat},${lng}&radius=${radius}mi&format=json&limit=${limit}&client_id=${environment.aerisClient}&client_secret=${environment.aerisSecret}`
        );
    }

    fetchAerisDataWithinRectangle(
        type: AerisType,
        north: number,
        west: number,
        south: number,
        east: number,
        limit: number
    ) {
        return this.http.get<any[]>(
            `${this.baseUrl}${type}/within?p=${north},${west},${south},${east}&format=json&limit=${limit}&client_id=${environment.aerisClient}&client_secret=${environment.aerisSecret}`
        );
    }

    fetchAerisDataWithinPolygon(
        type: AerisType,
        points: string,
        limit: number
    ) {
        return this.http.get<any[]>(
            `${this.baseUrl}${type}/within?p=${points}&format=json&limit=${limit}&client_id=${environment.aerisClient}&client_secret=${environment.aerisSecret}`
        );
    }

    fetchMaritimeData(lat: number, lng: number, limit: number) {
        return this.http.get<any>(
            `${this.baseUrl}${AerisType.MARITIME}/${lat},${lng}?format=json&limit=${limit}&client_id=${environment.aerisClient}&client_secret=${environment.aerisSecret}`
        );
    }

    fetchLightingData(lat: number, lng: number, limit: number) {
        return this.http.get<any>(
            `${this.baseUrl}${AerisType.LIGHTNING}/${lat},${lng}?format=json&limit=${limit}&client_id=${environment.aerisClient}&client_secret=${environment.aerisSecret}`
        );
    }
}
