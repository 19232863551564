<div [class.collapsed]="!isExpanded" class="panel flex column" [style.minWidth.px]=" width && isExpanded ? width : 0"
    [style.width.px]="width && isExpanded ? dragWidth : 0" (mouseup)="onMouseUp($event)"
    style="height: calc(100% - 58px); top: 18px;right: 32px !important;">
    <div *ngIf="isProfileDataFetching" class="spinner-container">
        <mat-spinner class="spinner" diameter="40" color="accent"></mat-spinner>
    </div>
    <div class="panel-name location-right-panel">
        <div class="panel-title">
            <form>
                <div>
                    <span class="span-panel-title">Vessel Profile</span>
                    <mat-form-field appearance="fill" class="location-profile-dropown-new">
                        <mat-select matNativeControl [formControl]="selectedProfilePanel"
                            class="location-address-select">
                            <mat-option *ngFor="let panel of profilePanels" [value]="panel">&nbsp;{{ panel
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="panel-actions">
            <mat-icon (click)="onExpandedClick(false)" aria-hidden="false">close</mat-icon>
        </div>
    </div>
    <div class="panel-body details-expanded location-panel-body" *ngIf="!isProfileDataFetching && vessel">
        <div class="container location-right-panel-container">

            <div class="ship-image-container" *ngIf="shipImage; else imageLoader">
                <div class="edit-image-overlay">
                    <button class="overlay-icon-button" matTooltip="Recommended size 560 x 250 pixels"
                        (click)="vesselImageInput.click()">
                        <!-- add-image-icon -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-camera-plus">
                            <path
                                d="M12 20h-7a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v3.5" />
                            <path d="M16 19h6" />
                            <path d="M19 16v6" />
                            <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                        </svg>
                        <input #vesselImageInput (change)="onVesselImageUpload($event)" style="display: none"
                            type="file">
                    </button>
                    <button *ngIf="isImageUploaded" class="overlay-icon-button" [matTooltip]="'Remove Image'"
                        (click)="onRemoveVesselImageDialog()">
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path
                                    d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                    stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </g>
                        </svg>
                    </button>
                </div>
                <img [src]="shipImage" alt="">
            </div>
            <ng-template #imageLoader>
                <div class="vessel-image-loader-container">
                    <mat-spinner diameter="40" color="accent"></mat-spinner>
                </div>
            </ng-template>
            <div class="boxed-section location-summary-section-container">
                <div *ngIf="fields?.length">
                    <ng-container *ngFor="let field of fields">
                        <div class="detail-row detail-row-new1" *ngIf="field.isSummary">
                            <span [class.white-space]="isAppliedWrap">{{ field.name }}</span>
                            <span *ngIf="field.type === DatasetFieldSpecificType.DATE_FIELD"
                                [class.white-space]="isAppliedWrap">{{ field.value | maptycsDate: true}}</span>
                            <span *ngIf="field.type === DatasetFieldSpecificType.NUMBER_FIELD"
                                [class.white-space]="isAppliedWrap">{{ field.value | number}}</span>
                            <span
                                *ngIf="field.type !== DatasetFieldSpecificType.NUMBER_FIELD && field.type !== DatasetFieldSpecificType.DATE_FIELD"
                                [class.white-space]="isAppliedWrap">{{ field.value}}</span>
                        </div>
                    </ng-container>
                    <div class="detail-row detail-row-new1">
                        <span>Latitude</span>
                        <span>{{ this.vesselDetails?.location?.y }}</span>
                    </div>
                    <div class="detail-row detail-row-new1">
                        <span>Longitude</span>
                        <span>{{ this.vesselDetails?.location?.x }}</span>
                    </div>

                </div>
            </div>

            <ng-container [ngSwitch]="selectedProfilePanel.value">
                <ng-container *ngSwitchCase="shipProfilePanel.INTERNAL">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{shipProfilePanel.INTERNAL}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container card-padding">
                                <ng-container *ngFor="let field of fields">
                                    <div class="detail-row detail-row-new1" *ngIf="!field.isSummary">
                                        <span [class.white-space]="isAppliedWrap">{{ field.name }}</span>
                                        <span *ngIf="field.type === DatasetFieldSpecificType.DATE_FIELD"
                                            [class.white-space]="isAppliedWrap">{{ field.value | maptycsDate:
                                            true}}</span>
                                        <span *ngIf="field.type === DatasetFieldSpecificType.NUMBER_FIELD"
                                            [class.white-space]="isAppliedWrap">{{ field.value | number}}</span>
                                        <span
                                            *ngIf="field.type !== DatasetFieldSpecificType.NUMBER_FIELD && field.type !== DatasetFieldSpecificType.DATE_FIELD"
                                            [class.white-space]="isAppliedWrap">{{ field.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </ng-container>
                <ng-container *ngSwitchCase="shipProfilePanel.MARINE_TRAFFIC">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Vessel Route</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions *ngIf="vessel?.marineTrafficFields?.length; else marineTrafficNotFound">
                            <div class="boxed-section internal-data-container card-padding">
                                <ng-container *ngFor="let field of vessel.marineTrafficFields">
                                    <ng-container *ngIf="field.value">
                                        <div class="detail-row detail-row-new1">
                                            <span>{{field?.name }}</span>
                                            <span>{{ formatExternalField(field) }}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                        <ng-template #marineTrafficNotFound>
                            <mat-card-actions class="card-padding">
                                <span>Marine traffic data not present for this vessel</span>
                            </mat-card-actions>
                        </ng-template>
                    </mat-card>
                </ng-container>
                <ng-container *ngSwitchCase="shipProfilePanel.MARITIME">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{ shipProfilePanel.MARITIME }}</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions *ngIf="vessel?.maritimeFields?.length; else maritimeDataNotFound">
                            <div class="boxed-section internal-data-container card-padding">
                                <ng-container *ngFor="let field of vessel.maritimeFields">
                                    <ng-container *ngIf="field.value">
                                        <div class="detail-row detail-row-new1">
                                            <span>{{field?.name }}</span>
                                            <span>{{ formatExternalField(field) }}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                        <ng-template #maritimeDataNotFound>
                            <mat-card-actions class="card-padding">
                                <span>Maritime data not present for this location</span>
                            </mat-card-actions>
                        </ng-template>
                    </mat-card>
                </ng-container>
                <ng-container *ngSwitchCase="shipProfilePanel.LIGHTNING">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Lightning</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions *ngIf="vessel?.lightningFields?.length; else lightningDataNotFound">
                            <div class="boxed-section internal-data-container card-padding">
                                <ng-container *ngFor="let field of vessel.lightningFields">
                                    <ng-container *ngIf="field.value">
                                        <div class="detail-row detail-row-new1">
                                            <span>{{field?.name }}</span>
                                            <span>{{ formatExternalField(field) }}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                        <ng-template #lightningDataNotFound>
                            <mat-card-actions class="card-padding">
                                <span>Lightning data not present for this location</span>
                            </mat-card-actions>
                        </ng-template>
                    </mat-card>
                </ng-container>
                <ng-container *ngSwitchCase="shipProfilePanel.NOTIFICATIONS">

                    <mat-card class="notification-card">
                        <mat-card-header>
                            <mat-card-title>Notifications</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <map-event-notifications [dataset]="dataset" [account]="account" [groupsIds]="groupsIds"
                                [externalOverlays]="externalOverlays" [isOpenedInProfile]="true"
                                [MMSI]="vesselMMSI"></map-event-notifications>
                        </mat-card-actions>
                    </mat-card>
                </ng-container>
                <ng-container *ngSwitchDefault></ng-container>
            </ng-container>
        </div>
        <span class="actions-button ellipsis-btn" [matMenuTriggerFor]="actionsMenu">
            <svg width="16" height="16" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                <g fill="#0044f1" fill-rule="evenodd">
                    <circle cx="4.5" cy="1" r="1" />
                    <circle cx="4.5" cy="4" r="1" />
                    <circle cx="4.5" cy="7" r="1" />
                </g>
            </svg>
        </span>
        <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
            <div mat-menu-item (click)="enableEditMode()" *ngIf="hasWriteAccess">
                <span><mat-icon fontIcon="fa-pencil-alt" fontSet="fas"></mat-icon></span>
                Edit
            </div>
            <div mat-menu-item (click)="moveModeEnabled = true; onMoveModeEnabled.next(vessel)"
                *ngIf="(hasWriteAccess || isAccountAdmin)">
                <span><mat-icon fontIcon="fa-expand-arrows" fontSet="far"></mat-icon></span>
                Move
            </div>
            <div mat-menu-item *ngIf="hasWriteAccess" (click)="deleteDatapoint()">
                <span><mat-icon fontIcon="fa-trash-alt" fontSet="fas"></mat-icon></span>
                Delete
            </div>
            <div mat-menu-item (click)="downloadLocationProfile($event)">
                <span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon></span>
                Download Data
            </div>
        </mat-menu>
    </div>
    <div class="grabber" (mousedown)="onMouseDown($event)">
        <i class="fas fa-ellipsis-v"></i>
    </div>
</div>
