<ng-container>
    <div class="panel-container right-panel-container">
        <div class="panel-container container" *ngIf="!isLoading" style="margin-bottom: 28px;padding: 0;">
            <div class="panel-body">
                <div style="padding: 8px !important; background-color: #ffffff; font-size: 0.85rem; font-weight: bold; color: #0044f1;">Address</div>
                <div class="boxed-section summary-section-container">
                    <span>{{ currentPinAddress | async }}</span>
                </div>

                <div style="padding: 8px !important; background-color: #ffffff; font-size: 0.85rem; font-weight: bold; color: #0044f1;">Coordinates (latitude, longitude)</div>
                <div class="boxed-section summary-section-container flex" style="justify-content: space-between">
                    <span class="coordinates" matTooltip="Copy to clipboard" matTooltipPosition="right"
                        (click)="onClickCoordinates()">{{ location.y.toFixed(6) }}, {{ location.x.toFixed(6) }}</span>
                </div>

                <div class="container">
                    <mat-card
                        *ngIf="_dropdownSelectedValue == null ||_dropdownSelectedValue == locationProfile.EXTERNAL"
                        class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title class="title">External</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div *ngIf="locationDetails?.datasets?.length">
                                <ng-container *ngFor="let dataset of locationDetails.datasets">
                                    <div class="detail-row ">
                                        <div *ngIf="dataset.fields.length && avoidDatasets(dataset.datasetId) && isExternalDatasets(dataset.datasetId)"
                                            class="boxed-section summary-section-container">
                                            <ng-container *ngFor="let field of dataset.fields">
                                                <div class="detail-row detail-row-new2">
                                                    <span>{{ field.label }}</span>
                                                    <span [title]="field.value">{{field.value}}</span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.NRI" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title class="title">NRI</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div *ngIf="locationDetails?.datasets?.length">
                                <ng-container *ngFor="let dataset of locationDetails.datasets">
                                    <div class="detail-row ">
                                        <div *ngIf="dataset.fields.length && avoidDatasets(dataset.datasetId) && dataset.datasetId == 'risk_index_tract'"
                                            class="boxed-section summary-section-container">
                                            <ng-container *ngFor="let field of dataset.fields">
                                                <div class="detail-row detail-row-new2">
                                                    <span>{{ field.label }}</span>
                                                    <span [title]="field.value">{{field.value}}</span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.APG" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title class="title">Precisely Wildfire</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div *ngIf="locationDetails?.datasets?.length">
                                <ng-container *ngFor="let dataset of locationDetails.datasets">
                                    <div class="detail-row ">
                                        <div *ngIf="dataset.fields.length && avoidDatasets(dataset.datasetId) && dataset.datasetId == 'noharm'"
                                            class="boxed-section summary-section-container">
                                            <ng-container *ngFor="let field of dataset.fields">
                                                <div class="detail-row detail-row-new2">
                                                    <span>{{ field.label }}</span>
                                                    <span [title]="field.value">{{field.value}}</span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.POI && tessadataEnabled"
                        class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Points of Interest</mat-card-title>
                            <mat-card-subtitle class="subtitle">POI</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" style="padding: 8px 16px;">
                                <div class="section-item">
                                    <div class="section-item-title">Point of Interest</div>
                                    <div>
                                        <mat-form-field color="accent">
                                            <mat-select [(ngModel)]="tessadataPOIDataset" class="location-address-select">
                                                <mat-option
                                                    *ngFor="let ds of testdataInputParameters.tessadataPOIDatasets"
                                                    [value]="ds">
                                                    {{ ds.datasetLabel }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <hr class="menu-line-separator"/>
                                <div class="section-item">
                                    <div class="section-item-title">Distance</div>
                                    <div style="display: flex; align-items: flex-end">
                                        <mat-form-field [floatLabel]="'never'" color="accent"
                                            class="section-item-input">
                                            <input #distanceInput placeholder="Distance"
                                                [(ngModel)]="tessadataPOIDistance" matInput
                                                (keydown)="$event.stopPropagation()" style="bottom: 0 !important;">
                                        </mat-form-field>
                                        <mat-form-field color="accent" style="width: 60px">
                                            <mat-select #distUnit [(value)]="tessadataPOIDistanceUnit" class="location-address-select">
                                                <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                                <mat-option
                                                    [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <hr class="menu-line-separator"/>
                                <div class="section-item">
                                    <div class="section-item-title">Limit</div>
                                    <mat-form-field [floatLabel]="'never'" color="accent" class="section-item-input">
                                        <input type="number" [(ngModel)]="tessadataPOILimit" matInput style="bottom: 0 !important;" />
                                    </mat-form-field>
                                </div>
                                <hr class="menu-line-separator"/>
                                <div class="section-button" style="margin-top: 8px;">
                                    <button color="accent" mat-flat-button (click)="fetchClosestPOI(true)">
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <hr class="menu-line-separator"/>
                            <div *ngIf="tessadataPOIDetails" class="boxed-section internal-data-container"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let poi of tessadataPOIDetails">
                                    <ng-container *ngFor="let poiField of poi.fields">
                                        <div class="detail-row detail-row-new2">
                                            <span>{{ poiField.label }}</span>
                                            <span *ngIf="poiField.type === 'NUMBER'; else notNumber">{{ poiField.value |
                                                number}} <span
                                                    *ngIf="poiField.label === 'Distance'">{{tessadataPOISubmittedDistanceUnit}}</span></span>
                                        </div>
                                        <ng-template #notNumber>
                                            <span>{{ poiField.value }}</span>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-card-actions>
                    </mat-card>

                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.AIR_QUALITY" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Air Quality</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" style="padding: 8px 16px;"
                                *ngIf="airQualityData.length > 0">
                                <ng-container *ngFor="let ad of airQualityData">
                                    <div class="detail-row detail-row-new2">
                                        <span
                                            [style.font-weight]="ad.value === '' ? '900' : 'normal' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="airQualityData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CONDITIONS" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Conditions</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container"
                                *ngIf="meteorologicalConditionsData.length > 0" style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of meteorologicalConditionsData">
                                    <div class="detail-row detail-row-new2">
                                        <span
                                            [style.font-weight]="ad.value === '' ? '900' : 'normal' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="meteorologicalConditionsData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.FORECASTS" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Forecasts</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="forecastData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of forecastData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="forecastData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.TROPICALCYCLONES"
                        class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Tropical Cyclones</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="tropicalCyclonesData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of tropicalCyclonesData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="tropicalCyclonesData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.ALERTS" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Alerts</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="alertsData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of alertsData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="alertsData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CONVECTIVE_AND_OUTLOOK"
                        class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Convective/Outlook</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="convectiveData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of convectiveData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="convectiveData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.DROUGHTS_AND_MONITOR"
                        class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Droughts/Monitor</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="droughtsData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of droughtsData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="droughtsData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.FIRES" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Fires</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="firesData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of firesData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="firesData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.FIRES_AND_OUTLOOK"
                        class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Fires/Outlook</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="firesOutlookData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of firesOutlookData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="firesOutlookData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.PLACES" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Places</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="placesData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of placesData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="placesData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.RIVERS" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Rivers</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="riversData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of riversData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="riversData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.STROMCELLS" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Stormcells</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="stormCellsData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of stormCellsData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="stormCellsData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.THREATS" class="details-box-wrap">
                        <mat-card-header>
                            <mat-card-title>Threats</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="boxed-section internal-data-container" *ngIf="threatsData.length > 0"
                                style="padding: 8px 16px;">
                                <ng-container *ngFor="let ad of threatsData">
                                    <div class="detail-row detail-row-new2">
                                        <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                            [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                                        <span>{{ad.value}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <p *ngIf="threatsData.length === 0">
                                No data for this location.
                            </p>
                        </mat-card-actions>
                    </mat-card>

                    <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CLIMATE">
                        <mat-card-header>
                            <mat-card-title>Climate Data</mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isMaxTemperatureEnabled">
                                <div class="headline">
                                    <div class="title">Maximum Temperature</div>
                                </div>
                                <div echarts [options]="initMaxTempOption" (chartInit)="onMaxTempChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isMeanTemperatureEnabled">
                                <div class="headline">
                                    <div class="title">Mean Temperature</div>
                                </div>
                                <div echarts [options]="initMeanTempOption" (chartInit)="onMeanTempChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isMinTemperatureEnabled">
                                <div class="headline">
                                    <div class="title">Minimum Temperature</div>
                                </div>
                                <div echarts [options]="initMinTempOption" (chartInit)="onMinTempChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isSnowFallEnabled">
                                <div class="headline">
                                    <div class="title">Snowfall</div>
                                </div>
                                <div echarts [options]="initSnowfallOption" (chartInit)="onSnowfallChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isPrecipitationEnabled">
                                <div class="headline">
                                    <div class="title">Precipitation</div>
                                </div>
                                <div echarts [options]="initPrecipitationOption" (chartInit)="onPrecipitationChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isSurfaceWindEnabled">
                                <div class="headline">
                                    <div class="title">Surface Wind</div>
                                </div>
                                <div echarts [options]="initSurfaceWindOption" (chartInit)="onSurfaceWindChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isPopulationDensityEnabled">
                                <div class="headline">
                                    <div class="title">Population Density</div>
                                </div>
                                <div echarts [options]="initPopulationDensityOption"
                                    (chartInit)="onPopulationDensityCharInit($event)" [theme]="maptycsTheme" class="demo-chart">
                                </div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isSeaLevelRiseEnabled">
                                <div class="headline">
                                    <div class="title">Sea Level Rise</div>
                                </div>
                                <div echarts [options]="initSeaLevelRiseOption" (chartInit)="onSeaLevelRiseChartInit($event)"
                                    [theme]="maptycsTheme" class="demo-chart"></div>
                            </div>

                            <div class="tab-content-container" *ngIf="climateOverlayPermission.isSeaLevelRiseWarmingEnabled">
                                <div class="headline">
                                    <div class="title">Sea Level Rise Warming</div>
                                </div>
                                <div echarts [options]="initSeaLevelRiseWarmingOption"
                                    (chartInit)="onSeaLevelRiseWarmingChartInit($event)" [theme]="maptycsTheme"
                                    class="demo-chart">
                                </div>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <span class="actions-button ellipsis-btn " [matMenuTriggerFor]="actionsMenu">
        <!-- ellipsis-icon -->
        <svg width="16" height="16" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg" style="position: relative; left:4px;">
            <g fill="#002d57" fill-rule="evenodd">
                <circle cx="4.5" cy="1" r="1" />
                <circle cx="4.5" cy="4" r="1" />
                <circle cx="4.5" cy="7" r="1" />
            </g>
        </svg>
    </span>
    <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
        <div mat-menu-item (click)="downloadLocationProfile($event)">
            <span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon></span>
            Download Data
        </div>
        <div mat-menu-item [disabled]="_dropdownSelectedValue !== locationProfile.CLIMATE"
            (click)="downloadAdressProfileChart()">
            <span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon></span>
            Download Chart
        </div>
    </mat-menu>
</ng-container>
