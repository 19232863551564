<div class="cd-panel" (click)="setPanelFirst()" [ngClass]="{'cd-panel--is-visible ' :show}" [hidden]="!isOpen">
    <div *ngIf="backdrop" class="backdrop" (click)="hidePanel()"></div>
    <div (click)="showPanel()" [ngStyle]="{'z-index': zIndex}" class="cd-panel-container cd-right-panel-container"
        [class.grabbing]="grabber" [style.minWidth.px]=" width && show ? width : 0"
        [style.width.px]="width && show ? dragWidth : 0" [ngClass]="size ? sizeClass : ''"
        (mouseup)="onMouseUp($event)">
        <header class="panel-name">
            <div class="panel-title" *ngIf="!isAddressLocationProfileCall()">
                <ng-container *ngIf="panelIcon">
                    <span *ngIf="isFontAwesomeIcon()" [class]="getPanelIcon()"></span>
                    <img *ngIf="!isFontAwesomeIcon()" [src]="getPanelIcon()" />
                </ng-container>

                <span style="position: absolute; top: 28px; margin: 1px;">{{panelTitle}}</span>
            </div>
            <div class="panel-title" *ngIf="isAddressLocationProfileCall()">

                <form [formGroup]="selectedGroup">

                    <div style="height: 40px;">
                        <span class="span-panel-title">
                            <ng-container *ngIf="panelIcon">
                                <span *ngIf="isFontAwesomeIcon()" [class]="getPanelIcon()"></span>
                                <img *ngIf="!isFontAwesomeIcon()" [src]="getPanelIcon()" />
                            </ng-container>

                            <span style="margin: 2px;">{{panelTitle}}</span>
                        </span>
                        <mat-form-field appearance="fill" class="address-page-dropdown">
                            <mat-select matNativeControl formControlName="defaultSelected"
                                (selectionChange)="selectionChange($event)" class="location-address-select">
                                <!-- <ng-container *ngFor="let list of menuList">
                                    <mat-option [value]="list[0]">&nbsp;{{list[1]}}</mat-option>
                                </ng-container> -->
                                <mat-option
                                    [value]="DEFAULT_SELECTED_GROUP.value">&nbsp;{{DEFAULT_SELECTED_GROUP.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div class="panel-actions">
                <span (click)="hidePanel($event); onClick()" class="material-icons cd-panel-close-icon">clear</span>
            </div>
        </header>


        <div class="panel-body">
            <div class="grabber" (mousedown)="onMouseDown($event)" *ngIf="resizeable">
                <i class="fas fa-ellipsis-v"></i>
            </div>
            <ng-content></ng-content>
            <div #container></div>
        </div>
    </div>
</div>
