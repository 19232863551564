import {Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Dataset} from '../model/dataset/dataset';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {SidePanelComponent} from '../core/side-panel/side-panel.component';
import {DatapointsPageStateService} from '../dataset/datapoints/datapoints-page-state.service';
import {OverlaysService} from '../data-access-layer/global-overlays/overlays.service';
import {Router} from '@angular/router';
import {SidePanelService} from '../shared/services/side-panel.service';
import {SidePanels} from '../shared/services/side-panel.helper';
import {AccountServiceState} from '../shared/services/account-service-state';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {DialogModel} from '../model/dialog/dialog-model';
import {take} from 'rxjs/operators';
import {NotifService} from '../core/notification/notif.service';
import {RoutingUtils} from '../core/utils/routing-utils';
import {GroupWithOverlaysTreeNode} from '../model/overlay/group/group-with-overlays-tree-node';
import {OverlayGroup} from '../model/overlay/group/overlay-group';
import {Group} from '../model/group/group';

type EditOverlayAccountsComponentType = {
  currentOverlay: Dataset;
}

@Component({
    selector: 'map-external-overlays',
    templateUrl: './external-overlays.component.html',
    styleUrls: ['./external-overlays.component.scss']
})
export class ExternalOverlaysComponent implements OnInit, OnDestroy {

    currentOverlay: Dataset;
    overlays: Dataset[];
    overlaysDataSource: MatTableDataSource<Dataset>;
    currentDisplayedColumns: string[] = ['Name', 'DatasetID', 'Group', 'Actions'];
    isTableDataLoading: boolean;
    editOverlayAccountsPanel: ComponentRef<SidePanelComponent>;
    assignOverlayAccountsPanel: ComponentRef<SidePanelComponent>;

    currentExternaloverlay: Dataset;
    groups: OverlayGroup[];
    groupsByIds: Map<number, Group> = new Map();

    breadCrumbs: ['Home', 'Overlays'];


    constructor(
        private readonly router: Router,
        private readonly overlayService: OverlaysService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly sidePanelService: SidePanelService,
        private readonly accountServiceState: AccountServiceState,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly dialog: MatDialog,
        private readonly notifService: NotifService,
    ) {
        this.isTableDataLoading = true;
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.fetchAllTessadataOverlays();
        this.datapointsPageStateService.activeAccount = undefined;
        this.fetchGroup();
    }

    fetchGroup() {
        this.overlayService.getAllExternalGroups().subscribe((groups) => {
            this.groups = this.flatten(groups).map((groupNode) => {
                this.groupsByIds.set(groupNode.group.id, groupNode.group);   // Set Groups into Map to Display in list
                return groupNode.group;
            });
        });
    }

    get componentName() {
        return this.constructor.name;
    }

    flatten(groupNodes: GroupWithOverlaysTreeNode[]) {
        let flatNodes = [];
        groupNodes.forEach(rootGroup => {
            this.recursiveOperation(rootGroup, group => {
                flatNodes.push(group);
            });
        });
        return flatNodes;
    }

    recursiveOperation(groupNode: GroupWithOverlaysTreeNode, operation: (node: GroupWithOverlaysTreeNode) => void) {
        operation(groupNode);
        groupNode.children.forEach(child => this.recursiveOperation(child, operation));
    }


    fetchAllTessadataOverlays(): void {
        this.overlayService.getAllTessadataOverlays().subscribe((overlays) => {
            this.overlays = overlays;
            this.overlaysDataSource = new MatTableDataSource(overlays);
            this.isTableDataLoading = false;
        }, (err) => {
            console.log(err);
        });
    }


    openSidebar(overlay?: Dataset): void {
        if (this.assignOverlayAccountsPanel && this.currentOverlay && overlay.datasetId === this.currentOverlay.datasetId) {
            this.assignOverlayAccountsPanel.instance.showPanel();
        } else {
            this.currentOverlay = overlay;
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            this.assignOverlayAccountsPanel = this.sidePanelService.open<EditOverlayAccountsComponentType>(SidePanels.EDIT_EXTERNAL_OVERLAYS_ACCOUNT,
                {
                    id: 'overlayAccounts',
                    width: 400,
                    panelTitle: 'Overlay Accounts',
                },
                {
                    currentOverlay: this.currentOverlay
                });
            this.accountServiceState.editOverlaysComponentInit$.pipe(take(1)).subscribe(() => {
                if (this.assignOverlayAccountsPanel) {
                    const {componentRef} = this.assignOverlayAccountsPanel.instance;
                    if (componentRef) {
                        componentRef.instance.init(this.currentOverlay);
                    }
                }
            });

            this.accountServiceState.onEditOverlaySuccess.subscribe(() => {
                if (this.assignOverlayAccountsPanel) {
                    this.assignOverlayAccountsPanel.instance.hidePanel();
                }
            });
        }
    }


    createExternalOverlay(): void {
        this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
        this.editOverlayAccountsPanel = this.sidePanelService.open<any>(SidePanels.CREATE_EXTERNAL_OVERLAY,
            {
                id: 'createTessadata',
                width: 400,
                panelTitle: 'Create Tessadata Overlay',
            },
            {
                groups: this.groups,
                editAction: false
            });
        this.accountServiceState.createExternaloverlayComponentInit$.pipe(take(1)).subscribe(() => {
            if (this.editOverlayAccountsPanel) {
                const {componentRef} = this.editOverlayAccountsPanel.instance;
                if (componentRef) {
                    this.init();
                    this.editOverlayAccountsPanel.instance.hidePanel();
                }
            }
        });
    }

    applyFilter(filterValue: string): void {
        this.overlaysDataSource.filter = filterValue.trim().toLowerCase();
    }

    editOverlay(overlay: Dataset): void {
        // if (this.editOverlayAccountsPanel) {
        //     this.editOverlayAccountsPanel.instance.hidePanel();
        // }
        this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
        this.editOverlayAccountsPanel = this.sidePanelService.open<any>(SidePanels.CREATE_EXTERNAL_OVERLAY,
            {
                id: 'editTessadata',
                width: 400,
                panelTitle: 'Edit Tessadata Overlay',
            },
            {
                currentExternaloverlay: overlay,
                groups: this.groups,
                editAction: true
            });

        this.accountServiceState.createExternaloverlayComponentInit$.pipe(take(1)).subscribe(() => {
            if (this.editOverlayAccountsPanel) {
                const {componentRef} = this.editOverlayAccountsPanel.instance;
                if (componentRef) {
                    this.init();
                    this.editOverlayAccountsPanel.instance.hidePanel();
                }
            }
        });
    }

    deleteOverlay(overlay: Dataset): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                'Confirm Action',
                `Are you sure you want to delete "${overlay.datasetLabel}" overlay?`
            )
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
            if (dialogResult) {
                this.overlayService.deleteExternalOverlay(overlay.datasetId).subscribe((res) => {
                    this.fetchAllTessadataOverlays();
                    this.notifService.success('Successfully deleted overlay');
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.editOverlayAccountsPanel) {
            this.editOverlayAccountsPanel.instance.closePanel();
            this.editOverlayAccountsPanel = null;
        }

        if (this.assignOverlayAccountsPanel) {
            this.assignOverlayAccountsPanel.instance.closePanel();
            this.assignOverlayAccountsPanel = null;
        }
    }


}
