import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
} from "@angular/core";
// import { OverlaysService } from '../../../data-access-layer/global-overlays/overlays.service';
// import { GroupWithOverlaysTreeNode } from '../../../model/overlay/group/group-with-overlays-tree-node';
// import { Dataset } from '../../../model/dataset/dataset';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Dataset } from "src/app/model/dataset/dataset";
import {
    GroupWithOverlaysTreeNode,
    GroupTreeNode,
} from "src/app/model/overlay/group/group-with-overlays-tree-node";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { groupTypes } from "src/app/account/account-groups/account-create-groups-panel/account-create-groups-panel.constants";

@Component({
    selector: "map-group-tree",
    templateUrl: "./group-tree.component.html",
    styleUrls: ["./group-tree.component.scss"],
})
export class GroupTreeComponent implements OnInit, OnChanges {
    isExpanded = true;

    @Input() dataset: Dataset;
    selectedGroupID: number;
    @Output() treeChanged = new EventEmitter<any>();

    // value;
    groupNodes: any[] = [];
    treeValue: number;

    constructor(private readonly groupService: GroupService) {}

    changeEvent(value) {
        this.treeChanged.emit(value);
    }

    ngOnInit(): void {
        // this.overlayService.getOverlayGroupsAsTree(this.accountID).subscribe(groups => { this.groupNodes = groups; console.log("grouptree", groups) });
        this.groupService
            .getGroupsAsTree(this.dataset.id, this.dataset.accountID)
            .subscribe((groups) => {
                this.groupNodes = groups.filter(
                    (element) => element.value.type == groupTypes.ROW_GROUP
                );
                this.treeValue = groups[0].value.id;
                this.changeEvent(this.treeValue);
            });
    }

    ngOnChanges() {}

    groupWasChecked(node, event: MatCheckboxChange) {
        // TODO make a delay between events
        let operation: (group: any) => void; // will be applied recursively
        operation = (group) => {
            group.isSelected = event.checked;
            // group.overlays.forEach(overlay => {
            //   if (event.checked && !overlay.isSelected) { // we must activate it
            //     overlay.isSelected = true;
            //     this.onToggleOverlay.next(overlay);
            //   } else if (!event.checked && overlay.isSelected) { // disableOverlay it
            //     overlay.isSelected = false;
            //     this.onDisableOverlay.next(overlay);
            //   }
            // });
        };
        this.recursiveOperation(node, operation);
    }

    overlayWasToggled(
        overlay: Dataset,
        groupNode: GroupWithOverlaysTreeNode,
        event: MatCheckboxChange
    ) {
        if (event.checked) {
            // this.onActivateGroup.next(overlay);
        } else {
            groupNode.isSelected = false;
            // this.onDisableGroup.next(overlay);
        }
    }

    recursiveOperation(
        groupNode: GroupWithOverlaysTreeNode,
        operation: (node: GroupWithOverlaysTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) =>
            this.recursiveOperation(child, operation)
        );
        // this.changeEvent();
    }
}
