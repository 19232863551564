export enum ShipProfilePanel {
    INTERNAL = "Internal",
    MARINE_TRAFFIC = "Route",
    MARITIME = "Sea Conditions",
    LIGHTNING = "Lightning",
    NOTIFICATIONS = "Notifications",
}

export enum VesselInternalKeys {
    Name = "Name",
    IMO = "IMO",
    MMSI = "MMSI",
}

export enum ExternalFieldsPrefix {
    MARITIME = "maritime",
    LIGHTNING = "lightning",
    MARINE_TRAFFIC = "marine_traffic",
}

export enum VesselImageExtension {
    PNG = "png",
}

export enum IsImageUploaded {
    YES = "YES",
    NO = "NO",
}
export interface ShipImage {
    imageUrl: string;
    isImageUploaded: IsImageUploaded;
}
