export const imageOverlaysGroup = {
    name: 'Image Overlays',
    overlays: [
        {
            id: 'radar_new',
            name: 'Radar',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/radar/zoom/coordX/coordY/offset',
        },
        {
            id: 'satellite_new',
            name: 'Satellite',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/satellite-geocolor/zoom/coordX/coordY/offset',
        },
        {
            id: 'alerts_new',
            name: 'Alerts',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/alerts/zoom/coordX/coordY/offset',
        },
        {
            id: 'stormcells_new',
            name: 'Stormcells',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/stormcells/zoom/coordX/coordY/offset',
        },
        {
            id: 'ftemperatures_new',
            name: 'Forecast Temperatures',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/ftemperatures/zoom/coordX/coordY/offset',
        },
        {
            id: 'ftemperatures-max',
            name: 'Forecast High Temps',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/ftemperatures-max/zoom/coordX/coordY/offset',
        },
        {
            id: 'fqpf-accum',
            name: 'Forecast Precip Accum',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/fqpf-accum/zoom/coordX/coordY/offset',
        },
        {
            id: 'fqsf-accum',
            name: 'Forecast Snow Accum',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/fqsf-accum/zoom/coordX/coordY/offset',
        },
        {
            id: 'fice-accum',
            name: 'Forecast Ice Accum',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/fice-accum/zoom/coordX/coordY/offset',
        },
        {
            id: 'fwind-speeds',
            name: 'Forecast Winds',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/fwind-speeds/zoom/coordX/coordY/offset',
        },
        {
            id: 'fwind-gusts',
            name: 'Forecast Wind Gusts',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/fwind-gusts/zoom/coordX/coordY/offset',
        },
        {
            id: 'heat-index-text',
            name: 'Heat-index - Text',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/heat-index-text/zoom/coordX/coordY/offset',
        },
        {
            id: 'air-quality',
            name: 'Air Quality',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/air-quality/zoom/coordX/coordY/offset',
        },
        {
            id: 'convective',
            name: 'Convective Outlook',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/convective/zoom/coordX/coordY/offset',
        },
        {
            id: 'fires-outlook',
            name: 'Fires Outlook',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/fires-outlook/zoom/coordX/coordY/offset',
        },
        {
            id: 'fdrought-monitor',
            name: 'Drought Monitor',
            offset: 'current.png',
            url: 'https://maps.aerisapi.com/3xBPMX4XHtn7FxUBevi5b_oQ9WB0zr7HGbQMuZz6P36ApF4bJrvtTrXZ3G96xV/drought-monitor/zoom/coordX/coordY/offset',
        },
        // {
        //     id: 'clouds_new',
        //     name: 'Clouds',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'precipitation_new',
        //     name: 'Precipitation',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'pressure_new',
        //     name: 'Sea Level Pressure',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'wind_new',
        //     name: 'Wind Speed',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'temp_new',
        //     name: 'Temperature',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'transport',
        //     name: 'Transport',
        //     apiKey: 'f5f96d3b781b43e18b6a27a6d0fbcd26',
        //     url: 'https://tile.thunderforest.com/transport-dark/zoom/coordX/coordY.png?apikey=apiKey'
        // },
        // {
        //     id: 'rain',
        //     name: 'Rain',
        //     apiKey: '',
        //     url: 'https://tilecache.rainviewer.com/v2/radar/1592046000/256/zoom/coordX/coordY/2/1_1.png'
        // }
    ]
};


export const interactiveOverlaysGroup = {
    name: 'Interactive Overlays',
    overlays: [
        {
            id: 'radar',
            name: 'Radar'
        },
        {
            id: 'satellite',
            name: 'Satellite'
        },
        // {
        //     id: 'alerts',
        //     name: 'Alerts'
        // },
        // {
        //     id: 'stormcells',
        //     name: 'Stormcells'
        // },
        // {
        //     id: 'ftemperatures',
        //     name: 'Forecast Temperatures'
        // },
        // {
        //     id: 'ftemperatures-max',
        //     name: 'Forecast High Temps'
        // },
        // {
        //     id: 'fqpf-accum',
        //     name: 'Forecast Precip Accum'
        // },
        // {
        //     id: 'fqsf-accum',
        //     name: 'Forecast Snow Accum'
        // },
        // {
        //     id: 'fice-accum',
        //     name: 'Forecast Ice Accum'
        // },
        // {
        //     id: 'fwind-speeds',
        //     name: 'Forecast Winds'
        // },
        // {
        //     id: 'fwind-gusts',
        //     name: 'Forecast Wind Gusts'
        // },
        {
            id: 'heat-index-text',
            name: 'Heat-index - Text'
        },
        // {
        //     id: 'air-quality',
        //     name: 'Air Quality'
        // },
        // {
        //     id: 'convective',
        //     name: 'Convective Outlook'
        // },
        // {
        //     id: 'fire-outlook',
        //     name: 'Fires Outlook'
        // },
        // {
        //     id: 'fdrought-monitor',
        //     name: 'Drought Monitor'
        // },
        // {
        //     id: 'clouds_new',
        //     name: 'Clouds',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'precipitation_new',
        //     name: 'Precipitation',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'pressure_new',
        //     name: 'Sea Level Pressure',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'wind_new',
        //     name: 'Wind Speed',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'temp_new',
        //     name: 'Temperature',
        //     apiKey: '4ffac95373daa192d1d2ce46456ebc04',
        //     url: 'https://tile.openweathermap.org/map/overlayId/zoom/coordX/coordY?appid=apiKey'
        // },
        // {
        //     id: 'transport',
        //     name: 'Transport',
        //     apiKey: 'f5f96d3b781b43e18b6a27a6d0fbcd26',
        //     url: 'https://tile.thunderforest.com/transport-dark/zoom/coordX/coordY.png?apikey=apiKey'
        // },
        // {
        //     id: 'rain',
        //     name: 'Rain',
        //     apiKey: '',
        //     url: 'https://tilecache.rainviewer.com/v2/radar/1592046000/256/zoom/coordX/coordY/2/1_1.png'
        // }
    ]
};

export const FetchLimitParam = {
    limit: 10,
    tropicalcyclonesLimit: 50
};

export const numberOfAerisRequestsWithinShapes = 13;
