<div class="container location-right-panel-container">
    <ng-container *ngIf="_datapoint && datasetFields">
        <div class="boxed-section location-summary-section-container">
            <ng-container *ngFor="let field of _datapoint.fields">
                <div class="detail-row detail-row-new1" *ngIf="datasetFields[field.id].isSummary && field.isPresent">
                    <span>{{ datasetFields[field.id].name }}</span>
                    <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{ field.datetimeValue
                        | maptycsDate: true}}</span>
                    <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{ field.numberValue |
                        number}}</span>
                    <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{ field.textValue}}</span>
                </div>
            </ng-container>
            <div class="detail-row detail-row-new1">
                <span>Latitude</span>
                <span>{{ this._datapoint.location.y }}</span>
            </div>
            <div class="detail-row detail-row-new1">
                <span>Longitude</span>
                <span>{{ this._datapoint.location.x }}</span>
            </div>
        </div>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.INTERNAL" class="location-card">
            <mat-card-header>
                <mat-card-title>Internal Data</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" style="padding: 8px 8px;">
                    <ng-container *ngFor="let field of _datapoint.fields">
                        <div class="detail-row detail-row-new1"
                            *ngIf="!datasetFields[field.id].isSummary && field.scope === DatasetFieldScope.INTERNAL && field.isPresent">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>

                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.EXTERNAL && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>External Data</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div *ngIf="externalFields?.length" class="boxed-section internal-data-container">
                    <ng-container *ngFor="let field of externalFields">
                        <div class="detail-row detail-row-new1">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <div>
            <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CLIMATE">
                <mat-card-header>
                    <mat-card-title>Climate Data</mat-card-title>
                </mat-card-header>
                <mat-card-actions>
                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isMaxTemperatureEnabled">
                        <div class="headline">
                            <div class="title">Maximum Temperature</div>
                        </div>
                        <div echarts [options]="initMaxTempOption" (chartInit)="onMaxTempChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isMeanTemperatureEnabled">
                        <div class="headline">
                            <div class="title">Mean Temperature</div>
                        </div>
                        <div echarts [options]="initMeanTempOption" (chartInit)="onMeanTempChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isMinTemperatureEnabled">
                        <div class="headline">
                            <div class="title">Minimum Temperature</div>
                        </div>
                        <div echarts [options]="initMinTempOption" (chartInit)="onMinTempChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSnowFallEnabled">
                        <div class="headline">
                            <div class="title">Snowfall</div>
                        </div>
                        <div echarts [options]="initSnowfallOption" (chartInit)="onSnowfallChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isPrecipitationEnabled">
                        <div class="headline">
                            <div class="title">Precipitation</div>
                        </div>
                        <div echarts [options]="initPrecipitationOption" (chartInit)="onPrecipitationChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSurfaceWindEnabled">
                        <div class="headline">
                            <div class="title">Surface Wind</div>
                        </div>
                        <div echarts [options]="initSurfaceWindOption" (chartInit)="onSurfaceWindChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isPopulationDensityEnabled">
                        <div class="headline">
                            <div class="title">Population Density</div>
                        </div>
                        <div echarts [options]="initPopulationDensityOption"
                            (chartInit)="onPopulationDensityCharInit($event)" [theme]="maptycsTheme" class="demo-chart">
                        </div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSeaLevelRiseEnabled">
                        <div class="headline">
                            <div class="title">Sea Level Rise</div>
                        </div>
                        <div echarts [options]="initSeaLevelRiseOption" (chartInit)="onSeaLevelRiseChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSeaLevelRiseWarmingEnabled">
                        <div class="headline">
                            <div class="title">Sea Level Rise Warming</div>
                        </div>
                        <div echarts [options]="initSeaLevelRiseWarmingOption"
                            (chartInit)="onSeaLevelRiseWarmingChartInit($event)" [theme]="maptycsTheme"
                            class="demo-chart">
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.APG && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>Precisely Wildfire</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div *ngIf="apgFields?.length" class="boxed-section internal-data-container" style="padding: 8px 16px;">
                    <ng-container *ngFor="let field of apgFields">
                        <div class="detail-row detail-row-new1">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.NRI && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>National Risk Index</mat-card-title>
                <mat-card-subtitle class="subtitle">NRI</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <div class="card" *ngIf="summaryCard.visible">
                    <div class="content">
                        <div class="title">{{summaryCard.title}}</div>
                        <div class="indexes-list">
                            <div *ngFor="let property of summaryCard.properties">{{property.label}}: {{property.value}}
                            </div>
                        </div>
                    </div>
                    <div class="index-container" *ngIf="summaryCard.warnSignColor">
                        <mat-icon class="icon" [style.color]="summaryCard.warnSignColor"
                            fontIcon="fa-exclamation-triangle" fontSet="fas"></mat-icon>
                    </div>
                </div>
                <ng-container *ngFor="let card of nriCards">
                    <div class="card" *ngIf="card.visible">
                        <div class="icon-container"><img class="icon" [src]="'assets/icons/' + card.icon"
                                alt="Card Icon"></div>
                        <div class="content">
                            <div class="title">{{card.title}}</div>
                            <div class="indexes-list">
                                <div *ngFor="let property of card.properties">{{property.label}}: {{property.value}}
                                </div>
                            </div>
                        </div>
                        <div class="index-container" *ngIf="card.warnSignColor">
                            <mat-icon class="icon" [style.color]="card.warnSignColor" fontIcon="fa-exclamation-triangle"
                                fontSet="fas"></mat-icon>
                        </div>
                    </div>
                </ng-container>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.POI && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>Points of Interest</mat-card-title>
                <mat-card-subtitle class="subtitle">POI</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" style="padding: 8px 16px;">
                    <div class="section-item">
                        <div class="section-item-title">Point of Interest</div>
                        <div>
                            <mat-form-field color="accent">
                                <mat-select [(ngModel)]="tessadataPOIDataset" class="location-address-select">
                                    <mat-option *ngFor="let ds of tessadataPOIDatasets" [value]="ds">
                                        {{ ds.datasetLabel }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <hr class="menu-line-separator"/>
                    <div class="section-item">
                        <div class="section-item-title">Distance</div>
                        <div style="display: flex; align-items: flex-end">
                            <mat-form-field [floatLabel]="'never'" color="accent" class="section-item-input">
                                <input #distanceInput placeholder="Distance" [(ngModel)]="tessadataPOIDistance" matInput
                                    (keydown)="$event.stopPropagation()" style="bottom: 0 !important;">
                            </mat-form-field>
                            <mat-form-field color="accent" style="width: 60px">
                                <mat-select #distUnit [(value)]="tessadataPOIDistanceUnit" class="location-address-select">
                                    <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                    <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <hr class="menu-line-separator"/>
                    <div class="section-item">
                        <div class="section-item-title">Limit</div>
                        <mat-form-field [floatLabel]="'never'" color="accent" class="section-item-input">
                            <input type="number" [(ngModel)]="tessadataPOILimit" matInput style="bottom: 0 !important;" />
                        </mat-form-field>
                    </div>
                    <hr class="menu-line-separator"/>
                    <div class="section-button" style="margin-top: 8px;">
                        <button color="accent" mat-flat-button (click)="fetchClosestPOI()">
                            Submit
                        </button>
                    </div>
                </div>
                <hr class="menu-line-separator"/>
                <div *ngIf="tessadataPOIDetails" class="boxed-section internal-data-container"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let poi of tessadataPOIDetails">
                        <ng-container *ngFor="let poiField of poi.fields">
                            <div class="detail-row detail-row-new1">
                                <span>{{ poiField.label }}</span>
                                <span *ngIf="poiField.type === 'NUMBER'; else notNumber">{{ poiField.value | number}}
                                    <span
                                        *ngIf="poiField.label === 'Distance'">{{tessadataPOISubmittedDistanceUnit}}</span></span>
                            </div>
                            <ng-template #notNumber>
                                <span>{{ poiField.value }}</span>
                            </ng-template>
                        </ng-container>
                        <div *ngIf="poi.fields.length" class="inner-section-delimiter">
                            <div style="border-bottom: 1px solid #8080805e;"></div>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.AIR_QUALITY">
            <mat-card-header>
                <mat-card-title>Air Quality</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" style="padding: 8px 16px;"
                    *ngIf="airQualityData.length > 0">
                    <ng-container *ngFor="let ad of airQualityData">
                        <div class="detail-row detail-row-new1"
                            [style.visibility]="ad.text === '' ? 'hidden' : 'visible' ">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="airQualityData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CONDITIONS">
            <mat-card-header>
                <mat-card-title>Conditions</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="meteorologicalConditionsData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of meteorologicalConditionsData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="meteorologicalConditionsData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.FORECASTS">
            <mat-card-header>
                <mat-card-title>Forecasts</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="forecastData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of forecastData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="forecastData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.TROPICALCYCLONES">
            <mat-card-header>
                <mat-card-title>Tropical Cyclones</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="tropicalCyclonesData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of tropicalCyclonesData">
                        <div class="detail-row detail-row-new1"
                            [style.visibility]="ad.text === '' ? 'hidden' : 'visible' ">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="tropicalCyclonesData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.ALERTS">
            <mat-card-header>
                <mat-card-title>Alerts</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="alertsData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of alertsData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="alertsData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CONVECTIVE_AND_OUTLOOK">
            <mat-card-header>
                <mat-card-title>Convective/Outlook</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="convectiveData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of convectiveData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="convectiveData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.DROUGHTS_AND_MONITOR">
            <mat-card-header>
                <mat-card-title>Droughts/Monitor</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="droughtsData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of droughtsData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="droughtsData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.FIRES">
            <mat-card-header>
                <mat-card-title>Fires</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="firesData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of firesData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="firesData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.FIRES_AND_OUTLOOK">
            <mat-card-header>
                <mat-card-title>Fires/Outlook</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="firesOutlookData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of firesOutlookData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="firesOutlookData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.PLACES">
            <mat-card-header>
                <mat-card-title>Places</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="placesData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of placesData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="placesData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.RIVERS">
            <mat-card-header>
                <mat-card-title>Rivers</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="riversData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of riversData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="riversData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.STROMCELLS">
            <mat-card-header>
                <mat-card-title>Stormcells</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="stormCellsData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of stormCellsData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="stormCellsData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.THREATS">
            <mat-card-header>
                <mat-card-title>Threats</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="threatsData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of threatsData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="threatsData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.TENSORFLIGHT">
            <mat-card-header>
                <mat-card-title>TensorFlight Data</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div *ngIf="tensorflightFields?.length" class="boxed-section internal-data-container"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let field of tensorflightFields">
                        <div class="detail-row detail-row-new1">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
    </ng-container>
</div>
