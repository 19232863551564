<div class="colorization-label" style="display: flex;">
    Custom Intervals
    <div matTooltip="Save Changes" (click)="applyIntervals()">
        <span style="cursor: pointer;">
             <!-- save-icon -->
             <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                    <path d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <circle stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502"/>
                    <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052"/>
                    <path d="M0 0h24.01v24.01H0z"/>
                </g>
            </svg>
        </span>
    </div>
    
</div>
<div class="color-interval-content" *ngIf="intervals?.length">
    <div *ngFor="let interval of intervals" class="color-interval-row">
        <div class="color-square">
            <input class="box-input-colorization color-picker-input"
                   cpColorMode="true"
                   (colorPicker)="color"
                   formControlName="color"
                   [style.background]="color"
                   (colorPickerChange)="saveColor($event, interval)"
                   [ngStyle]="{'background': ColorUtils.colorToBase64(interval.color)}"
            >
        </div>

        <ng-container *ngIf="interval.edit">
            <div class="color-interval-details" (click)="$event.stopPropagation()">
                <div style="max-width:120px">
                    <input [(ngModel)]="interval.minValue" matInput placeholder="min" type="number" autocomplete="off">
                </div>
                <div style="max-width:120px">
                    <input [(ngModel)]="interval.maxValue" matInput placeholder="max" type="number" autocomplete="off">
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!interval.edit">
            <div (click)="toggleEdit(interval)" matTooltip="Edit interval" matTooltipPosition="right">
                [{{ interval.minValue | number }}, {{ (interval.maxValue | number) || 'any' }}]
            </div>
        </ng-container>

        <button style="margin-left:auto;" color="primary" matTooltip="Delete interval" mat-icon-button>
            <mat-icon (click)="removeInterval(interval)">close</mat-icon>
        </button>
    </div>
</div>
<form [formGroup]="intervalForm" class="color-interval-row">
    <div class="color-square">
        <input class="box-input-colorization location-interval-input"
               (colorPicker)="color"
               formControlName="color"
               [style.background]="color"
        >
    </div>
    <div class="color-interval-details">
        <div>
            <input autocomplete="off"  matInput formControlName="minValue" placeholder="min" type="number">
        </div>
        <div>
            <input autocomplete="off"  matInput formControlName="maxValue" placeholder="max" type="number">
        </div>
    </div>
    <mat-icon (click)="addInterval()">add</mat-icon>
</form>
