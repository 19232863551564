import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthModule} from './auth/auth.module';
import {AccountGroupsComponent} from './account/account-groups/account-groups.component';
import {AccountMembersComponent} from './account/members/members/account-members.component';
import {InvitationComponent} from './account/members/invitation/invitation.component';
import {OverlaysComponent} from './overlays/overlays.component';
import {AccountComponent} from './account/account.component';
import {AuthenticatedGuard} from './auth/guards/authenticated-guard';
import {AccountFieldsComponent} from './fields/fields/account-fields.component';
import {DraftsComponent} from './dataset/drafts/drafts.component';
import {DatapointsComponent} from './dataset/datapoints/datapoints.component';
import {LoginComponent} from './auth/login/login.component';
import {NotAuthenticatedGuard} from './auth/guards/not-authenticated-guard';
import {Constants} from './constants';
import {EditOverlayAccountsComponent} from './overlays/overlay-edit-accounts/edit-overlay-accounts.component';
import {UserSettingsComponent} from './account/user-settings/user-settings.component';
import {PrivateComponent} from './shared/private/private.component';
import {PublicComponent} from './shared/public/public.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {OverlayDatapointsComponent} from './overlays/overlay-datapoints/overlay-datapoints.component';
import {AccountOverlaysComponent} from './account/account-overlays/account-overlays.component';
import {RegisterComponent} from './auth/register/register.component';
import {ConfigGuard} from './auth/guards/config-guard';
import {EditOverlayTreeComponent} from './overlays/edit-overlay-tree/edit-overlay-tree.component';
import {GroupPermissionLoginComponent} from './auth/group-permission-login/group-permission-login.component';
import {AccountPrivateOverlaysComponent} from './account/account-private-overlays/account-private-overlays.component';
import {CreatePrivateOverlayComponent} from './account/account-overlays/create-private-overlay/create-private-overlay.component';
import {EditPrivateOverlayComponent} from './account/account-overlays/edit-private-overlay/edit-private-overlay.component';
import {CreateGlobalOverlayComponent} from './create-global-overlay/create-global-overlay.component';
import {EditGlobalOverlayComponent} from './edit-global-overlay/edit-global-overlay.component';
import {Functionalities} from '../environments/app-functionalities';
import {ForgotPasswordConfirmationComponent} from './auth/forgot-password/confirm/forgot-password-confirm.component';
import {IntegratedClientsComponent} from './integration/integrated-clients.component';
import {SuperadminGuard} from './auth/guards/superadmin-guard';
import {MapComponentTest} from './map/test/map-component-test';
import {GeographicalRegionsComponent} from './geographical-regions/geographical-regions.component';
import {TwoFactorAuthComponent} from './auth/two-factor-auth/two-factor-auth.component';
import {TwoFactorAuthGuard} from './auth/guards/two-factor-auth.guard';
import { ExternalOverlaysComponent } from './external-overlays/external-overlays.component';
import { AccountExternalOverlaysComponent } from './account/account-external-overlays/account-external-overlays.component';
import { EditExternalOverlayTreeComponent } from './external-overlays/edit-external-overlay-tree/edit-external-overlay-tree.component';
import { OverlayComponent } from './overlay/overlay.component';
import { DashboardComponent } from './dataset/dashboard/dashboard.component';

// TODO: add route guards
const routes: Routes = [
    {
        path: '',
        redirectTo: '/private/account',
        pathMatch: 'full'

    },
    {
        path: 'permission-token/:token/group/:groupId',
        component: GroupPermissionLoginComponent
    },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'login',
                canActivate: [NotAuthenticatedGuard],
                component: LoginComponent
            },
            {
                path: 'forgot-password',
                canActivate: [NotAuthenticatedGuard],
                component: ForgotPasswordComponent
            },
            {
                path: 'forgot-password/confirm/:token',
                component: ForgotPasswordConfirmationComponent
            },
            {
                path: 'user/:userId/register/:token',
                component: RegisterComponent
            },
            {
                path: 'two-factor-auth/:email',
                component: TwoFactorAuthComponent,
                // canActivate: [TwoFactorAuthGuard]
            },
        ]
    },
    {
        path: 'private/account/:accountId/dataset/:datasetId',
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: 'drafts',
                component: DraftsComponent,
                canActivate: [ConfigGuard],
                data: { partOf: [Functionalities.DRAFTS] }
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [ConfigGuard],
                data: { partOf: [Functionalities.DRAFTS] }
            },
            {
                path: 'datapoints/:' + Constants.VIEW_MODE_PARAM_NAME,
                component: DatapointsComponent,
                canActivate: [ConfigGuard],
                data: { partOf: [Functionalities.DATAPOINTS]}
            }

        ]
    },
    {
        path: 'private/global-overlays/:datasetId/drafts',
        canActivate: [AuthenticatedGuard, ConfigGuard],
        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
        component: DraftsComponent,
    },
    {
        path: 'private/global-overlays/:datasetId/datapoints/:viewMode',
        canActivate: [AuthenticatedGuard, ConfigGuard],
        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
        component: OverlayDatapointsComponent,
    },
    {
        path: 'private',
        component: PrivateComponent,
        canActivate: [AuthenticatedGuard],
        children: [

            {
                path: 'account/user-settings',
                component: UserSettingsComponent,
            },
            {
                path: 'account',
                component: AccountComponent

            },
            {
                path: 'account/:accountId/dataset/:datasetId/datapoints',
                pathMatch: 'full',
                redirectTo: 'account/:accountId/dataset/:datasetId/datapoints/map',
                data: { partOf: [Functionalities.DATAPOINTS] }
            },
            {
                path: 'account/:accountId/dataset/:datasetId/datapoints/map',
                pathMatch: 'full',
                canActivate: [ConfigGuard],
                data: { partOf: [Functionalities.DATAPOINTS] }
            },
            {
                path: 'account/:accountId/settings',
                // canActivate: [ConfigGuard],
                // data: { partOf: Functionalities.SETTINGS },
                children: [
                    {
                        path: 'fields',
                        component: AccountFieldsComponent,
                    },
                    {
                        path: 'groups',
                        component: AccountGroupsComponent,
                    },
                    {
                        path: 'members',
                        component: AccountMembersComponent,
                    },
                    {
                        path: 'global-overlays',
                        component: AccountOverlaysComponent,
                        canActivate: [ConfigGuard],
                        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]}
                    },
                    {
                        path: 'private-overlays',
                        component: AccountPrivateOverlaysComponent,
                        canActivate: [ConfigGuard],
                        data: { partOf: [Functionalities.OVERLAYS] }
                    },
                    {
                        path: 'create-overlay',
                        component: CreatePrivateOverlayComponent,
                        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
                        canActivate: [ConfigGuard]
                    },
                    {
                        path: 'edit-overlay/:datasetId',
                        component: EditPrivateOverlayComponent,
                        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
                        canActivate: [ConfigGuard]
                    },
                    {
                        path: 'external-overlays',
                        component: AccountExternalOverlaysComponent,
                        canActivate: [ConfigGuard],
                        data: { partOf: [Functionalities.OVERLAYS] }
                    },
                ]
            },
            {
                path: 'invitation/:token',
                component: InvitationComponent
            },
            {
                path: 'integrated-clients',
                component: IntegratedClientsComponent,
                canActivate: [SuperadminGuard]
            },
            {
                path:'geographical-regions',
                component: GeographicalRegionsComponent,
                canActivate: [SuperadminGuard]
            },
            {
                path: 'global-overlays',
                component: OverlaysComponent,
                data: { partOf:  [Functionalities.THEMATICMAP, Functionalities.OVERLAYS]},
                canActivate: [ConfigGuard]
            },
            {
                path: 'overlays',
                // data: { partOf:  [Functionalities.THEMATICMAP, Functionalities.OVERLAYS]},
                // canActivate: [ConfigGuard],
                children: [
                    {
                        path: 'global-overlays',
                        component: OverlaysComponent,
                        data: { partOf:  [Functionalities.THEMATICMAP, Functionalities.OVERLAYS]},
                        canActivate: [ConfigGuard]
                    },
                    {
                        path: 'accounts',
                        component: AccountComponent
                    },
                    {
                        path: 'external-overlays',
                        component: ExternalOverlaysComponent,
                        data: { partOf:  [Functionalities.THEMATICMAP, Functionalities.OVERLAYS]},
                        canActivate: [ConfigGuard]
                    },
                    {
                        path: 'externaloverlay-tree',
                        component: EditExternalOverlayTreeComponent,
                        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP] }
                    },
                    {
                        path: 'overlay-tree',
                        component: EditOverlayTreeComponent,
                        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP] }
                    },
                    {
                        path: 'global-overlays/:datasetId',
                        data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
                        canActivate: [ConfigGuard],
                        children: [
                            {
                                path: 'edit',
                                component: EditGlobalOverlayComponent,
                            },
                            {
                                path: 'edit-accounts',
                                component: EditOverlayAccountsComponent,
                            }
                        ]
                    }
                ]
            },
            {
                path: 'external-overlays',
                component: ExternalOverlaysComponent,
                data: { partOf:  [Functionalities.THEMATICMAP, Functionalities.OVERLAYS]},
                canActivate: [ConfigGuard]
            },
            // {
            //     path: 'externaloverlay-tree',
            //     component: EditExternalOverlayTreeComponent,
            //     data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP] }
            // },
            // {
            //     path: 'overlay-tree',
            //     component: EditOverlayTreeComponent,
            //     data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP] }
            // },
            {
                path: 'edit-overlay-tree',
                component: EditOverlayTreeComponent,
                data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP] },
            },
            {
                path: 'global-overlays/create',
                component: CreateGlobalOverlayComponent,
                data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
                canActivate: [ConfigGuard]
            },
            // {
            //     path: 'global-overlays/:datasetId',
            //     data: { partOf: [Functionalities.OVERLAYS, Functionalities.THEMATICMAP]},
            //     canActivate: [ConfigGuard],
            //     children: [
            //         {
            //             path: 'edit',
            //             component: EditGlobalOverlayComponent,
            //         },
            //         {
            //             path: 'edit-accounts',
            //             component: EditOverlayAccountsComponent,
            //         }
            //     ]
            // }
        ]
    },
    {
        path: '**',
        redirectTo: 'public/login'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        AuthModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
