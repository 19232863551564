import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { TessadataService } from '../../../data-access-layer/tessadata.service';
import { Point } from '../../../model/geometry/point';
import { TessadataLocationDetails } from '../../../core/tessadata/tessadata-location-details';
import { DownloadService } from '../../../data-access-layer/download/download.service';
import { AttachmentUtils } from '../../../core/utils/attachment-utils';
import { Observable, of } from 'rxjs';
import { GoogleGeocodingService } from 'src/app/shared/services/google-geo-coder.service';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TessadataDatasetStructure } from 'src/app/core/tessadata/tessadata-dataset-structure';
import { TessadataFieldsValues } from '../../../core/tessadata/tessadata-fields-values';
import { MapStateService } from '../../../map/map-state.service';
import { DataPointsServiceState } from '../../../shared/services/datapoints-service-state';
import {NotifService} from '../../../core/notification/notif.service';
import { EChartsOption } from 'echarts';
import { ClimateOverlayPermission, DatapointsClimateChartService } from '../climate-charts/datapoints-climate-chart.service';
import { LocationProfile } from 'src/app/model/datapoint/location-profile/location-profile';
import { AerisService } from 'src/app/data-access-layer/aeris.service';
import { AerisType } from 'src/app/core/aeris/AerisType';
import { Aeris } from 'src/app/core/utils/aeris';
import { AerisData } from '../datapoints-location-profile/location-profile/location-profile.component';
import { isNullOrUndefined, isUndefined } from "src/app/core/utils/util-master";
import { Climate } from 'src/app/core/utils/climate';
import { TessadataDataset } from 'src/app/core/tessadata/tessadata-dataset';
import { isEnabled } from 'src/environments/environment';
import { Functionalities } from 'src/environments/app-functionalities';
import { ComputationUtils } from 'src/app/core/utils/computation-utils';
import { DistanceUnit } from 'src/app/constants';
import { PoiClosestFilter } from 'src/app/model/datapoint/filter/poi-closest-filter';
import { MaptycsTheme } from '../climate-charts/custom-theme';

@Component({
    selector: 'map-address-location-profile',
    templateUrl: './address-location-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./address-location-profile.component.scss',
        '../datapoints-location-profile/location-profile/location-profile.component.scss',
        '../datapoints-location-profile/datapoints-profile-panel.component.scss']
})
export class AddressLocationProfileComponent implements OnInit, OnDestroy {
    isExpanded = false;
    isLoading = false;
    requestFailed = false;
    location: Point;
    currentPinAddress: Observable<string>;
    locationDetails: TessadataLocationDetails;

    initMaxTempOption: EChartsOption;
    initMeanTempOption: EChartsOption;
    initMinTempOption: EChartsOption;
    initSnowfallOption: EChartsOption;
    initPrecipitationOption: EChartsOption;
    initSurfaceWindOption: EChartsOption;
    initPopulationDensityOption: EChartsOption;
    initSeaLevelRiseOption: EChartsOption;
    initSeaLevelRiseWarmingOption: EChartsOption;
    @Input() datasetID: any;
    _dropdownSelectedValue: string;

    locationProfile = new LocationProfile();
    selectedAddressPoint: Point;
    airQualityData: AerisData[] = [];
    meteorologicalConditionsData: AerisData[] = [];
    forecastData: AerisData[] = [];
    tropicalCyclonesData: AerisData[] = [];
    alertsData: AerisData[] = [];
    convectiveData: AerisData[] = [];
    droughtsData: AerisData[] = [];
    firesOutlookData: AerisData[] = [];
    earthquakesData: AerisData[] = [];
    firesData: AerisData[] = [];
    placesData: AerisData[] = [];
    riversData: AerisData[] = [];
    stormCellsData: AerisData[] = [];
    threatsData: AerisData[] = [];

    tessadataEnabled = isEnabled(Functionalities.TESSADATA_DATA);
    tessadataPOIDetails: TessadataFieldsValues[]; //each entry belongs to a new POI
    tessadataPOIDataset: TessadataDataset;
    tessadataPOIDistance: number = 100; //maybe extract this into constants
    tessadataPOIDistanceUnit: DistanceUnit = DistanceUnit.KM;
    tessadataPOISubmittedDistanceUnit: DistanceUnit = DistanceUnit.KM;
    tessadataPOILimit: number = 5; //maybe extract this into constant
    testdataInputParameters: {address: Point, tessadataPOIDatasets: TessadataDataset[], tessadataPOIDatasetsStructures: TessadataDatasetStructure[]} = {address: {x: 0, y: 0}, tessadataPOIDatasets: [], tessadataPOIDatasetsStructures: []};
    address: string = null;
    maptycsTheme = MaptycsTheme;
    constructor(
        private readonly tessadataService: TessadataService,
        private cd: ChangeDetectorRef,
        private downloadService: DownloadService,
        public dataPointsServiceState: DataPointsServiceState,
        private geoCoderService: GoogleGeocodingService,
        private mapStateService: MapStateService,
        private notifService: NotifService,
        private datapointsClimateChartService: DatapointsClimateChartService,
        public readonly climateOverlayPermission: ClimateOverlayPermission,
        private aerisService: AerisService
    ) {
        this.currentPinAddress = this.mapStateService.getCurrentPinAddress();
        this.initMaxTempOption = datapointsClimateChartService.maxTemperatureService.options;
        this.initMeanTempOption = datapointsClimateChartService.meanTemperatureService.options;
        this.initMinTempOption = datapointsClimateChartService.minTemperatureService.options;
        this.initSnowfallOption = datapointsClimateChartService.snowfallService.options;
        this.initPrecipitationOption = datapointsClimateChartService.precipitationService.options;
        this.initSurfaceWindOption = datapointsClimateChartService.surfaceWindService.options;
        this.initPopulationDensityOption = datapointsClimateChartService.populationDensityService.options;
        this.initSeaLevelRiseOption = datapointsClimateChartService.seaLevelRiseService.options;
        this.initSeaLevelRiseWarmingOption = datapointsClimateChartService.seaLevelRiseWarmingService.options

        this.dataPointsServiceState.getActiveDropDownValueSubject().subscribe((value: string) => {
            this._dropdownSelectedValue = value;
            if (this._dropdownSelectedValue == this.locationProfile.CLIMATE) {
                this.prepareClimateCharts(this.selectedAddressPoint);
            } else {
                Climate.resetGraphTypes(this.datapointsClimateChartService);
            }
            this.cd.detectChanges();

        })
    }

    @Input('location')
    set setLocationInput(data: {address: Point, externalPOIMenuItems: TessadataDataset[], externalPOIDatasetsStructures: TessadataDatasetStructure[]}) {
        this.setLocation(data);
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    onMaxTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.maxTemperature.instance = ec;
        }
        this.datapointsClimateChartService.maxTemperatureService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onMeanTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.meanTemperature.instance = ec;
        }
        this.datapointsClimateChartService.meanTemperatureService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onMinTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.minTemperature.instance = ec;
        }
        this.datapointsClimateChartService.minTemperatureService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSnowfallChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.snowfallTemperature.instance = ec;
        }
        this.datapointsClimateChartService.snowfallService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onPrecipitationChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.precipitation.instance = ec;
        }
        this.datapointsClimateChartService.precipitationService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSurfaceWindChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.surfaceWind.instance = ec;
        }
        this.datapointsClimateChartService.surfaceWindService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onPopulationDensityCharInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.populationDensity.instance = ec;
        }
        this.datapointsClimateChartService.populationDensityService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSeaLevelRiseChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.seaLevelRise.instance = ec;
        }
        this.datapointsClimateChartService.seaLevelRiseService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSeaLevelRiseWarmingChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.seaLevelRiseWarming.instance = ec;
        }
        this.datapointsClimateChartService.seaLevelRiseWarmingService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    ngOnInit() {
        this.dataPointsServiceState.emitOnLocationProfileInit();
        this.currentPinAddress.subscribe(response => {
            this.address = response;
        });
    }

    setLocation(data: {address: Point, externalPOIMenuItems: TessadataDataset[], externalPOIDatasetsStructures: TessadataDatasetStructure[]}) {
        this.geoCoderService.reverseGeocoding(data.address)
            .subscribe(currentPinAddress => this.mapStateService.updateCurrentPinAddress(currentPinAddress));
        this.aerisFetch(data.address);
        this.testdataInputParameters = {address: data.address, tessadataPOIDatasets: data.externalPOIMenuItems, tessadataPOIDatasetsStructures: data.externalPOIDatasetsStructures};
        this.fetchClosestPOI();
        this.location = data.address;
        this.isLoading = true;
        this.cd.detectChanges();
        this._dropdownSelectedValue = this.locationProfile.EXTERNAL;
        this.selectedAddressPoint = data.address;
        this.prepareClimateCharts(data.address);

        this.tessadataService
            .fetchLocationProfile(data.address.y, data.address.x)
            .pipe(
                tap(locationDetails => this.locationDetails = locationDetails),
                map(locationDetails => locationDetails.datasets.map(dataset => dataset.datasetId)),
                mergeMap(datasetIds => datasetIds.length ? this.tessadataService.fetchDatasetsStructures(datasetIds) : of(null))
            )
            .subscribe((response: TessadataDatasetStructure[] | null) => {
                if (response) {
                    this.mapDatasetFieldLabels(response);
                }
                this.requestFailed = false;
                this.isLoading = false;
                this.cd.detectChanges();
            }, () => {
                this.requestFailed = true;
                this.isLoading = false;
                this.cd.detectChanges();
            });
    }

    private mapDatasetFieldLabels(labelDatasets: TessadataDatasetStructure[]) {
        this.locationDetails.datasets.forEach((dataset: TessadataFieldsValues) => {
            if (dataset.fields.length) {
                const labelDataset = labelDatasets.find(labelDataset => labelDataset.datasetId === dataset.datasetId);

                dataset.fields.forEach(field => {
                    const labelField = labelDataset.fields.find(labelField => labelField.fieldId === field.id);
                    if (!isNullOrUndefined(labelField)) {
                        field.label = labelField.fieldLabel;
                    }
                });
            }
        });
    }

    onExpandedClick(expanded?: boolean) {
        this.isExpanded = (expanded !== undefined) ? expanded : !this.isExpanded;

        if (!expanded) {
            this.dataPointsServiceState.emitOnAddressLocationProfileClose();
        }
    }

    downloadLocationProfile($event) {
        $event.preventDefault();
        if (!this.locationDetails || !this.currentPinAddress) {
            return;
        }
        let address: any;
        this.currentPinAddress.subscribe(response => {
            address = response;
        });

        let distanceInM = ComputationUtils.getDistanceInMeters(this.tessadataPOIDistance, this.tessadataPOIDistanceUnit);
        const poiClosestFilter: PoiClosestFilter = {
            latitude: this.testdataInputParameters.address.y,
            longitude: this.testdataInputParameters.address.x,
            externalDatasetId: this.tessadataPOIDataset.datasetId,
            distanceInMeters: distanceInM,
            distanceUnit: this.tessadataPOIDistanceUnit,
            limit: this.tessadataPOILimit
        };
        this.downloadService.downloadExternalDataForAddress(this.location, address, this.filterIds(), poiClosestFilter)
            .subscribe(response => AttachmentUtils.downloadFileWithName(response, 'Address Profile.xlsx'),
                error => this.notifService.error('Something went wrong during download'));
        // this.currentPinAddress.pipe(
        //     switchMap(address => this.downloadService.downloadExternalDataForAddress(this.location, address, this.filterIds())),
        //     tap(response => AttachmentUtils.downloadFileWithName(response, 'Address Profile.xlsx'))
        // ).subscribe();
    }

    filterIds() {
        return this.locationDetails.datasets.reduce(function(ids, obj){
            if(!['25', '26'].includes(obj.datasetId)){
                ids.push(obj.datasetId);
            }
            return ids;
        }, []);
    }

    onClickCoordinates() {
        this.notifService.success('Full Coordinates Copied');
        window.navigator.clipboard.writeText(this.location.y + ',' + this.location.x);
    }

    ngOnDestroy() {
        this.dataPointsServiceState.emitOnLocationProfileDestroyed();
        this.datapointsClimateChartService.unsubscribeAddressChartInstances();
    }

    avoidDatasets(datasetId) {
        return  !['25', '26'].includes(datasetId);
    }

    prepareClimateCharts(address: Point) {
        this.datapointsClimateChartService.subscribeAddressChartInstances(address, this.datasetID);
        this.datapointsClimateChartService.checkClimatePermissions(this.climateOverlayPermission, this.datasetID);
    }

    isExternalDatasets(datasetId) {
        return !['noharm','risk_index_tract'].includes(datasetId);
    }

    aerisFetch(address: Point) {
        this.aerisService.fetchLocationProfile(AerisType.CONDITIONS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.meteorologicalConditionsData = Aeris.computeAerisData(response, '', 0);
            this.meteorologicalConditionsData = this.meteorologicalConditionsData.filter(meteorologicalConditions => {
                return meteorologicalConditions.text !== "" && meteorologicalConditions.value !== "";
            })
        }, () => {
            this.meteorologicalConditionsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.FORECASTS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.forecastData = Aeris.computeAerisData(response, '', 0);
            this.forecastData = this.forecastData.filter(forecast => {
                return forecast.text !== "" && forecast.value !== "";
            })
        }, () => {
            this.forecastData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.AIR_QUALITY, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.airQualityData = Aeris.computeAerisData(response, '', 0);
            this.airQualityData = this.airQualityData.filter(airQuality => {
                return airQuality.text !== "" && airQuality.value !== "";
            })
        }, () => {
            this.airQualityData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.TROPICALCYCLONES, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.tropicalCyclonesData = Aeris.computeAerisData(response, '', 0);
            this.tropicalCyclonesData = this.tropicalCyclonesData.filter(tropicalCyclones => {
                return tropicalCyclones.text !== "" && tropicalCyclones.value !== "";
            })
        }, () => {
            this.tropicalCyclonesData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.ALERTS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.alertsData = Aeris.computeAerisData(response, '', 0);
            this.alertsData = this.alertsData.filter(alerts => {
                return alerts.text !== "" && alerts.value !== "";
            })
        }, () => {
            this.alertsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.CONVECTIVE, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.convectiveData = Aeris.computeAerisData(response, '', 0);
            this.convectiveData = this.convectiveData.filter(convective => {
                return convective.text !== "" && convective.value !== "";
            })
        }, () => {
            this.convectiveData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.DROUGHTS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.droughtsData = Aeris.computeAerisData(response, '', 0);
            this.droughtsData = this.droughtsData.filter(droughts => {
                return droughts.text !== "" && droughts.value !== "";
            })
        }, () => {
            this.droughtsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.FIRES_OUTLOOk, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.firesOutlookData = Aeris.computeAerisData(response, '', 0);
            this.firesOutlookData = this.firesOutlookData.filter(firesOutlook => {
                return firesOutlook.text !== "" && firesOutlook.value !== "";
            })
        }, () => {
            this.firesOutlookData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.FIRES, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.firesData = Aeris.computeAerisData(response, '', 0);
            this.firesData = this.firesData.filter(fires => {
                return fires.text !== "" && fires.value !== "";
            })
        }, () => {
            this.firesData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.PLACES, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.placesData = Aeris.computeAerisData(response, '', 0);
            this.placesData = this.placesData.filter(places => {
                return places.text !== "" && places.value !== "";
            })
        }, () => {
            this.placesData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.RIVERS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.riversData = Aeris.computeAerisData(response, '', 0);
            this.riversData = this.riversData.filter(rivers => {
                return rivers.text !== "" && rivers.value !== "";
            })
        }, () => {
            this.riversData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.STORMCELLS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.stormCellsData = Aeris.computeAerisData(response, '', 0);
            this.stormCellsData = this.stormCellsData.filter(stormCells => {
                return stormCells.text !== "" && stormCells.value !== "";
            })
        }, () => {
            this.stormCellsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.THREATS, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.threatsData = Aeris.computeAerisData(response, '', 0);
            this.threatsData = this.threatsData.filter(threats => {
                return threats.text !== "" && threats.value !== "";
            })
        }, () => {
            this.threatsData = [];
        });
    }

    downloadAdressProfileChart() {
        const addressDetails = {
            address: this.address,
            yAxis: this.location.y.toFixed(6),
            xAxis: this.location.x.toFixed(6)
        }
        Climate.downloadOverlayProfileChart(this.datapointsClimateChartService, this.notifService, 'addressProfileClimateChart', null, addressDetails);
    }

    fetchClosestPOI(isSubmitCall: boolean = false) {
        if (this.tessadataEnabled) {
            if (!isSubmitCall) {
                this.tessadataPOIDataset = this.testdataInputParameters.tessadataPOIDatasets[0];
            }
            let distanceInM = ComputationUtils.getDistanceInMeters(this.tessadataPOIDistance, this.tessadataPOIDistanceUnit);
            this.tessadataPOISubmittedDistanceUnit = this.tessadataPOIDistanceUnit;
            const poiClosestFilter: PoiClosestFilter = {
                latitude: this.testdataInputParameters.address.y,
                longitude: this.testdataInputParameters.address.x,
                externalDatasetId: this.tessadataPOIDataset.datasetId,
                distanceInMeters: distanceInM,
                distanceUnit: this.tessadataPOIDistanceUnit,
                limit: this.tessadataPOILimit
            };
            //this.poiClosestSubmittedFilter.emit(poiClosestFilter);
            this.tessadataService.fetchClosestPOIDetails(poiClosestFilter).subscribe(
                locationDetails => {
                    this.tessadataPOIDetails = locationDetails.datasets;
                    if (this.tessadataPOIDetails.length) {
                        let tessadataDatasetStructure = this.testdataInputParameters.tessadataPOIDatasetsStructures.find(structure => structure.datasetId === this.tessadataPOIDetails[0].datasetId);
                        this.tessadataPOIDetails.forEach(poi => poi.fields.forEach(field => {
                            let tessadataField = tessadataDatasetStructure.fields.find(f => f.fieldId === field.id);
                            field.label = tessadataField.fieldLabel;
                            field.type = tessadataField.fieldType;
                            if (field.label === 'Distance') {
                                field.value = field.value ? String(ComputationUtils.getDistanceInUnit(Number(field.value), this.tessadataPOIDistanceUnit)) : '';
                            }
                        }));
                    }
                   this.cd.detectChanges();
                }
            );
        }
    }
}
