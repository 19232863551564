<div class="public-content">
    <div class="public-text">
        <img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
    </div>
    <div class="public-form">
        <form [formGroup]="registerForm">
            <mat-form-field [floatLabel]="'never'" color="#fff">
                <mat-label>Name</mat-label>
                <input matInput autocomplete="off" [errorStateMatcher]="matcher" required
                       formControlName="name">
                <mat-error *ngIf="registerForm.controls.name.hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Password</mat-label>
                <input matInput autocomplete="off" [errorStateMatcher]="matcher" required
                       formControlName="password" type="password">
                <mat-error *ngIf="registerForm.controls.password.hasError('required')">
                    Password is required
                </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Confirm Password</mat-label>
                <input matInput autocomplete="off" [errorStateMatcher]="matcher" required
                       formControlName="confirmPassword" type="password">
                <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('required')">
                    Confirm Password is required
                </mat-error>
                <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('mustMatch')">
                    Passwords must match
                </mat-error>
            </mat-form-field>
            <button mat-stroked-button color="primary" [disabled]="registerForm.invalid" (click)="onSubmit()">Register</button>
        </form>
    </div>
</div>
