import {Injectable} from '@angular/core';
import {Group} from '../model/group/group';
import {PrincipalUser} from '../model/auth/principal-user';
import {Dataset} from '../model/dataset/dataset';
import {AccountService} from '../data-access-layer/account/account.service';
import {GroupService} from '../data-access-layer/groups/group.service';
import {AccountMember} from '../model/member/account-member';
import {Account} from '../model/account/account';
import {BehaviorSubject, Observable} from 'rxjs';
import { Modules } from '../model/modules/modules';
import { isNull, isUndefined } from '../core/utils/util-master';

@Injectable({
    providedIn: 'root'
})
export class UserStateService {

    principal: PrincipalUser;
    id: number;
    isSuperadmin = false;

    private readonly accountMembers: Map<number, AccountMember> = new Map<number, AccountMember>(); // by account id

    // account id, is account admin
    private readonly isAccountAdminByAccount: Map<number, boolean> = new Map<number, boolean>();
    // dataset id, available groups for user in that dataset
    private readonly availableGroupsByDataset: Map<string, Group[]> = new Map<string, Group[]>();
    // account id, available datasets ids for user in that account
    private readonly availableDatasetsByAccount: Map<number, Dataset[]> = new Map<number, Dataset[]>();
    // all accounts the user has access to
    private readonly _availableAccounts: Account[] = [];
    //true is emitted when user state data is ready. Initially value is true for components that subscribe after the data has been fetched
    private readonly _userStateSubject = new BehaviorSubject<boolean>(true);
    // account id, available module's against that account.
    public readonly availableModulesByAccount: Map<number, Modules[]> = new Map<number, Modules[]>();

    constructor(private readonly accountService: AccountService,
                private readonly groupService: GroupService) {
    }

    get userStateSubject(): Observable<boolean> {
        return this._userStateSubject.asObservable();
    }

    initialize(principal: PrincipalUser): Promise<void> {
        this.clearAll();
        this.principal = principal;
        this.principal.membership.forEach(member => {
            this.accountMembers.set(member.accountID, member);
        });
        return new Promise((resolve, reject) => {
            try {
                this.id = principal.id;
                this.isSuperadmin = principal.isSuperadmin;
                if (principal.isSuperadmin) {
                    this.accountService.getMyAccounts().subscribe(accounts => {
                        accounts.forEach(account => {
                            this.availableDatasetsByAccount.set(account.id, account.datasets);
                            this._availableAccounts.push(account);
                            this.availableModulesByAccount.set(account.id, account.modules);
                            this._userStateSubject.next(true);
                            // TODO: decomment this if _availableGroupsByDataset is used
                            // account.datasets.forEach(dataset => {
                            //     this.groupService.getGroups(dataset.id, account.id).subscribe(groups => {
                            //         this._availableGroupsByDataset.set(dataset.id, groups);
                            //     })
                            // })
                        });

                        resolve();
                    });

                } else {
                    let promises = [];
                    let members = [];
                    principal.membership.forEach(member => {
                        members.push(member);
                        promises.push(this.accountService.findAccount(member.accountID).toPromise());
                    });
                    Promise.all(promises).then((accounts: Account[]) => {
                        this.prepareAndSaveAccounts(accounts, members);
                        this._userStateSubject.next(true);
                        resolve();
                    }, (err) => {
                        reject(err);
                    });
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    hasWriteAccess(accountId: number): boolean {
        if (this.principal.isSuperadmin) {
            return true;
        }
        let member = this.accountMembers.get(accountId);
        return member && member.permissions > 0;
    }

    private prepareAndSaveAccounts(accounts: Account[], members: AccountMember[]): void {
        for (let i = 0; i < accounts.length; i++) {
            let member = members[i];
            let account = accounts[i];
            this._availableAccounts.push(account);

            if (member.isAccountAdmin) {
                this.availableDatasetsByAccount.set(account.id, account.datasets);
            } else {
                let availableDatasets: Dataset[] = [];
                account.datasets.forEach(dataset => {
                    if (member.datasetGroups[dataset.id]) {
                        availableDatasets.push(dataset);
                        this.availableGroupsByDataset.set(dataset.id, member.datasetGroups[dataset.id]);
                    }
                });
                this.availableDatasetsByAccount.set(member.accountID, availableDatasets);
            }
            this.isAccountAdminByAccount.set(member.accountID, member.isAccountAdmin);
            this.availableModulesByAccount.set(member.accountID, account.modules);
        }
    }

    isAccountAdmin(accountId: number): boolean {
        return this.isAccountAdminByAccount.get(accountId);
    }

    availableGroupsInDataset(datasetId: string): Group[] {
        return this.availableGroupsByDataset.get(datasetId);
    }

    availableDatasetsInAccount(accountId: number): Dataset[] {
        return this.availableDatasetsByAccount.get(accountId);
    }

    availableModulesInAccount(accountId: number): Modules[] {
        let moduleList: Modules[] = this.availableModulesByAccount.get(accountId);
        if (!isUndefined(moduleList) && !isNull(isUndefined)) {
            moduleList.forEach(element => {
                element.moduleName = element.moduleName.toUpperCase();
            });
        }
        return moduleList;
    }

    get availableAccounts(): Account[] {
        return this._availableAccounts;
    }

    clearAll() {
        this.id = null;
        this.isSuperadmin = false;
        this.isAccountAdminByAccount.clear();
        this.availableDatasetsByAccount.clear();
        this.availableGroupsByDataset.clear();
        this.accountMembers.clear();
        this._availableAccounts.length = 0;
        this.availableModulesByAccount.clear();
        this._userStateSubject.next(true);

    }

    setModuleAgainstAccount(accountId: number, modules: Modules[]) {
        this.availableModulesByAccount.set(accountId, modules);
    }
}
