import {
    Component,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { AccountService } from "../data-access-layer/account/account.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { AuthService } from "../auth/auth.service";
import { PanelComponent } from "../shared/panel/panel.component";
import { Account, AccountTableRow } from "../model/account/account";
import { forkJoin, Subject, Subscription } from "rxjs";
import { Dataset } from "../model/dataset/dataset";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifService } from "../core/notification/notif.service";
import { SidePanelComponent } from "../core/side-panel/side-panel.component";
import { UserStateService } from "../auth/user-state-service";
import { DialogComponent } from "../shared/dialog/dialog.component";
import { DialogModel } from "../model/dialog/dialog-model";
import { take } from "rxjs/operators";
import { DatapointsPageStateService } from "../dataset/datapoints/datapoints-page-state.service";
import { Group } from "../model/group/group";
import { SidePanelService } from "../shared/services/side-panel.service";
import { SidePanels } from "../shared/services/side-panel.helper";
import { MaptycsApplication } from "../model/account/maptycs-application";

type EditAccountOverlaysComponentType = {
    currentAccount: Account;
};
@Component({
    selector: "map-account",
    templateUrl: "./account.component.html",
    styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit, OnDestroy {
    currentAccount: Account;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild("pagePanel", { static: true }) pagePanel: PanelComponent;
    @ViewChild("editAccountPanel") editAccountPanel: SidePanelComponent;
    @ViewChild("createAccountPanel") createAccountPanel: SidePanelComponent;
    @ViewChild("editOverlayAccountsPanel")
    editOverlayAccountsPanel: SidePanelComponent;

    displayedColumns: string[] = ["select", "name"];
    selection = new SelectionModel<AccountTableRow>(true, []);
    dataSource: MatTableDataSource<AccountTableRow>;
    isTableDataLoading: boolean;
    account: Account;
    emitAccountOnEditSubject: Subject<Account> = new Subject();

    canCreate: boolean;
    canDelete: boolean;

    breadCrumbs: ["Home", "Account"];

    private readonly accountSubscription: Subscription;
    get componentName() {
        return this.constructor.name;
    }


    get MaptycsApplication() {
        return MaptycsApplication;
    }

    constructor(
        private readonly accountService: AccountService,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly notifService: NotifService,
        private readonly userStateService: UserStateService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly dialog: MatDialog,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly sidePanelService: SidePanelService
    ) {
        this.accountSubscription = new Subscription();
        this.isTableDataLoading = true;
        this.canCreate = false;
        this.canDelete = false;
    }

    ngOnInit(): void {
        this.datapointsPageStateService.activeAccount = undefined;
        this.displayedColumns = !this.isDashboardCall()
            ? this.displayedColumns.concat(["actions"])
            : this.displayedColumns.concat(["views", "actions"]);

        this.dataSource = new MatTableDataSource<AccountTableRow>([]);
        this.fetchAccounts();
        this.canCreate = this.can("create");
        this.canDelete = this.can("delete");
    }

    fetchAccounts() {
        this.userStateService.userStateSubject.subscribe((ready) => {
            const accountsTableRows = [];
            this.userStateService.availableAccounts.forEach((account) => {
                let availableDatasetsInAccount =
                    this.userStateService.availableDatasetsInAccount(
                        account.id
                    );
                const datasetMap = new Map<string, Dataset>();
                account.datasets.forEach((dataset) => {
                    // add only the datasets the user has permission to see
                    if (
                        availableDatasetsInAccount.find(
                            (d) => d.id === dataset.id
                        )
                    ) {
                        datasetMap.set(dataset.application, dataset);
                    }
                });
                accountsTableRows.push({
                    ...account,
                    datasetMap,
                    canUpdate: this.can("update", account.id),
                    canDelete: this.can("delete", account.id),
                });
            });
            this.dataSource = new MatTableDataSource(accountsTableRows);
            this.dataSource.sort = this.sort;
            this.isTableDataLoading = false;
        });
    }

    applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /**
     * The label for the checkbox on the passed row
     */
    checkboxLabel(row?: AccountTableRow): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row `;
    }

    openCreateAccountPanel(): void {
        this.createAccountPanel.showPanel();
    }

    deleteAccounts(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete ${this.selection.selected.length} account(s)?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    const observers = [];
                    this.selection.selected.forEach((account) => {
                        observers.push(
                            this.accountService.deleteAccount(account.id)
                        );
                    });

                    forkJoin(observers).subscribe((res) => {
                        this.fetchAccounts();
                        this.notifService.success(
                            "The account has been deleted. The page will refresh in 3 seconds"
                        );
                        this.selection = new SelectionModel<AccountTableRow>(
                            true,
                            []
                        );
                        window.location.reload();
                    });
                }
            });
    }

    deleteAccount(account: AccountTableRow): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete "${account.name}" account?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.accountService
                        .deleteAccount(account.id)
                        .subscribe((success) => {
                            this.fetchAccounts();
                            this.notifService.success(
                                "The account has been deleted. The page will refresh in 3 seconds"
                            );
                            window.location.reload();
                        });
                }
            });
    }

    navigateToDatapoints(row: AccountTableRow, application: string): void {
        const dataset = row.datasetMap.get(application);

        if (dataset) {
            this.router.navigate([`./${row.id}/dataset/${dataset.id}/datapoints/map`], { relativeTo: this.route });
        }
    }

    ngOnDestroy(): void {
        this.accountSubscription.unsubscribe();
    }

    onCreateSuccess() {
        this.selection = new SelectionModel<AccountTableRow>(true, []);
        this.createAccountPanel.hidePanel();
        this.fetchAccounts();
    }

    can(action: string, accountID?: number) {
        return this.authService.can(action, accountID);
    }

    editAccount(account: Account) {
        this.account = account;
        this.emitAccountOnEditSubject.next(account);
        this.editAccountPanel.showPanel();
    }

    isDashboardCall() {
        return this.router.url === "/private/account";
    }

    openSidebar(row?: Account): void {
                if (this.editOverlayAccountsPanel) {
            this.editOverlayAccountsPanel.showPanel();
        } else {
            if (this.editOverlayAccountsPanel)
                this.editOverlayAccountsPanel.hidePanel();
            this.currentAccount = row;
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.sidePanelService.open<EditAccountOverlaysComponentType>(
                SidePanels.EDIT_ACCOUNT_OVERLAYS,
                {
                    id: "accountOverlays",
                    width: 400,
                    panelTitle: "Account Overlays",
                },
                {
                    currentAccount: this.currentAccount,
                }
            );

            // this.accountServiceState.editOverlaysComponentInit$.pipe(take(1)).subscribe(() => {
            //     if(this.editOverlayAccountsPanel){
            //       const { componentRef } = this.editOverlayAccountsPanel.instance;
            //         if (componentRef) {
            //             componentRef.instance.init(overlay);
            //         }
            //     }
            // });

            // this.accountServiceState.onEditOverlaySuccess.subscribe(() => {
            //     if (this.editOverlayAccountsPanel) this.editOverlayAccountsPanel.instance.hidePanel();
            // });
        }
    }

    closeSidebar(): void {
        if (this.editOverlayAccountsPanel)
            this.editOverlayAccountsPanel.hidePanel();
    }

    navigateToSettings(account: AccountTableRow) {
        this.router.navigate(
            [`/private/account/${account.id}/settings/fields`],
            { relativeTo: this.route }
        );
    }

    // openCreateAccountPanel(): void {
    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.createAccountPanel = this.sidePanelService.open<AccountComponent>(SidePanels.CREATE_ACCOUNT,
    //         {
    //             id: 'create-account-panel',
    //             width: 400,
    //             panelTitle: "Edit Account",
    //             panelIcon: "fa-pencil"
    //         });

    //     this.accountServiceState.onCreateSuccess$.subscribe(() => {
    //         this.onCreateSuccess();
    //         this.createAccountPanel.instance.closePanel()
    //     });
    // }

    // editAccount(account: Account) {
    //     this.account = account;
    //     this.emitAccountOnEditSubject.next(account);
    //     this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
    //     this.editAccountPanel = this.sidePanelService.open<EditAccountComponentType>(SidePanels.EDIT_ACCOUNT,
    //         {
    //             id: 'update-account-panel',
    //             width: 400,
    //             panelTitle: "Edit Account",
    //             panelIcon: "fa-pencil"
    //         },
    //         {
    //             account: this.account,
    //             emitAccountOnEditSubject: this.emitAccountOnEditSubject,
    //         });

    //     this.accountServiceState.nameUpdate$.subscribe(() => {
    //         this.fetchAccounts();
    //         this.editAccountPanel.instance.closePanel();
    //     });
    // }
}
