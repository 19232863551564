<ng-container *ngIf="isEnabled && account && dataset">
    <div class="panel-container">
        <div [ngClass]="{'panel-header': !isOpenedInProfile, 'extra-mb': isOpenedInProfile}">
            <button (click)="addNotification()" mat-stroked-button class="notif-btn">
                <mat-icon fontIcon="fa-plus" fontSet="far"></mat-icon>
                <span style="padding-left: 6px;">Add {{ isOpenedInProfile ? 'Notification' : 'Alert'}}</span>
            </button>
        </div>

        <div [ngClass]="{'reports-container': !isOpenedInProfile}">
            <div *ngFor="let notif of notifications; let i = index">
                <div class="notification-box">
                    <div class="notification-container">
                        <map-event-notification style="width: 100%;" [eligibleOverlays]="eligibleOverlays"
                            [eventNotif]="notif" [currencies]="currencies" [dataset]="dataset" [groupsIds]="groupsIds"
                            [isOpenedInProfile]="isOpenedInProfile" (deleteEventNotif)="deleteEventNotif($event) "
                            [MMSI]="MMSI"></map-event-notification>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
