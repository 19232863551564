<ng-template #approveModal>
	<mat-horizontal-stepper [selectedIndex]="currentStepIndex" #stepper>
		<mat-step label="Select Groups" editable="true">
			<div class="step">
				<map-group-tree
						*ngIf="dialogData"
						[dataset]="dialogData.dataset"
						(treeChanged)="selectGroup($event)"
				></map-group-tree>
				<div class="step-controls">
					<button mat-raised-button class="cancel-btn" (click)="onNoClick()">CANCEL</button>
					<button
							mat-raised-button class="cancel-btn"
							matStepperNext

							[disabled]="!selectedGroupID"> Next
					</button>
				</div>
			</div>
		</mat-step>
		<mat-step label="Select Used Fields" editable="true" [stepControl]="fieldsFormGroup">
			<div class="step">
				<h4>Fields:</h4>
				<form
						*ngIf="fieldsFormGroup"
						style="display:flex; flex-direction:column; margin-bottom:25px"
						[formGroup]="fieldsFormGroup"
				>
					<mat-checkbox
							*ngFor="let field of fields"
							[formControlName]="field.id"
					>{{ field.name }}</mat-checkbox
					>
				</form>
				<div class="step-controls">
					<button matStepperPrevious mat-raised-button class="cancel-btn">BACK</button>
					<button
							mat-raised-button class="cancel-btn"
							matStepperNext
							[disabled]="!selectedGroupID"> Next
					</button>
				</div>
			</div>
		</mat-step>
		<mat-step label="Done" editable="true">
			<div class="step-final step">
				<h4 *ngIf="!isLoading" class="submit">Submit drafts for approval ?</h4>
				<mat-spinner *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
			</div>
			<div class="step-controls">
				<button matStepperPrevious mat-button>Back</button>

				<button color="primary" [disabled]="isLoading" mat-raised-button (click)="submitDraftsForApproval()">
					Submit
				</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
	<mat-progress-bar color="accent" mode="indeterminate" class="loading-bar" *ngIf="isLoading"></mat-progress-bar>

</ng-template>
