import { NgModule } from "@angular/core";
import { AccountComponent } from "./account.component";
import { AccountService } from "../data-access-layer/account/account.service";
import { SharedModule } from "../shared/shared.module";
import { AccountPanelComponent } from "./account-panel/account-panel.component";
import { AccountGroupsComponent } from "./account-groups/account-groups.component";
import { AccountCreateGroupsPanelComponent } from "./account-groups/account-create-groups-panel/account-create-groups-panel.component";
import { AccountUpdateGroupsPanelComponent } from "./account-groups/account-update-groups-panel/account-update-groups-panel.component";
import { AccountMembersComponent } from "./members/members/account-members.component";
import { CreateMemberComponent } from "./members/create-member-panel/create-member/create-member.component";
import { InvitationComponent } from "./members/invitation/invitation.component";
import { OverlaysComponent } from "../overlays/overlays.component";
import { EditOverlayComponent } from "../overlays/overlay-edit/edit-overlay.component";
import { CreateOverlayComponent } from "../overlays/overlay-create/create-overlay.component";
import { EditOverlayAccountsComponent } from "../overlays/overlay-edit-accounts/edit-overlay-accounts.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { AccountOverlaysComponent } from "./account-overlays/account-overlays.component";
import { EditAccountComponent } from "./edit-account/edit-account.component";
import { AccountPrivateOverlaysComponent } from "./account-private-overlays/account-private-overlays.component";
import { CreateGlobalOverlayComponent } from "../create-global-overlay/create-global-overlay.component";
import { CreatePrivateOverlayComponent } from "./account-overlays/create-private-overlay/create-private-overlay.component";
import { EditPrivateOverlayComponent } from "./account-overlays/edit-private-overlay/edit-private-overlay.component";
import { EditGlobalOverlayComponent } from "../edit-global-overlay/edit-global-overlay.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { AccountExternalOverlaysComponent } from "./account-external-overlays/account-external-overlays.component";
import { ExternalOverlayCreateComponent } from "../external-overlays/external-overlay-create/external-overlay-create.component";
import { EditExternalOverlayAccountComponent } from "../external-overlays/edit-external-overlay-account/edit-external-overlay-account.component";
import { OverlayComponent } from "../overlay/overlay.component";
import { OverlayModule } from "../overlay/overlay.module";
import { EditAccountOverlaysComponent } from "./edit-account-overlays/edit-account-overlays.component";
import { DatasetModule } from "../dataset/dataset.module";
import { LocationsEditDialog } from "./members/members/locations-edit-dialog/locations-edit-dialog.component"
import { MultiLevelDropdownComponent } from "../shared/multi-level-dropdown/multi-level-dropdown.component";

@NgModule({
    declarations: [
        AccountComponent,
        AccountPanelComponent,
        AccountGroupsComponent,
        AccountCreateGroupsPanelComponent,
        AccountUpdateGroupsPanelComponent,
        AccountMembersComponent,
        CreateMemberComponent,
        InvitationComponent,
        CreateOverlayComponent,
        EditOverlayComponent,
        CreateOverlayComponent,
        EditOverlayAccountsComponent,
        UserSettingsComponent,
        AccountOverlaysComponent,
        EditAccountComponent,
        AccountPrivateOverlaysComponent,
        CreateGlobalOverlayComponent,
        CreatePrivateOverlayComponent,
        EditPrivateOverlayComponent,
        EditGlobalOverlayComponent,
        AccountExternalOverlaysComponent,
        ExternalOverlayCreateComponent,
        EditExternalOverlayAccountComponent,
        EditAccountOverlaysComponent,
        LocationsEditDialog
    ],
    imports: [SharedModule, MatInputModule, MatDatepickerModule, OverlayModule, DatasetModule],
    providers: [AccountService]
})
export class AccountModule {}
