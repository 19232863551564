<div class="report-box">
    <div>
        <div class="report-header">
            <div style="display: flex">
                <div
                    *ngIf="
                        aggregateDataSource.data[0]?.children.length === 0 ||
                        aggregateDataSource.data.length === 0
                    "
                >
                    <span
                        class="report-icon header-icon"
                        matTooltip="Aggregate field"
                    >
                        <!-- sort-amount-up-icon -->
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m16 6.62-.768-1.242A.806.806 0 0 0 14.545 5H6.801a.805.805 0 0 0-.697.395.758.758 0 0 0 .01.783L7.512 8.44c.112.181 1.473 1.348 4.082 3.5-2.61 2.232-3.97 3.439-4.082 3.62l-1.398 2.262a.758.758 0 0 0-.01.783.805.805 0 0 0 .697.395h7.744a.806.806 0 0 0 .687-.378L16 17.38"
                                class="chart-icons"
                                stroke="#002d57"
                                stroke-width="1.5"
                                fill="none"
                                fill-rule="evenodd"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                <div *ngIf="aggregateDataSource.data[0]?.children.length >= 1">
                    <div
                        [matMenuTriggerFor]="aggregateFieldMenu"
                        style="cursor: pointer"
                        (mouseleave)="collapseAll()"
                    >
                        <span
                            class="report-icon header-icon"
                            matTooltip="Aggregate field"
                        >
                            <!-- sort-amount-up-icon -->
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="m16 6.62-.768-1.242A.806.806 0 0 0 14.545 5H6.801a.805.805 0 0 0-.697.395.758.758 0 0 0 .01.783L7.512 8.44c.112.181 1.473 1.348 4.082 3.5-2.61 2.232-3.97 3.439-4.082 3.62l-1.398 2.262a.758.758 0 0 0-.01.783.805.805 0 0 0 .697.395h7.744a.806.806 0 0 0 .687-.378L16 17.38"
                                    class="chart-icons"
                                    stroke="#002d57"
                                    stroke-width="1.5"
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </span>
                    </div>
                    <mat-menu
                        #aggregateFieldMenu="matMenu"
                        class="nested-menu"
                        (closed)="generateReport()"
                    >
                        <mat-tree
                            [dataSource]="aggregateDataSource"
                            [treeControl]="aggregateTreeControl"
                            class="sub-menu-options-child"
                        >
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node
                                *matTreeNodeDef="let node"
                                matTreeNodeToggle
                                class="sub-menu-form-options-tree"
                            >
                                <li
                                    class="mat-tree-node sub-menu-checkbox-options"
                                    [matTooltip]="getDisplayName(node)"
                                    matTooltipPosition="right"
                                    *ngIf="
                                        node.params?.overlay?.geometryType !==
                                            DatasetGeometryType.POINT &&
                                        node.params?.showCheckBox
                                    "
                                >
                                    <button mat-icon-button disabled></button>
                                    <mat-checkbox
                                        class="checklist-leaf-node"
                                        [checked]="node.selected"
                                        [disabled]="getDisabledTreeNode(node)"
                                        [(ngModel)]="node.selected"
                                        (ngModelChange)="
                                            onAggregateFieldModelChangeEvent(
                                                node
                                            )
                                        "
                                    >
                                        {{ getDisplayName(node) }}</mat-checkbox
                                    >
                                </li>
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-nested-tree-node
                                *matTreeNodeDef="
                                    let node;
                                    when: aggregateTreeStrcuture.hasChild
                                "
                                class="sub-menu-options-parent"
                            >
                                <li>
                                    <hr
                                        *ngIf="
                                            aggregateDataSource.data.length > 1
                                        "
                                        class="menu-line-separator"
                                    />
                                    <div
                                        class="mat-tree-node"
                                        [matTooltip]="node.name"
                                        matTooltipPosition="right"
                                        style="
                                            justify-content: space-between !important;
                                        "
                                    >
                                        <span class="filter-inner-parent">{{
                                            node.name
                                        }}</span>
                                        <button
                                            mat-icon-button
                                            matTreeNodeToggle
                                            [attr.aria-label]="
                                                'toggle ' + node.name
                                            "
                                        >
                                            <mat-icon
                                                class="mat-icon-rtl-mirror"
                                            >
                                                {{
                                                    aggregateTreeControl.isExpanded(
                                                        node
                                                    )
                                                        ? "expand_more"
                                                        : "chevron_right"
                                                }}
                                            </mat-icon>
                                        </button>
                                    </div>
                                    <ul
                                        [class.tree-invisible]="
                                            !aggregateTreeControl.isExpanded(
                                                node
                                            )
                                        "
                                    >
                                        <ng-container
                                            matTreeNodeOutlet
                                        ></ng-container>
                                    </ul>
                                </li>
                            </mat-nested-tree-node>
                        </mat-tree>
                    </mat-menu>
                </div>
                <div
                    *ngIf="
                        breakdownDataSource.data[0]?.children.length === 0 ||
                        breakdownDataSource.data.length === 0
                    "
                >
                    <span
                        class="report-icon header-icon"
                        matTooltip="Breakdown fields"
                    >
                        <!-- tasks-icon -->
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g fill="none" fill-rule="evenodd">
                                <path
                                    class="chart-icons"
                                    stroke="#002d57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004"
                                />
                                <path
                                    class="chart-icons"
                                    stroke="#002d57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m18.665 15.167-2.083 2.085-1.25-1.25"
                                />
                                <path d="M-.005-.005h24.01v24.01H-.005z" />
                            </g>
                        </svg>
                    </span>
                </div>
                <div
                    *ngIf="
                        breakdownDataSource.data[0]?.children.length > 1 ||
                        breakdownDataSource.data.length > 1
                    "
                    style="display: flex"
                >
                    <div
                        [matMenuTriggerFor]="breakDownFieldMenu"
                        style="cursor: pointer"
                        (mouseleave)="collapseAll()"
                    >
                        <span
                            class="report-icon header-icon"
                            matTooltip="Breakdown fields"
                        >
                            <!-- tasks-icon -->
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <path
                                        class="chart-icons"
                                        stroke="#002d57"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004"
                                    />
                                    <path
                                        class="chart-icons"
                                        stroke="#002d57"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m18.665 15.167-2.083 2.085-1.25-1.25"
                                    />
                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                    <mat-menu
                        #breakDownFieldMenu="matMenu"
                        (closed)="generateReport()"
                        class="nested-menu"
                    >
                        <mat-tree
                            [dataSource]="breakdownDataSource"
                            [treeControl]="breakdownTreeControl"
                            class="sub-menu-options-child"
                        >
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node
                                *matTreeNodeDef="let node"
                                matTreeNodeToggle
                                class="sub-menu-form-options-tree"
                            >
                                <li
                                    class="mat-tree-node sub-menu-checkbox-options"
                                    [matTooltip]="getDisplayName(node)"
                                    matTooltipPosition="right"
                                    *ngIf="
                                        node.params?.overlay?.geometryType !==
                                            DatasetGeometryType.POINT &&
                                        node.params?.showCheckBox
                                    "
                                >
                                    <button mat-icon-button disabled></button>
                                    <mat-checkbox
                                        class="checklist-leaf-node"
                                        [checked]="node.selected"
                                        [disabled]="
                                            !node.selected &&
                                            selectedBreakdownFieldsByDataset?.get(
                                                _dataset.id
                                            )?.length == 2
                                        "
                                        [(ngModel)]="node.selected"
                                        (ngModelChange)="
                                            onFieldsMenuClick(
                                                node.selected,
                                                _dataset,
                                                node.params?.field
                                            )
                                        "
                                    >
                                        {{ getDisplayName(node) }}</mat-checkbox
                                    >
                                </li>
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-nested-tree-node
                                *matTreeNodeDef="
                                    let node;
                                    when: breakdownTreeStrcuture.hasChild
                                "
                                class="sub-menu-options-parent"
                            >
                                <li>
                                    <hr
                                        *ngIf="
                                            breakdownDataSource.data.length > 1
                                        "
                                        class="menu-line-separator"
                                    />
                                    <div
                                        class="mat-tree-node"
                                        [matTooltip]="node.name"
                                        matTooltipPosition="right"
                                        style="
                                            justify-content: space-between !important;
                                        "
                                    >
                                        <span class="filter-inner-parent">{{
                                            node.name
                                        }}</span>
                                        <button
                                            mat-icon-button
                                            matTreeNodeToggle
                                            [attr.aria-label]="
                                                'toggle ' + node.name
                                            "
                                        >
                                            <mat-icon
                                                class="mat-icon-rtl-mirror"
                                            >
                                                {{
                                                    breakdownTreeControl.isExpanded(
                                                        node
                                                    )
                                                        ? "expand_more"
                                                        : "chevron_right"
                                                }}
                                            </mat-icon>
                                        </button>
                                    </div>
                                    <ul
                                        [class.tree-invisible]="
                                            !breakdownTreeControl.isExpanded(
                                                node
                                            )
                                        "
                                    >
                                        <ng-container
                                            matTreeNodeOutlet
                                        ></ng-container>
                                    </ul>
                                </li>
                            </mat-nested-tree-node>
                        </mat-tree>
                    </mat-menu>
                    <div
                        (click)="generateReport()"
                        *ngIf="
                            (selectedAggregateField || selectedFormula) &&
                            selectedBreakdownFieldsByDataset.size > 0
                        "
                        class="report-submit-btn"
                    >
                        <span>
                            <!-- check-outline-icon -->
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform="translate(-.005 -.005)"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <circle
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        cx="12.005"
                                        cy="12.005"
                                        r="9.004"
                                    />
                                    <path
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m8.448 12.344 2.167 2.168-.014-.014 4.891-4.891"
                                    />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div class="report-type-icons-container">
                <span
                    (click)="setDisplayType(ReportDisplayType.TABLE)"
                    [ngClass]="{
                        selected:
                            reportSubType === ReportDisplayType.TABLE &&
                            dataIsReady,
                        active: dataIsReady
                    }"
                    class="report-icon"
                >
                    <!-- document-icon -->
                    <!-- <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<g fill="none" fill-rule="evenodd">
							<path d="M0 0v24h24V0z" />
							<path d="M21 15h-4a2 2 0 0 0-2 2v4" stroke="#002d57" class="chart-icons" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
							<path
								d="M7 3h10a4 4 0 0 1 4 4v8.343a4 4 0 0 1-1.172 2.829l-1.656 1.656A4 4 0 0 1 15.343 21H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4zM8 12h8M8 8h8M8 16h3"
								stroke="#002d57" class="chart-icons" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</g>
					</svg> -->
                    <i
                        class="fal fa-table chart-icons"
                        style="font-size: 14px; position: relative; bottom: 1px"
                    ></i>
                </span>
                <span
                    (click)="setDisplayType(ReportDisplayType.BAR_CHART)"
                    [ngClass]="{
                        selected:
                            reportSubType === ReportDisplayType.BAR_CHART &&
                            dataIsReady,
                        active: dataIsReady
                    }"
                    class="report-icon"
                >
                    <!-- chart-bar-icon -->
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="M16.5 3.5h3A1.5 1.5 0 0 1 21 5v14a1.5 1.5 0 0 1-1.5 1.5H15V5a1.5 1.5 0 0 1 1.5-1.5zM15 20.5H9v-10A1.5 1.5 0 0 1 10.5 9H15M4.5 15H9v5.5H4.5A1.5 1.5 0 0 1 3 19v-2.5A1.5 1.5 0 0 1 4.5 15z"
                                stroke="#002d57"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="chart-icons"
                            />
                            <path d="M24 24H0V0h24z" />
                        </g>
                    </svg>
                </span>
                <span
                    (click)="setDisplayType(ReportDisplayType.PIE_CHART)"
                    [ngClass]="{
                        selected:
                            reportSubType === ReportDisplayType.PIE_CHART &&
                            dataIsReady,
                        active: dataIsReady
                    }"
                    class="report-icon"
                >
                    <!-- chart-pie-icon -->
                    <!-- <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<g fill="none" fill-rule="evenodd">
							<path d="M12 17.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11" class="chart-icons"
								stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<rect class="chart-icons" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" x="2.5" y="2.5" width="19" height="19" rx="9.5" />
							<path class="chart-icons" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" d="M15.89 15.889 12 11.999V6.51" />
							<path d="M0 0h24v24H0z" />
						</g>
					</svg> -->
                    <i
                        class="fal fa-chart-pie chart-icons"
                        style="font-size: 14px; position: relative; bottom: 1px"
                    ></i>
                </span>
                <span (click)="closed.emit()" class="report-icon active">
                    <!-- cross-icon -->
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 9 9"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke="#002d57"
                            stroke-width="1.5"
                            class="chart-icons"
                        >
                            <path d="m1 1 3.333 3.333L1 7.667" />
                            <path d="M7.333 1 4 4.333l3.333 3.334" />
                        </g>
                    </svg>
                </span>
                <span
                    *ngIf="isLocationTypeApplication()"
                    [matMenuTriggerFor]="actionsMenu"
                    class="report-icon active"
                >
                    <!-- dot-menu-icon -->
                    <svg
                        width="13"
                        height="13"
                        viewBox="0 0 9 9"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="#002d57" fill-rule="evenodd">
                            <circle cx="4.5" cy="1" r="1" />
                            <circle cx="4.5" cy="4" r="1" />
                            <circle cx="4.5" cy="7" r="1" />
                        </g>
                    </svg>
                </span>
                <mat-menu
                    #actionsMenu="matMenu"
                    class="nested-menu header-menu"
                    backdropClass="mat-menu-header"
                >
                    <hr
                        *ngIf="dashboardWorkspaceItems.length"
                        class="menu-line-separator"
                    />
                    <div
                        mat-menu-item
                        *ngIf="dashboardWorkspaceItems.length"
                        (click)="saveWorkspaceItemToDashboard.emit()"
                    >
                        <span>
                            <!-- save-icon -->
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform="translate(-.005 -.005)"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <circle
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        cx="17.507"
                                        cy="17.507"
                                        r="4.502"
                                    />
                                    <path
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m18.907 16.808-1.75 1.752-1.05-1.052"
                                    />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        {{ getSaveButtonLabel() }}
                    </div>
                    <hr
                        *ngIf="dashboardWorkspaceItems.length > 0"
                        class="menu-line-separator"
                    />
                    <div
                        mat-menu-item
                        (click)="saveAsWorkspaceItemToDashboard.emit()"
                    >
                        <span>
                            <!-- save-icon -->
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    transform="translate(-.005 -.005)"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <circle
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        cx="17.507"
                                        cy="17.507"
                                        r="4.502"
                                    />
                                    <path
                                        stroke="#0044f1"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m18.907 16.808-1.75 1.752-1.05-1.052"
                                    />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        {{ getSaveAsButtonLabel() }}
                    </div>
                </mat-menu>
            </div>
        </div>

        <div *ngIf="!dataIsReady" class="report-instructions">
            Select from above the fields for the aggregate.
        </div>

        <div *ngIf="dataIsReady" class="report-content">
            <div
                *ngIf="reportSubType === ReportDisplayType.TABLE"
                class="report-table"
            >
                <table
                    (matSortChange)="sortData($event, true)"
                    [dataSource]="reportData"
                    [matSortActive]="sort?.active"
                    [matSortDirection]="sort?.direction"
                    class="report-table"
                    mat-table
                    matSort
                    matSortDisableClear
                >
                    <ng-container
                        *ngFor="
                            let dynamicColumn of dynamicColumns | keyvalue;
                            let i = index
                        "
                        matColumnDef="{{ dynamicColumn.key }}"
                    >
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>
                            {{ dynamicColumn.value }}
                        </th>
                        <td *matCellDef="let element" mat-cell>
                            {{
                                element.dynamicFieldValuesByIds.get(
                                    dynamicColumn.key
                                )
                            }}
                        </td>
                        <td *matFooterCellDef mat-footer-cell>
                            {{ i === 0 ? "Total" : ("" | commaFormat) }}
                        </td>
                    </ng-container>

                    <ng-container [matColumnDef]="AGGREGATE_COLUMN_ID">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>
                            {{ selectedAggregateName }}
                        </th>
                        <td *matCellDef="let element" mat-cell>
                            {{ element.aggregate | commaFormat }}
                        </td>
                        <td *matFooterCellDef mat-footer-cell>
                            {{ totalAggregate | commaFormat }}
                        </td>
                    </ng-container>

                    <ng-container [matColumnDef]="PERCENTAGE_COLUMN_ID">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>
                            Total %
                        </th>
                        <td *matCellDef="let element" mat-cell>
                            {{ element.percentage | commaFormat }}%
                        </td>
                        <td *matFooterCellDef mat-footer-cell>100%</td>
                    </ng-container>

                    <ng-container matColumnDef="count">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>
                            Count
                        </th>
                        <td *matCellDef="let element" mat-cell>
                            {{ element.count | commaFormat }}
                        </td>
                        <td *matFooterCellDef mat-footer-cell>
                            {{ totalCount | commaFormat }}
                        </td>
                    </ng-container>

                    <ng-container [matColumnDef]="AVERAGE_COLUMN_ID">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>
                            Average
                        </th>
                        <td *matCellDef="let element" mat-cell>
                            {{ element.average | roundUpNumber | commaFormat }}
                        </td>
                        <td *matFooterCellDef mat-footer-cell>
                            {{
                                totalAggregate / totalCount
                                    | roundUpNumber
                                    | commaFormat
                            }}
                        </td>
                    </ng-container>

                    <tr
                        *matHeaderRowDef="columnsToDisplay; sticky: true"
                        mat-header-row
                    ></tr>
                    <tr
                        *matRowDef="let row; columns: columnsToDisplay"
                        mat-row
                    ></tr>
                    <tr *matFooterRowDef="columnsToDisplay" mat-footer-row></tr>
                </table>
            </div>
            <div *ngIf="reportSubType === ReportDisplayType.BAR_CHART">
                <div
                    *ngIf="selectedBreakdownFieldsCount > 2"
                    class="report-instructions"
                >
                    Unable to create a graph with more than two fields selected
                    for grouping by
                </div>
                <div *ngIf="selectedBreakdownFieldsCount <= 2">
                    <mat-radio-group
                        (change)="onChartDisplayTypeChanged()"
                        [(ngModel)]="chartDisplayType"
                        class="radio-buttons-group"
                        id="aggregateChartDisplayType"
                    >
                        <mat-radio-button [value]="ChartDisplayType.AGGREGATE"
                            >Aggregate</mat-radio-button
                        >
                        <mat-radio-button [value]="ChartDisplayType.COUNT"
                            >Count</mat-radio-button
                        >
                        <mat-radio-button [value]="ChartDisplayType.AVERAGE"
                            >Average</mat-radio-button
                        >
                    </mat-radio-group>
                    <canvas
                        [colors]="chartColors"
                        [datasets]="chartDatasets"
                        [labels]="chartLabels"
                        [legend]="selectedBreakdownFieldsCount > 1"
                        [options]="chartOptions"
                        chartType="bar"
                        mdbChart
                    >
                    </canvas>
                </div>
            </div>
            <div *ngIf="reportSubType === ReportDisplayType.PIE_CHART">
                <div
                    *ngIf="selectedBreakdownFieldsCount > 2"
                    class="report-instructions"
                >
                    Unable to create a graph with more than two fields selected
                    for grouping by
                </div>
                <div *ngIf="selectedBreakdownFieldsCount <= 2">
                    <mat-radio-group
                        (change)="onChartDisplayTypeChanged()"
                        [(ngModel)]="chartDisplayType"
                        class="radio-buttons-group"
                        id="aggregatePieChartDisplayType"
                    >
                        <mat-radio-button [value]="ChartDisplayType.AGGREGATE"
                            >Aggregate</mat-radio-button
                        >
                        <mat-radio-button [value]="ChartDisplayType.COUNT"
                            >Count</mat-radio-button
                        >
                        <mat-radio-button [value]="ChartDisplayType.AVERAGE"
                            >Average</mat-radio-button
                        >
                    </mat-radio-group>
                    <canvas
                        [colors]="chartColors"
                        [datasets]="chartDatasets"
                        [labels]="chartLabels"
                        [legend]="selectedBreakdownFieldsCount > 1"
                        [options]="chartOptions"
                        chartType="pie"
                        mdbChart
                    >
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="report-title-container">
        <input
            [(ngModel)]="reportName"
            class="report-bottom-title"
            placeholder="Aggregate"
        />
    </div>
</div>
