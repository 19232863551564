export class TilesUtils {
    public static getAllVisibleTiles(mapBounds, zoom): any[] {
        if (!mapBounds) {
            return;
        }

        const tiles = [];

        const northEast = mapBounds.getNorthEast();
        const southWest = mapBounds.getSouthWest();

        const north = northEast.lat();
        const east = northEast.lng();
        const south = southWest.lat();
        const west = southWest.lng();

        for (
            let x = Math.floor(this.lng2tile(west, zoom));
            x <= Math.floor(this.lng2tile(east, zoom));
            x++
        ) {
            for (
                let y = Math.floor(this.lat2tile(north, zoom));
                y <= Math.floor(this.lat2tile(south, zoom));
                y++
            ) {
                tiles.push({ x, y, zoom });
            }
        }
        return tiles;
    }

    public static lng2tile(lon, zoom) {
        return ((lon + 180) / 360) * Math.pow(2, zoom);
    }

    public static lat2tile(lat, zoom) {
        return (
            ((1 -
                Math.log(
                    Math.tan((lat * Math.PI) / 180) +
                        1 / Math.cos((lat * Math.PI) / 180)
                ) /
                    Math.PI) /
                2) *
            Math.pow(2, zoom)
        );
    }
}
