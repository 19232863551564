import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, HostListener,
    Input, OnDestroy,
    OnInit,
    ViewChild,
    ViewRef
} from '@angular/core';
import { Dataset } from '../../../model/dataset/dataset';
import { DatapointsService } from '../../../data-access-layer/datapoints/datapoints.service';
import { ClusteringService } from '../../clustering/clustering.service';
import { DatapointFilter } from '../../../model/datapoint/filter/datapoint-filter';
import { DatasetFieldType } from '../../../model/dataset/dataset-field-type';
import { Cluster } from '../../clustering/cluster';
import { DatapointProjection } from '../../../model/datapoint/projection/datapoint-projection';
import { ClusterDatapoint } from '../../clustering/cluster-datapoint';
import { Constants, DistanceUnit } from '../../../constants';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { NotifService } from '../../../core/notification/notif.service';
import { GISUtils } from '../../../core/utils/gis-utils';
import { DatapointsPageStateService } from '../datapoints-page-state.service';
import { DatasetField } from '../../../model/dataset/field/dataset-field';
import { Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { DownloadService } from '../../../data-access-layer/download/download.service';
import { DownloadDatapointRequest } from '../../../model/download/download-datapoint-request';
import { AttachmentUtils } from '../../../core/utils/attachment-utils';
import { DownloadFileType } from '../../../model/download/download-file-type';
import { ClusteringRequest } from '../../clustering/clustering-request';
import { DataPointsServiceState } from '../../../shared/services/datapoints-service-state';
import { ObjectUtils } from 'src/app/core/utils/object-utils';
import { DatapointsFilterService } from '../datapoints-filter.service';
import {AuthService} from '../../../auth/auth.service';
import {PrincipalUser} from '../../../model/auth/principal-user';
import {DatasetFieldScope} from '../../../model/dataset/dataset-field-scope';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'map-datapoints-cluster-panel',
    templateUrl: './datapoints-cluster.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./datapoints-cluster.component.scss']
})
export class DatapointsClusterComponent implements OnInit, OnDestroy {

    private MAXIMUM_RADIUS = 10000;
    private MAXIMUM_RADIUS_FOR_FEET = 1000000000;
    private MAXIMUM_TOP_CLUSTERS = 1000000000;
    private MAX_DATAPOINTS_PER_CLUSTER = 20;
    filter: DatapointFilter;
    @Input() dataset: Dataset;
    @Input() initSettings: ClusteringRequest;

    @ViewChild('table') table: MatTable<ClusterDatapoint>;

    datapointsColumns = ['summary', 'clusteringField', 'percentage'];
    clustersColumns = ['color', 'value'];

    distanceUnits = Object.keys(DistanceUnit);

    clusterClone: Cluster;
    selectedCluster: Cluster;
    clusteringForm: UntypedFormGroup;
    clusteringField: DatasetField;
    clusters: Cluster[] = [];
    clusteringSettings: ClusteringRequest;

    principal: PrincipalUser;
    private readonly subscriptions: Subscription = new Subscription();

    validChars = [
        'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', '.'
    ];

    constructor(private readonly clusteringService: ClusteringService,
        private readonly datapointsService: DatapointsService,
        private readonly datapointFilterService: DatapointsFilterService,
        private readonly dataPointsServiceState: DataPointsServiceState,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly notifService: NotifService,
        private readonly authService: AuthService,
        private readonly downloadService: DownloadService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly cd: ChangeDetectorRef) {
    }


    initClusteringForm() {
        this.clusteringForm = new UntypedFormGroup({
            radius: new UntypedFormControl(this.initSettings && this.initSettings.radius ?
                this.initSettings.radius :
                Constants.DEFAULT_RADIUS, [Validators.required, Validators.max(this.MAXIMUM_RADIUS), Validators.maxLength(7), this.radiusValidator.bind(this)]),
            fieldId: new UntypedFormControl(this.initSettings && this.initSettings.fieldId ?
                this.initSettings.fieldId :
                undefined, Validators.required),
            distanceUnit: new UntypedFormControl(this.initSettings && this.initSettings.distanceUnit ?
                this.initSettings.distanceUnit :
                DistanceUnit.KM, Validators.required),
            limit: new UntypedFormControl(this.initSettings && this.initSettings.limit ?
                this.initSettings.limit :
                Constants.DEFAULT_TOP_CLUSTERS, [Validators.max(this.MAXIMUM_TOP_CLUSTERS), this.forbiddenZeroFirst.bind(this)]),
            minCluster: new UntypedFormControl(this.initSettings && this.initSettings.minCluster ?
                this.initSettings.minCluster :
                null),
            maxCluster: new UntypedFormControl(this.initSettings && this.initSettings.maxCluster ?
                this.initSettings.maxCluster :
                null)
        });
    }

    ngOnInit(): void {
        // initialize clustering panel form
        this.principal = this.authService.getPrincipal();
        this.initClusteringForm();
        this.filter = this.datapointFilterService.getActiveFilter();
        this.subscriptions.add(
            this.datapointFilterService.onFilterChange().pipe(debounceTime(200)).subscribe(newFilter => {
                this.filter = newFilter;
                if (this.clusteringSettings) {
                    // clustering on filter change
                    const projection: DatapointProjection = {
                        datasetID: this.dataset.id,
                        fields: [this.clusteringSettings.fieldId, 'id', this.dataset.fields[this.dataset.mainSummaryFieldIndex].id],
                        geometryPrecision: 25
                    };
                    let radius = this.clusteringSettings.radius;
                    if (this.clusteringSettings.distanceUnit === DistanceUnit.MILES) {
                        radius = GISUtils.milesToKm(radius);
                    }
                    let clonedFilter = ObjectUtils.clone(this.filter);
                    if (this.principal.isSuperadmin) {
                        clonedFilter.groups = [];
                    }
                    let request: ClusteringRequest = {
                        datasetId: this.dataset.id,
                        filter: clonedFilter,
                        projection: projection,
                        fieldId: this.clusteringSettings.fieldId,
                        radius: radius,
                        limit: this.clusteringSettings.limit,
                        distanceUnit: this.clusteringSettings.limit,
                        openSidePanel: false,
                        minCluster: this.clusteringSettings.minCluster,
                        maxCluster: this.clusteringSettings.maxCluster
                    };
                    this.clusteringService.enableClusteringMode(request);}
            }));

        this.subscriptions.add(
            this.clusteringService.onClusteringActivated().subscribe(clusters => {
                this.clusters = clusters.clusters;
                if (this.cd && !(this.cd as ViewRef).destroyed) {
                    this.cd.detectChanges();
                }

                const projection: DatapointProjection = {
                    datasetID: this.dataset.id,
                    fields: [this.clusteringSettings.fieldId, 'id', this.dataset.fields[this.dataset.mainSummaryFieldIndex].id],
                    geometryPrecision: 25
                };
                let radius = this.clusteringSettings.radius;
                this.dataPointsServiceState.emitOnApplySettings({
                    filter: this.filter,
                    projection: projection,
                    radius: radius,
                    fieldId: this.clusteringSettings.fieldId,
                    datasetId: this.dataset.id,
                    limit: this.clusteringSettings.limit,
                    distanceUnit: this.clusteringSettings.distanceUnit,
                    openSidePanel: false,
                    minCluster: this.clusteringSettings.minCluster,
                    maxCluster: this.clusteringSettings.maxCluster
                });
            }));

        this.subscriptions.add(
            this.clusteringService.onClusterSelected().subscribe(cluster => {
                cluster.datapoints.forEach(datapoint => {
                    let percentage = 100 * datapoint.value / cluster.value;
                    datapoint.percentage = percentage.toFixed(2);
                });

                this.clusterClone = ObjectUtils.clone(cluster);
                this.selectedCluster = ObjectUtils.clone(cluster);
                this.selectedCluster.datapoints = cluster.datapoints.slice(0, this.MAX_DATAPOINTS_PER_CLUSTER);

                if (this.cd && !(this.cd as ViewRef).destroyed) {
                    this.cd.detectChanges();
                }

                const projection: DatapointProjection = {
                    datasetID: this.dataset.id,
                    fields: [this.clusteringSettings.fieldId, 'id', this.dataset.fields[this.dataset.mainSummaryFieldIndex].id],
                    geometryPrecision: 25
                };
                let radius = this.clusteringSettings.radius;
                this.dataPointsServiceState.emitOnApplySettings({
                    filter: this.filter,
                    projection: projection,
                    radius: radius,
                    fieldId: this.clusteringSettings.fieldId,
                    datasetId: this.dataset.id,
                    limit: this.clusteringSettings.limit,
                    distanceUnit: this.clusteringSettings.distanceUnit,
                    openSidePanel: true,
                    minCluster: this.clusteringSettings.minCluster,
                    maxCluster: this.clusteringSettings.maxCluster
                });
            }));

    }

    selectCluster(index: number) {
        this.clusteringService.animateCluster(index);
    }

    openLocationProfile(datapoint: ClusterDatapoint) {
        this.dataPointsServiceState.emitOnDataPointSelecetd(datapoint);
    }

    sortDatapoints(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        const fieldId = sort.active;
        let sortedDatapoints = this.selectedCluster.datapoints.sort((a, b) => {
            switch (fieldId) {
                case 'summary':
                    return a.summary.localeCompare(b.summary) * (isAsc ? 1 : -1);
                case 'clusteringField':
                case 'percentage':
                    return (a.value - b.value) * (isAsc ? 1 : -1);
            }
        });
        this.selectedCluster.datapoints = sortedDatapoints;
        this.table.renderRows();
    }

    enableClustering() {
        if (this.clusteringForm.valid) {
            this.clusterClone = undefined;
            this.selectedCluster = undefined;
            this.clusters = [];
            this.clusteringSettings = this.clusteringForm.value;
            this.clusteringField = this.datapointsPageStateService.getActiveDatasetFields().get(this.clusteringSettings.fieldId);
            const projection: DatapointProjection = {
                datasetID: this.dataset.id,
                fields: [this.clusteringSettings.fieldId, 'id', this.dataset.fields[this.dataset.mainSummaryFieldIndex].id],
                geometryPrecision: 25
            };
            let radius = this.clusteringSettings.radius;
            if (this.clusteringSettings.distanceUnit === DistanceUnit.MILES) {
                radius = GISUtils.milesToKm(radius);
            } else if (this.clusteringSettings.distanceUnit === DistanceUnit.FEET) {
                radius = GISUtils.milesToFoot(radius);
            } else if (this.clusteringSettings.distanceUnit === DistanceUnit.METER) {
                radius = GISUtils.milesToMeter(radius);
            }
            let clonedFilter = ObjectUtils.clone(this.filter);
            if (this.principal.isSuperadmin) {
                clonedFilter.groups = [];
            }
            // for normal users groups are mandatory
            this.clusteringService.enableClusteringMode({
                filter: clonedFilter,
                projection: projection,
                radius: radius,
                fieldId: this.clusteringSettings.fieldId,
                datasetId: this.dataset.id,
                limit: this.clusteringSettings.limit,
                openSidePanel: false,
                minCluster: this.clusteringSettings.minCluster ? this.clusteringSettings.minCluster : null,
                maxCluster: this.clusteringSettings.maxCluster ? this.clusteringSettings.maxCluster : null,
            });
        } else {
            this.notifService.error('Please recheck clustering settings');
        }
    }

    disableClustering() {
        this.clusterClone = null;
        this.selectedCluster = null;
        this.clusters = [];
        this.clusteringService.disableClustering();
        this.clusteringSettings = undefined;
        this.dataPointsServiceState.emitOnCancelClustering();
        this.initClusteringForm();
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    downloadClusterContent() {
        let datapointIds = this.clusterClone.datapoints.map(dp => dp.id);
        let request: DownloadDatapointRequest = {
            datapointRequest: {
                limit: 0,
                projection: {
                    datasetID: this.dataset.id,
                    geometryPrecision: 25,
                    fields: this.dataset.fields.map(f => f.id)
                },
                skip: 0,
                sort: null
            },
            filter: { datasetID: this.dataset.id, groups: this.datapointsPageStateService.activeGroups },
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: DownloadFileType.XLSX,
            datapointIds: datapointIds
            // reportRequest: {},
        };
        let fileName = 'cluster-content.xlsx';
        this.downloadService.downloadDatapoints(this.dataset.id, request, fileName).subscribe(
            response => {
                AttachmentUtils.downloadFileWithName(response, fileName);
            }, error => this.notifService.error('Something went wrong during download'));
    }

    get DatasetFieldScope() {
        return DatasetFieldScope;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    // allowing only numbers
    @HostListener ('keydown', ['$event'])
    onKeydown(event: KeyboardEvent) {
        let regExp = new RegExp('\\d+');
        if (!event.key.match(regExp) && !this.validChars.includes(event.key)) {
            event.preventDefault();
        }
    }

   // custom validator / cannot allow starting the number with 0
   forbiddenZeroFirst(control: UntypedFormControl): {[key: string]: boolean} {
        if (control.value !== null) {
            if (control.value.toString().startsWith('0')) {return {zeroFirstIsForbidden : true};
            }
            if (control.value.toString().includes('.')) {
                return {zeroFirstIsForbidden : true};
            }
        }
        return null;
   }

   radiusValidator(control: UntypedFormControl): {[key: string]: boolean} {
       let valueString = control.value.toString();
       if (valueString[0] && valueString[1]) {
           if (valueString[0] === '0' && valueString[1] !== '.') {
               return {radiusValidator : true};
           }
       }
       if (valueString && valueString.startsWith('.')) {
           return {radiusValidator : true};
       }
       let counter = 0;
       for (let i=0; i < valueString.length; i++) {
           if (valueString[i] === '.') {
               counter++;
           }
       }
       if (counter > 1) {
           return {radiusValidator : true};
       }
       return  null;
   }

   distanceUnitCall($event) {
    this.clusteringForm.controls.radius.setValidators(null);
    if ($event === DistanceUnit.FEET || $event === DistanceUnit.METER) {
        this.clusteringForm.controls.radius.setValidators([Validators.required, Validators.max(this.MAXIMUM_RADIUS_FOR_FEET), Validators.maxLength(7), this.radiusValidator.bind(this)]);
    } else {
        this.clusteringForm.controls.radius.setValidators([Validators.required, Validators.max(this.MAXIMUM_RADIUS), Validators.maxLength(7), this.radiusValidator.bind(this)]);
    }
    this.clusteringForm.controls.radius.updateValueAndValidity();      
   }

}
