<div>
    <div class="buttons-container">
        <button (click)="deleteEventNotification()" mat-stroked-button>
            <mat-icon fontIcon="fa-trash-alt" fontSet="far"></mat-icon>
            <span>Delete</span>
        </button>
        <button (click)="saveEventNotification()" mat-stroked-button style="margin-left: 10px" type="submit"
            [disabled]="!notifForm.valid">
            <div class="icon-container-wrap">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    style="position: relative; top:2px;">
                    <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                        <path
                            d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                            stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                            stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <circle stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                            cx="17.507" cy="17.507" r="4.502" />
                        <path stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                            d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                        <path d="M0 0h24.01v24.01H0z" />
                    </g>
                </svg>
            </div>
            <div>Save</div>
        </button>
    </div>
    <form *ngIf="notifForm" (ngSubmit)="saveEventNotification()" [formGroup]="notifForm" class="notif-fields-container">
        <div class="notif-field">
            <label>Name</label>
            <div class="display-flex column w-100 mT-4">
                <input formControlName="notificationName" matInput autocomplete="off" class=" w-100">
                <mat-error
                    *ngIf="notifForm.get('notificationName').touched && notifForm.get('notificationName').errors?.required"
                    class="error">
                    Name is required.
                </mat-error>
                <mat-error
                    *ngIf="notifForm.get('notificationName').touched && notifForm.get('notificationName').errors?.maxlength"
                    class="error">
                    Exceed the maximum.
                </mat-error>
            </div>
        </div>
        <div class="notif-field">
            <label for="overlay">Overlay</label>
            <div class="display-flex column w-100 mT-4">
                <mat-select *ngIf="eligibleOverlays?.length" formControlName="overlayId" id="overlay"
                    class="location-address-select">
                    <mat-option (onSelectionChange)="onSelectedOverlayChange($event)"
                        *ngFor="let overlay of sortArrayByName(eligibleOverlays)"
                        [value]="overlay.id">{{overlay.name}}</mat-option>
                </mat-select>
                <mat-select *ngIf="!eligibleOverlays?.length" [disabled]="true" placeholder="No items available"
                    class="location-address-select"></mat-select>
                <mat-error
                    *ngIf="notifForm.get('overlayId').touched && notifForm.get('overlayId').errors?.required && eligibleOverlays?.length"
                    class="error">
                    Select a field
                </mat-error>
            </div>
        </div>
        <ng-container
            *ngIf="selectedOverlay?.overlayAlertType === alertOverlayType.POINT || selectedOverlay?.overlayAlertType === alertOverlayType.EXTERNAL; else complexFields">
            <div class="notif-field" [style.height]="selectedOverlayFilterDisplayedValue ? 'auto' : '60px'">
                <label [ngClass]="{'error-color' : onSaveFormWithoutFilter}">Overlay Filter</label>
                <div class="overlay-main-label ">
                    <div *ngIf="selectedOverlay?.fields.length" class="display-flex column w-100 mT-4">
                        <mat-select [placeholder]="selectedOverlayFilterDisplayedValue"
                            [matTooltip]="selectedOverlayFilterDisplayedValue" class="location-address-select">
                            <div (click)="$event.stopPropagation()" class="nested-menu-search notification-search">
                                <input (keydown)="$event.stopPropagation()" [(ngModel)]="filterFieldSearchString"
                                    [ngModelOptions]="{standalone: true}" placeholder="Search..."
                                    class="notification-search-input">
                            </div>
                            <ng-container *ngIf="selectedOverlay?.fields">
                                <ng-container
                                    *ngFor="let field of sortArrayByName(selectedOverlay?.fields) | callback: filterFieldSearchFilter">
                                    <mat-option (click)="$event.stopPropagation()"
                                        *ngIf="field.baseType === DatasetFieldType.NUMBER || field.baseType === DatasetFieldType.NUMERIC && !field.isGenerated && !field.tags?.includes('ID')">
                                        <mat-checkbox (change)="onChangeFilterItemsForOverlay(field.selected,field)"
                                            [checked]="field.selected" class="notif-checkbox">
                                            <span>{{field.displayName || field.name}}</span>
                                        </mat-checkbox>
                                    </mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </div>
                    <mat-select *ngIf="!selectedOverlay?.fields.length" [disabled]="true"
                        placeholder="No items available" class="location-address-select"></mat-select>

                    <div class="filter-items-container">
                        <ng-container *ngFor="let filterItem of selectedOverlayFilterItems"
                            [ngSwitch]="filterItem.datasetField.baseType">
                            <ng-container *ngSwitchCase="DatasetFieldType.NUMERIC">
                                <ng-container
                                    *ngTemplateOutlet="overlayNumberFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.NUMBER">
                                <ng-container
                                    *ngTemplateOutlet="overlayNumberFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.TEXT">
                                <ng-container
                                    *ngTemplateOutlet="textFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.DATE_TIME">
                                <ng-container
                                    *ngTemplateOutlet="dateFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div *ngIf="!isOpenedInProfile" class="notif-field"
                [style.height]="locationsFilterDisplayedValue ? 'auto' : '60px'">
                <label [ngClass]="{'error-color' : onSaveFormWithoutFilter}">Vessels Filter</label>
                <div class="overlay-main-label">
                    <div class="display-flex column w-100 mT-4">
                        <mat-select *ngIf="dataset?.fields.length" [placeholder]="locationsFilterDisplayedValue"
                            [matTooltip]="locationsFilterDisplayedValue" class="location-address-select">
                            <div (click)="$event.stopPropagation()" class="nested-menu-search notification-search">
                                <input (keydown)="$event.stopPropagation()" [(ngModel)]="filterFieldSearchString"
                                    [ngModelOptions]="{standalone: true}" placeholder="Search..."
                                    class="notification-search-input">
                            </div>
                            <ng-container
                                *ngFor="let field of sortArrayByName(dataset?.fields) | callback: filterFieldSearchFilter">
                                <mat-option (click)="$event.stopPropagation()"
                                    *ngIf="!field.isGenerated && !field.tags?.includes('ID')">
                                    <mat-checkbox (change)="onChangeFilterItemForLocations(field.selected, field)"
                                        [checked]="field.selected" class="notif-checkbox">
                                        <span>{{field.name}}</span>
                                    </mat-checkbox>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                    <mat-select *ngIf="!dataset?.fields.length" [disabled]="true" placeholder="No items available"
                        class="location-address-select"></mat-select>
                    <div class="filter-items-container">
                        <ng-container *ngFor="let filterItem of locationsFilterItems"
                            [ngSwitch]="filterItem.datasetField.baseType">
                            <ng-container *ngSwitchCase="DatasetFieldType.NUMBER">
                                <ng-container
                                    *ngTemplateOutlet="numberFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.TEXT">
                                <ng-container
                                    *ngTemplateOutlet="textFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem, isVesselFilter: true}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.DATE_TIME">
                                <ng-container
                                    *ngTemplateOutlet="dateFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="selectedOverlay?.overlayAlertType !== alertOverlayType.EXTERNAL;">
                <div class="notif-field" [style.height]="accumulationFilterDisplayedValue ? 'auto' : '60px'">
                    <label [ngClass]="{'error-color' : onSaveFormWithoutFilter}" y>Accumulation Filter</label>
                    <div class="overlay-main-label">
                        <mat-select *ngIf="eligibleAccumulationFields?.length"
                            [placeholder]="accumulationFilterDisplayedValue"
                            [matTooltip]="accumulationFilterDisplayedValue" [multiple]="false"
                            class="location-address-select">
                            <div (click)="$event.stopPropagation()" class="nested-menu-search notification-search">
                                <input (keydown)="$event.stopPropagation()" [(ngModel)]="filterFieldSearchString"
                                    [ngModelOptions]="{standalone: true}" placeholder="Search..."
                                    class="notification-search-input">
                            </div>
                            <ng-container
                                *ngFor="let field of sortArrayByName(eligibleAccumulationFields) | callback: filterFieldSearchFilter">
                                <mat-option (click)="$event.stopPropagation()">
                                    <mat-checkbox (change)="onChangeFilterItemForAccumulation(field.selected, field)"
                                        [checked]="field.selected" class="notif-checkbox">
                                        <span>{{field.name}}</span>
                                    </mat-checkbox>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-select *ngIf="!eligibleAccumulationFields?.length" [disabled]="true"
                            placeholder="No items available" class="location-address-select"></mat-select>
                        <div class="filter-items-container">
                            <ng-container *ngFor="let filterItem of sortAccumulationArray(accumulationFilterItems)"
                                [ngSwitch]="filterItem.datasetField.baseType">
                                <ng-container *ngSwitchCase="DatasetFieldType.NUMBER">
                                    <ng-container
                                        *ngTemplateOutlet="numberFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="notif-field">
                    <label for="breakdownField">Breakdown Field</label>
                    <mat-select *ngIf="breakdownFields?.length" formControlName="locationBreakdownFieldId"
                        id="breakdownField" class="location-address-select">
                        <mat-option [value]="null">None</mat-option>
                        <ng-container *ngFor="let field of breakdownFields">
                            <mat-option [value]="field.id">
                                {{field.name}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-select *ngIf="!breakdownFields?.length" [disabled]="true" placeholder="No items available"
                        class="location-address-select"></mat-select>
                </div>
                <div *ngIf="accumulationFilterItems?.length > 0" class="notif-field autocomplete-dropdown"
                    style="margin-bottom: 15px;">
                    <label>Currency</label>
                    <mat-form-field id="currency">
                        <input type="text" matInput formControlName="currency" [matAutocomplete]="auto" class=" w-100">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredCurrencies | async" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="notif-field">
                    <label for="radius">Radius</label>
                    <div class="overlay-main-label" style="flex-direction: row !important;">
                        <div class="display-flex column w-100 mT-4">
                            <input matInput formControlName="radius" type="number" name="radius" id="radius"
                                autocomplete="off" class=" w-100" />
                            <mat-error *ngIf="notifForm.get('radius').errors?.max" class="error">
                                Exceed the maximum.
                            </mat-error>
                            <mat-error *ngIf="notifForm.get('radius').errors?.required" class="error">
                                Required field.
                            </mat-error>
                            <mat-error *ngIf="notifForm.get('radius').errors?.allowPositiveOnly" class="error">
                                Invalid Radius.
                            </mat-error>
                        </div>
                        <mat-select [(value)]="distanceUnit" class="distance-unit-dropdown km-dropdown">
                            <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                            <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #complexFields>
            <div class="notif-field">
                <label for="field">Fields</label>
                <div class="display-flex column w-100 mT-4">
                    <mat-select *ngIf="selectedOverlay?.fields?.length" formControlName="fieldID" id="field"
                        class="location-address-select">
                        <ng-container *ngFor="let field of sortArrayByName(selectedOverlay.fields)">
                            <mat-option *ngIf="field.displayInDropdowns" [value]="field.id">{{field.displayName ||
                                field.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-select *ngIf="!selectedOverlay?.fields?.length" [disabled]="true"
                        placeholder="No items available" class="location-address-select"></mat-select>
                    <mat-error
                        *ngIf="notifForm.get('fieldID').touched && notifForm.get('fieldID').errors?.required && selectedOverlay?.fields?.length"
                        class="error">
                        Select a field
                    </mat-error>
                </div>
            </div>
            <!-- <div class="notif-field">
                <label>Allowed Regions:</label>
                <div class="display-flex column">
                    <mat-select [ngClass]="{'notif-field-small-input' : isOpenedInProfile}"
                        [placeholder]="regionsDisplayValue" [matTooltip]="regionsDisplayValue">
                        <div (click)="$event.stopPropagation()" class="nested-menu-search">
                            <input (keydown)="$event.stopPropagation()" [(ngModel)]="filterFieldSearchString"
                                [ngModelOptions]="{standalone: true}" placeholder="Search...">
                        </div>
                        <ng-container>
                            <ng-container *ngFor="let region of regions  | callback: filterFieldSearchFilter">
                                <mat-option (click)="$event.stopPropagation()">
                                    <mat-checkbox (change)="toggleRegion($event, region)" [checked]="region.selected"
                                        class="notif-checkbox">
                                        <span>{{region.name}}</span>
                                    </mat-checkbox>
                                </mat-option>
                            </ng-container>
                        </ng-container>
                    </mat-select>
                </div>
            </div> -->
            <div *ngIf="!isOpenedInProfile" class="notif-field"
                [style.height]="locationsFilterDisplayedValue ? 'auto' : '60px'">
                <label [ngClass]="{'error-color' : onSaveFormWithoutFilter}">Vessels Filter</label>
                <div class="overlay-main-label">
                    <div class="display-flex column w-100 mT-4">
                        <mat-select *ngIf="dataset?.fields.length" [placeholder]="locationsFilterDisplayedValue"
                            [matTooltip]="locationsFilterDisplayedValue" class="location-address-select">
                            <div (click)="$event.stopPropagation()" class="nested-menu-search notification-search">
                                <input (keydown)="$event.stopPropagation()" [(ngModel)]="filterFieldSearchString"
                                    [ngModelOptions]="{standalone: true}" placeholder="Search..."
                                    class="notification-search-input">
                            </div>
                            <ng-container
                                *ngFor="let field of sortArrayByName(dataset?.fields) | callback: filterFieldSearchFilter">
                                <mat-option (click)="$event.stopPropagation()"
                                    *ngIf="!field.isGenerated && !field.tags?.includes('ID')">
                                    <mat-checkbox (change)="onChangeFilterItemForLocations(field.selected, field)"
                                        [checked]="field.selected" class="notif-checkbox">
                                        <span>{{field.name}}</span>
                                    </mat-checkbox>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                    <mat-select *ngIf="!dataset?.fields.length" [disabled]="true" placeholder="No items available"
                        class="location-address-select"></mat-select>
                    <div class="filter-items-container">
                        <ng-container *ngFor="let filterItem of locationsFilterItems"
                            [ngSwitch]="filterItem.datasetField.baseType">
                            <ng-container *ngSwitchCase="DatasetFieldType.NUMBER">
                                <ng-container
                                    *ngTemplateOutlet="numberFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.TEXT">
                                <ng-container
                                    *ngTemplateOutlet="textFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem, isVesselFilter: true}"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="DatasetFieldType.DATE_TIME">
                                <ng-container
                                    *ngTemplateOutlet="dateFilterTemplate; context:{fieldName: filterItem.datasetField.name, filterItem: filterItem}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="notif-field email-field">
            <label for="emails">Emails</label>
            <div class="display-flex column w-100">
                <mat-form-field class="example-chip-list w-100"
                    [ngClass]="{'invalid': emailsForm.invalid && emailsForm.touched}">
                    <mat-chip-list #emailsList aria-label="Fruit selection">
                        <input [matChipInputFor]="emailsList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addEmail($event)"
                            [formControl]="emailControl">
                    </mat-chip-list>
                </mat-form-field>
                <mat-error *ngIf="emailsForm.invalid && emailsForm.touched" class="error">
                    Please enter a valid email address.
                </mat-error>
                <div class="emails-list">
                    <mat-chip *ngFor="let email of alertsEmails" (removed)="removeEmail(email)">
                        {{email}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </div>
            </div>

        </div>
    </form>
</div>


<ng-template #numberFilterTemplate let-fieldName="fieldName" let-filterItem="filterItem">
    <div class="filter-item">
        <div style="display: flex;">
            <div class="filter-name" [matTooltip]="fieldName" style="font-weight: bold;">{{fieldName}}:</div>
            <input [(ngModel)]="filterItem.minNumberValue" type="number" placeholder="Min"
                matTooltip="Statistical Minimum: {{filterItem.statistics?.minValue || '-' | commaFormat}}"
                matTooltipPosition="below">
            <span class="filter-text"> to </span>
            <input [(ngModel)]="filterItem.maxNumberValue" type="number" placeholder="Max"
                matTooltip="Statistical Maximum: {{filterItem.statistics?.maxValue || '-' | commaFormat}}"
                matTooltipPosition="below">
        </div>
    </div>
</ng-template>
<ng-template #overlayNumberFilterTemplate let-fieldName="fieldName" let-filterItem="filterItem">
    <div class="filter-item">
        <div class="filter-name" [matTooltip]="fieldName" style="font-weight: bold;">{{fieldName}}:</div>
        <input [(ngModel)]="filterItem.minNumberValue" type="number" placeholder="Min">
        <span class="filter-text"> to </span>
        <input [(ngModel)]="filterItem.maxNumberValue" type="number" placeholder="Max">
    </div>
</ng-template>

<ng-template #textFilterTemplate let-fieldName="fieldName" let-filterItem="filterItem"
    let-isVesselFilter="isVesselFilter">
    <div class="filter-item">
        <div class="filter-name" [matTooltip]="fieldName" style="font-weight: bold;">{{fieldName}}:</div>
        <!-- text field low cardinality-->
        <mat-select *ngIf="!filterItem.datasetField.isHighCardinality else newDeb"
            [matTooltip]="filterItem.displayedSearchValue" [placeholder]="filterItem.displayedSearchValue"
            (closed)="constructFilterItemDisplayValues(filterItem)" class="location-address-select">
            <div *ngIf="filterItem.statistics?.values.length" (click)="$event.stopPropagation()"
                class="nested-menu-search notification-search">
                <input matInput [(ngModel)]="filterStatisticValuesString" placeholder="Search..." autocomplete="off"
                    class="notification-search-input">
                <mat-label *ngIf="filterItem.statistics?.values?.length === 0 ">No values available...</mat-label>
            </div>
            <ng-container *ngIf="filterItem.statistics?.values.length">
                <mat-option *ngIf="isVesselFilter">
                    <div (click)="$event.stopPropagation()" mat-menu-item>
                        <mat-checkbox (change)="selectAllForVesselFilter($event, filterItem)">
                            <span>Select All</span>
                        </mat-checkbox>
                    </div>
                </mat-option>
                <mat-option
                    *ngFor="let value of sortArrayByName(filterItem.statistics?.values) | callback: filterStatisticValuesFilter">
                    <div (click)="$event.stopPropagation()" mat-menu-item>
                        <mat-checkbox [(ngModel)]="filterItem.statisticValues[value]">
                            <span>{{value}}</span>
                        </mat-checkbox>
                    </div>
                </mat-option>
            </ng-container>
            <mat-option *ngIf="!filterItem.statistics?.values.length" [disabled]="true">No items available</mat-option>
        </mat-select>
        <!-- text field high cardinality-->
        <ng-template #newDeb>
            <input id="searchInput" [(ngModel)]="filterItem.searchValue" matInput placeholder="Search contains..."
                autocomplete="off" style="width: 94%;margin-left: 8px;margin-top: 8px;">
        </ng-template>
    </div>
</ng-template>
<ng-template #dateFilterTemplate let-fieldName="fieldName" let-filterItem="filterItem">
    <div class="filter-item">
        <div class="filter-name" [matTooltip]="fieldName" style="font-weight: bold;">{{fieldName}}:</div>
        <mat-form-field class="date-form-field" floatLabel="never">
            <input [(ngModel)]="filterItem.minDateValue" [matDatepicker]="startAt" matInput placeholder="Start date"
                [max]="filterItem.statistics?.maxDateValue" [min]="filterItem.statistics?.minDateValue" readonly>
            <mat-datepicker-toggle [for]="startAt" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #startAt [startAt]="filterItem.statistics?.minValue"></mat-datepicker>
        </mat-form-field>
        <span class="filter-text"> to </span>
        <mat-form-field class="date-form-field" appearance="legacy" floatLabel="never">
            <input [(ngModel)]="filterItem.maxDateValue" [matDatepicker]="endAt" matInput placeholder="End date"
                [max]="filterItem.statistics?.maxDateValue" [min]="filterItem.statistics?.minDateValue" readonly>
            <mat-datepicker-toggle [for]="endAt" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #endAt></mat-datepicker>
        </mat-form-field>
    </div>
</ng-template>
