<div class="panel flex column left-panel-wrapper" [class.collapsed]="!isExpanded" [class.disabled]="!isMapView || !isEnabled">
    <div class="panel-name left-panel-name">
        <span>Layers</span>
        <button (click)="onExpandedClick()" class="panel-toggle-icon"><mat-icon>{{isExpanded ? 'first_page' :
                'last_page'}}</mat-icon></button>
    </div>
    <div class="panel-body details-expanded">
        <div class="overlay-list left-panel-inner-wrapper">
            <ul class="tree" style="display: inline-block;">
                <li class="tree-row left-panel-rows">
                    <hr class="menu-line-separator" />
                    <div class="tree-node"
                        [ngClass]="{'selected': dataset?.id === selectedOverlay?.id, 'no-bg' : colorizationBoxIsClosed}">
                        <div class="node-navigation"></div>
                        <mat-checkbox [disableRipple]="true" [(ngModel)]="dataset.isSelected"
                            (ngModelChange)="overlayWasToggled(dataset, null, dataset.isSelected)">
                            <span class="tree-overlay" (click)="selectOverlay(dataset, $event)"> {{dataset.name}}</span>
                        </mat-checkbox>
                    </div>
                </li>


                <!-- Rendeted Tessdata Groups Here -->

                <!-- <ng-template #ExtrnaltreeView let-tessadataNodes>
                    <li *ngFor="let groupNode of tessadataNodes" class="tree-row left-panel-rows">
                        <hr class="menu-line-separator" />
                        <div class="tree-node">
                            <div class="node-navigation">
                                <mat-icon class="mat-icon-rtl-mirror" (click)="groupNode.isOpen = !groupNode.isOpen"
                                    *ngIf="groupNode.children.length || groupNode.overlays.length">
                                    {{groupNode.isOpen ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </div>
                            <mat-checkbox [disabled]="groupNode.disabled"
                                (change)="ExternalgroupWasChecked(groupNode, $event.checked)" [checked]="false">
                                {{ groupNode.group.name }}
                            </mat-checkbox>
                        </div>

                        <ul [hidden]="!(groupNode.isOpen && groupNode.children?.length > 0)">
                            <ng-container
                                *ngTemplateOutlet="ExtrnaltreeView;  context:{ $implicit: groupNode.children }"></ng-container>
                        </ul>
                        <ul [hidden]="!groupNode.isOpen">
                            <div *ngFor="let overlay of groupNode.overlays" class="tree-row">
                                <div class="tree-node">
                                    <div class="node-navigation"></div>
                                    <mat-checkbox [disableRipple]="true"
                                        [disabled]="!accountOverlaysByIds.get(overlay.id)"
                                        [(ngModel)]="overlay.isSelected"
                                        (ngModelChange)="overlayWasToggled(overlay, groupNode, overlay.isSelected)">
                                        <span class="tree-overlay"
                                            (click)="selectOverlay(overlay, $event)">{{overlay.name}}</span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </ul>
                    </li>
                </ng-template>
                <ng-container *ngTemplateOutlet="ExtrnaltreeView; context:{ $implicit: tessadataNodes }"></ng-container> -->

                <!-- End Of Tessadata Rendering -->


                <!-- Rendeted Global Overlay Groups Here -->
                <ng-template #treeView let-groupNodes>
                    <li *ngFor="let groupNode of groupNodes" class="tree-row left-panel-rows">
                        <hr class="menu-line-separator" />
                        <div class="tree-node">
                            <div class="node-navigation">
                                <mat-icon classgit="mat-icon-rtl-mirror" (click)="groupNode.isOpen = !groupNode.isOpen"
                                    *ngIf="groupNode.children.length || groupNode.overlays.length">
                                    {{groupNode.isOpen ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </div>
                            <mat-checkbox [disabled]="groupNode.disabled"
                                (change)="groupWasChecked(groupNode, $event.checked)" [checked]="groupNode.isSelected">
                                {{ groupNode.group.name }}
                            </mat-checkbox>
                        </div>

                        <ul [hidden]="!(groupNode.isOpen && groupNode.children?.length > 0)">
                            <ng-container
                                *ngTemplateOutlet="treeView;  context:{ $implicit: groupNode.children }"></ng-container>
                        </ul>
                        <ul [hidden]="!groupNode.isOpen">
                            <div *ngFor="let overlay of groupNode.overlays" class="tree-row">
                                <div class="tree-node"
                                    [ngClass]="{'selected': overlay.id === selectedOverlay?.id, 'no-bg' : colorizationBoxIsClosed}">
                                    <div class="node-navigation"></div>
                                    <mat-checkbox [disableRipple]="true"
                                        [disabled]="!accountOverlaysByIds.get(overlay.id)"
                                        [(ngModel)]="overlay.isSelected"
                                        (ngModelChange)="overlayWasToggled(overlay, groupNode, overlay.isSelected)">
                                        <span class="tree-overlay"
                                            (click)="selectOverlay(overlay, $event)">{{overlay.name}}</span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </ul>
                    </li>
                </ng-template>
                <ng-container *ngTemplateOutlet="treeView; context:{ $implicit: groupNodes }"></ng-container>

                <!-- End Of Global Overlay Groups Here -->

                <li *ngIf="showInteractiveOverlaysGroup" class="tree-row left-panel-rows">
                    <hr class="menu-line-separator" />
                    <div class="tree-node">
                        <div class="node-navigation">
                            <mat-icon class="mat-icon-rtl-mirror"
                                (click)="interactiveOverlaysGroup.isOpen = !interactiveOverlaysGroup.isOpen"
                                *ngIf="interactiveOverlaysGroup.overlays.length">
                                {{interactiveOverlaysGroup.isOpen ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </div>
                        <mat-checkbox [disabled]="interactiveOverlaysGroup.disabled"
                            (change)="imageGroupWasChecked(interactiveOverlaysGroup, $event, true)"
                            [checked]="interactiveOverlaysGroup.isSelected">
                            {{ interactiveOverlaysGroup.name }}
                        </mat-checkbox>
                    </div>

                    <ul [hidden]="!interactiveOverlaysGroup.isOpen">
                        <div *ngFor="let overlay of interactiveOverlaysGroup.overlays" class="tree-row">
                            <div class="tree-node"
                                [ngClass]="{'selected': overlay.id === selectedImageOverlay?.id, 'no-bg' : imageOverlayOpacityBoxIsClosed}">
                                <div class="node-navigation"></div>
                                <mat-checkbox [disableRipple]="true" [(ngModel)]="overlay.isSelected"
                                    (ngModelChange)="imageOverlayWasToggled(overlay, overlay.isSelected, true)">
                                    <span class="tree-overlay" (click)="selectImageOverlay(overlay, $event)">
                                        {{overlay.name}}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </ul>
                </li>
            </ul>
        </div>

        <div *ngIf="selectedOverlay"
            [ngClass]="{'collapsed-box': !colorizationBoxIsExpanded, 'display-none': colorizationBoxIsClosed}"
            class="overlay-details cursor-pointer" style="border-top: 0.8px solid #cccc;">
            <div class="panel-name panel-title-head">

                    <span [matTooltip]="selectedOverlay.name" matTooltipPosition="left">{{selectedOverlay.name}}</span>

                <span class="btns-container">
                    <mat-icon aria-hidden="false" (click)="colorizationBoxIsExpanded = !colorizationBoxIsExpanded">
                        {{colorizationBoxIsExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
                    </mat-icon>
                    <span class="close-btn" (click)="closeColorizationBox()">
                        <!-- cross-icon -->
                        <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#0044F1" stroke-width="1.5">
                                <path d="m1 1 3.333 3.333L1 7.667"/>
                                <path d="M7.333 1 4 4.333l3.333 3.334"/>
                            </g>
                        </svg>
                    </span>
                </span>
            </div>
            <div class="overlay-content" *ngIf="colorizationBoxIsExpanded">
                <mpt-colorization-box *ngIf="dataset" [dataset]="selectedOverlay"
                    (resetRenderingOptions)="resetRenderingOptions($event)" [accountApplicationDataset]="dataset"
                    [renderingOptions]="overlaysRenderingOptions.get(selectedOverlay.id)"
                    (overlaySettingsUpdated)="onOverlaySettingsUpdate($event)">
                </mpt-colorization-box>
            </div>
        </div>

        <div *ngIf="selectedImageOverlay"
            [ngClass]="{'collapsed-box': !imageOverlayOpacityBoxIsExpended, 'display-none': imageOverlayOpacityBoxIsClosed}"
            class="overlay-details image-overlay-container cursor-pointer">
            <div class="panel-name">
                <span [matTooltip]="selectedImageOverlay.name"
                    matTooltipPosition="left">{{selectedImageOverlay.name}}</span>
                <span class="btns-container">
                    <mat-icon aria-hidden="false"
                        (click)="imageOverlayOpacityBoxIsExpended = !imageOverlayOpacityBoxIsExpended">
                        {{imageOverlayOpacityBoxIsExpended ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
                    </mat-icon>
                    <span class="close-btn" (click)="closeBox()">
                         <!-- cross-icon -->
                         <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#0044F1" stroke-width="1.5">
                                <path d="m1 1 3.333 3.333L1 7.667"/>
                                <path d="M7.333 1 4 4.333l3.333 3.334"/>
                            </g>
                        </svg>
                    </span>
                </span>

            </div>
            <div class="overlay-content" *ngIf="imageOverlayOpacityBoxIsExpended">
                <div class="colorization-cell">
                    <div class="colorization-label">OPACITY</div>
                    <mat-slider class="example-margin" min="0" max="1" step="0.1" color="accent"
                        [(ngModel)]="imageOverlayOpacity">
                    </mat-slider>
                </div>
            </div>
        </div>


        <div id="aerisLegend" class="overlay-legends"></div>
    </div>
</div>


<ng-template #node_template></ng-template>
