export class RandomUtils {

    public static generateUUID(): string {
        let dt = new Date().getTime();
        /* tslint:disable:no-bitwise */
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        /* tslint:enable:no-bitwise */
    }


    public static generateRandomString(length: number): string {
        let charSet ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var randomString = '';
        for (var i = 0; i < length; i++) {
            var randomPoz = Math.floor(Math.random() * charSet.length);
            randomString += charSet.substring(randomPoz,randomPoz+1);
        }
        return randomString;
    }

    public static addCommasToNumber(value){
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}
