import { Validators, UntypedFormGroup } from '@angular/forms';
import { RepeatPasswordEStateMatcher, PasswordValidation } from './validators';

export const settingsControlsConfig = {
    id: [{value: '', disabled: true}],
    email: [{value: '', disabled: true}, Validators.required],
    password: [{value: '', disabled: false}],
    newPassword: [{value: '', disabled: false}, PasswordValidation],
    repeatNewPassword: [{value: '', disabled: false}]

};

export function RepeatPasswordValidator(group: UntypedFormGroup) {
    const password = group.controls.newPassword.value;
    const passwordConfirmation = group.controls.repeatNewPassword.value;

    return password === passwordConfirmation ? null : {passwordsNotEqual: true};
}
