import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ShipLocationService {
    private readonly SSE_SHIP_LOCATION_URL: string = `${environment.baseUrl}/marine/location`;
    private eventSource: EventSource;
    private messageSubject: Subject<any> = new Subject<any>();

    constructor() {}

    startShipLocationSSE(datasetId: string) {
        console.log("SSE CONNECTED");
        this.eventSource = new EventSource(
            `${this.SSE_SHIP_LOCATION_URL}/${datasetId}`
        );
        this.eventSource.onmessage = (event) => {
            this.messageSubject.next(event.data);
        };
    }

    stopShipLocationSSE() {
        console.log("SSE DISCONNECTED");
        this.eventSource.close();
    }

    getMessage(): Observable<any> {
        return this.messageSubject.asObservable();
    }
}
