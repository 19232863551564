<div class="panel-container cluster-main-wrapper" style="height: 100%">

    <div class="settings-container">
        <form [formGroup]="clusteringForm">
            <div class="inputs-row">
                <mat-form-field class="cluster-error-fields">
                    <mat-select formControlName="fieldId" placeholder="Field" class="cluster-field-dropdown">
                        <ng-container *ngFor="let field of dataset?.fields">
                            <mat-option *ngIf="field.baseType === DatasetFieldType.NUMBER && field.scope === DatasetFieldScope.INTERNAL" [value]="field.id">{{field.name}} </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="clusteringForm.get('fieldId').errors?.required">
                        Field is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="inputs-row">
                <mat-form-field>
                    <mat-select (valueChange)="distanceUnitCall($event)" formControlName="distanceUnit" placeholder="Unit" class="cluster-field-dropdown">
                        <ng-container *ngFor="let unit of distanceUnits">
                            <mat-option [value]="unit">{{unit}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="cluster-error-fields">
                    <mat-label>Radius</mat-label>
                    <input matInput formControlName="radius" type="text" name="radius" autocomplete="off"/>
                    <mat-error *ngIf="clusteringForm.get('radius').errors?.required">
                        Field is required.
                    </mat-error>
                    <mat-error *ngIf="clusteringForm.get('radius').errors?.max">
                        Exceeds the maximum allowed value.
                    </mat-error>
                    <mat-error *ngIf="clusteringForm.get('radius').errors?.radiusValidator" class="error">
                        Invalid Radius.
                    </mat-error>
                    <mat-error *ngIf="clusteringForm.get('radius').errors?.maxlength" class="error">
                        Exceeds the allowed length.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputs-row">
                <mat-form-field  class="cluster-error-fields">
                    <mat-label>Min Value</mat-label>
                    <input matInput formControlName="minCluster" type="text" name="minCluster" autocomplete="off"/>
                </mat-form-field>

                <mat-form-field  class="cluster-error-fields">
                    <mat-label>Max Value</mat-label>
                    <input matInput formControlName="maxCluster" type="text" name="maxCluster" autocomplete="off"/>
                </mat-form-field>
            </div>

            <div class="inputs-row" style="margin-bottom: 10px;">
                <mat-form-field  class="cluster-error-fields">
                    <mat-label>Top clusters</mat-label>
                    <input matInput formControlName="limit" type="text" name="limit" autocomplete="off"/>
                    <mat-error *ngIf="clusteringForm.get('limit').errors?.zeroFirstIsForbidden" class="error">
                        Invalid Top clusters.
                    </mat-error>
                    <mat-error *ngIf="clusteringForm.get('limit').errors?.max">
                        Exceeds the maximum allowed value.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputs-row">
                <button mat-button mat-flat-button color="accent" (click)="enableClustering()">
                    Apply
                </button>
                <button mat-button mat-flat-button color="primary" (click)="disableClustering()">
                    Cancel
                </button>
            </div>
        </form>
    </div>
    <div *ngIf="clusters?.length && clusteringSettings.limit" class="table-container" style="overflow: auto;">
        <table mat-table [dataSource]="clusters" class="report-table">

            <ng-container [matColumnDef]="'color'">
                <th mat-header-cell *matHeaderCellDef>Color</th>
                <td class="table-row" mat-cell *matCellDef="let cluster" >
                    <div style="width: 100%; height: 10px; opacity: 0.8" [style.background-color]="cluster.color || 'grey'">

                    </div>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="'value'">
                <th mat-header-cell *matHeaderCellDef>{{clusteringField.name}}</th>
                <td mat-cell *matCellDef="let element"> {{element.value | commaFormat}}</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="clustersColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: clustersColumns; let i = index" (click)="selectCluster(i)" class="table-row"></tr>
        </table>
    </div>
    <div *ngIf="selectedCluster" class="cluster-content-title">
        <div>Cluster content ({{clusterClone.datapoints.length}})</div>
        <div>
            <div [matMenuTriggerFor]="actionsMenu" class="actions-btn"><i class="fas fa-ellipsis-v"></i></div>
            <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
                <div mat-menu-item>
                    <span><mat-icon fontIcon="fa-envelope" fontSet="far"></mat-icon></span>
                    Send to Email
                </div>
                <div mat-menu-item (click)="downloadClusterContent()">
                    <span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon></span>
                    Download
                </div>

            </mat-menu>
        </div>
    </div>
    <div *ngIf="selectedCluster" class="table-container" style="overflow: auto;">
        <table mat-table #table
               matSort matSortDisableClear (matSortChange)="sortDatapoints($event)"
               [dataSource]="selectedCluster.datapoints" class="report-table">

            <ng-container [matColumnDef]="'summary'">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{dataset.fields[dataset.mainSummaryFieldIndex].name}}</th>
                <td mat-cell *matCellDef="let datapoint" (click)="openLocationProfile(datapoint)">{{datapoint.summary}}</td>
                <td mat-footer-cell *matFooterCellDef>Total: {{selectedCluster.datapointsCount | commaFormat}}</td>
            </ng-container>

            <ng-container [matColumnDef]="'clusteringField'">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{clusteringField.name}}</th>
                <td mat-cell *matCellDef="let datapoint">{{datapoint.value | commaFormat}}</td>
                <td mat-footer-cell *matFooterCellDef>Total: {{selectedCluster.value | commaFormat}}</td>
            </ng-container>

            <ng-container [matColumnDef]="'percentage'">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Total %</th>
                <td mat-cell *matCellDef="let element"> {{element.percentage}}%</td>
                <td mat-footer-cell *matFooterCellDef>100%</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="datapointsColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: datapointsColumns" class="table-row" (click)="openLocationProfile(row)"></tr>
            <tr mat-footer-row *matFooterRowDef="datapointsColumns" style="background-color: #ececec"></tr>
        </table>
    </div>


</div>
