<div class="page-root">
    <map-overlay>
        <map-overlay-tabs  [breadCrumbs]="breadCrumbs" [componentName]="componentName"></map-overlay-tabs>
    </map-overlay>
    <!-- <div class="breadcrumbs">
        <span>Home</span>
        <span>External Overlays</span>
    </div> -->

    <div class="page">
        <div class="page-container grey-background">
            <div class="table-actions">
                <div class="toolbar-title">
                    Tessadata Overlays
                </div>
                <div class="toolbar-container">
                    <map-filter-input (keyup)="applyFilter($event.target.value)"></map-filter-input>
                    <button [routerLink]="['../externaloverlay-tree']" mat-flat-button color="primary">
                        <mat-icon fontIcon="fa-project-diagram" fontSet="fas"></mat-icon>
                        <span>Edit Overlay Tree</span>
                    </button>

                    <button (click)="createExternalOverlay()" mat-flat-button color="accent">
                        <mat-icon fontIcon="fa-plus-circle" fontSet="fas"></mat-icon>
                        <span>Create Overlay</span>
                    </button>
                </div>
            </div>

           <div class="table-container">
                <div class="table-content">
                    <table
                        *ngIf="overlaysDataSource && !isTableDataLoading"
                        [dataSource]="overlaysDataSource"
                        mat-table>
                        <ng-container matColumnDef="Name">
                            <th *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let overlay" mat-cell>{{ overlay.datasetLabel }}</td>
                        </ng-container>
                        <ng-container matColumnDef="DatasetID">
                            <th *matHeaderCellDef mat-header-cell>DatasetID</th>
                            <td *matCellDef="let overlay" mat-cell>{{ overlay.externalDatasetKey }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Group">
                            <th *matHeaderCellDef mat-header-cell>Group</th>
                            <td *matCellDef="let overlay" mat-cell>
                                {{ groupsByIds.get(overlay.externalGroupId)?.name }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Actions">
                            <th *matHeaderCellDef mat-header-cell>Actions</th>
                            <td *matCellDef="let overlay" mat-cell class="table-cell-icons">
                            <span (click)="editOverlay(overlay)">
                                <mat-icon aria-hidden="false" fontIcon="fa-pencil" fontSet="fas"></mat-icon>
                            </span>
                                <span (click)="deleteOverlay(overlay)">
                                <mat-icon aria-hidden="false" fontIcon="fa-trash-alt" fontSet="fas"></mat-icon>
                            </span>
                                <span (click)="openSidebar(overlay)">
                                <mat-icon aria-hidden="false" fontIcon="fa-key" fontSet="fas"></mat-icon>
                            </span>
                            <!-- <span (click)="refreshOverlay(overlay.id)" *ngIf="overlay.isRefreshable">
                                <mat-icon aria-hidden="false" fontIcon="fa-redo" fontSet="fas"></mat-icon>
                            </span> -->
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="currentDisplayedColumns; sticky: true" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: currentDisplayedColumns"
                            mat-row>
                        </tr>
                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
