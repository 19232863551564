import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const EmailValidation = [Validators.required, Validators.email];
export const PasswordValidation = [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(24),
];

export class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return (control && control.parent.get('newPassword').value !== control.parent.get('repeatNewPassword').value && control.dirty);
    }
}

export function RepeatPasswordValidator(group: UntypedFormGroup) {
    const password = group.controls.newPassword.value;
    const passwordConfirmation = group.controls.repeatNewPassword.value;

    return password === passwordConfirmation ? null : {passwordsNotEqual: true};
}
