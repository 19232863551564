<div class="dialog location-panel-dialog">
    <div class="dialog-title">
        <!-- cross-icon -->
        <span  (click)="onDismiss()" style="cursor: pointer">
            <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#0044F1" stroke-width="1.5">
                    <path d="m1 1 3.333 3.333L1 7.667"/>
                    <path d="M7.333 1 4 4.333l3.333 3.334"/>
                </g>
            </svg>
        </span>
    </div>
    <div class="dialog-content">
        <div class="confirm-content">
            <div class="dialog-title">
                {{ title }}
            </div>
            <div class="dataset-tree">
                <app-multi-level-dropdown [items]="dropdownItems" [lastItemDisable]="isDisabled" (selectedItems)="onSelectedItemsChanged($event)" ></app-multi-level-dropdown>
            </div>
            <div class="confirm-actions">
                <button (click)="onConfirm()" class="small" mat-flat-button color="accent">{{confirmButton}}</button>
                <button (click)="onDismiss()" class="small" mat-flat-button color="primary">{{cancelButton}}</button>
            </div>
        </div>
    </div>
</div>
