import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
} from "@angular/core";
import { EventNotificationService } from "../../../data-access-layer/notification/event-notification.service";
import { EventNotificationSettings } from "../../../model/notifications/event-notification-settings";
import {
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Dataset } from "../../../model/dataset/dataset";
import { DatasetField } from "../../../model/dataset/field/dataset-field";
import { DatapointsFilterService } from "../../datapoints/datapoints-filter.service";
import { FilterBarItem } from "../../../model/datapoint/draft/table/filter-bar-item";
import { DatasetFieldType } from "../../../model/dataset/dataset-field-type";
import { DistanceUnit, FIRST_FIELD_ID } from "../../../constants";
import { NotifService } from "../../../core/notification/notif.service";
import { DatasetService } from "../../../data-access-layer/dataset/dataset.service";
import { DatapointFilterField } from "../../../model/datapoint/filter/datapoint-filter-field";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { GroupService } from "../../../data-access-layer/groups/group.service";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { A, COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { AlertsType } from "src/app/model/notifications/alerts-type";
import { MMSI_FIELD_TAG } from "src/app/fields/field/field.constants";
import {
    sortAccumulationArray,
    sortArrayByName,
} from "src/app/core/utils/util-master";

const LIMIT_NUMBER_LENGTH = 16;
const LIMIT_STRING_LENGTH = 50;
const MAX_NUMBER_VALUE = 1.7976931348623157e308;

@Component({
    selector: "map-event-notification",
    templateUrl: "./event-notification.component.html",
    styleUrls: ["./event-notification.component.scss"],
})
export class EventNotificationComponent implements OnInit {
    @Input() eventNotif: EventNotificationSettings;
    @Input() eligibleOverlays: Dataset[];
    @Input() dataset: Dataset;
    @Input() groupsIds: number[];
    @Input() currencies: string[] = [];
    @Input() isOpenedInProfile: Boolean;
    @Output() deleteEventNotif = new EventEmitter<EventNotificationSettings>();
    @Input() MMSI: string = null;

    emailControl = new FormControl("", [
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}"),
    ]);
    emailsForm = new FormGroup({
        email: this.emailControl,
    });

    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    alertsEmails: string[] = [];

    notifForm: UntypedFormGroup;

    filterFieldSearchString: string;
    filterFieldSearchFilter: (field: DatasetField) => boolean;
    filterStatisticValuesString: string;
    filterStatisticValuesFilter: (value: string) => boolean;

    selectedOverlay: Dataset;
    selectedOverlayFilterItems: FilterBarItem[] = [];
    selectedOverlayFilterDisplayedValue: string;

    locationsFilterItems: FilterBarItem[] = [];
    locationsFilterDisplayedValue: string;

    accumulationFilterItems: FilterBarItem[] = [];
    accumulationFilterDisplayedValue: string;
    eligibleAccumulationFields: DatasetField[] = [];

    filteredCurrencies: Observable<string[]>;
    distanceUnit: DistanceUnit = DistanceUnit.KM;
    onSaveFormWithoutFilter: boolean;

    breakdownFields: DatasetField[] = [];

    regionsDisplayValue: string = "";
    regions = [];
    selectedRegions = [];
    readonly EXTERNAL_TYPE: string = "external";

    // readonly DATASET_GEOMETRY_TYPE = DatasetGeometryType;

    get alertOverlayType() {
        return AlertsType;
    }

    get sortArrayByName() {
        return sortArrayByName;
    }

    get sortAccumulationArray() {
        return sortAccumulationArray;
    }

    constructor(
        private readonly eventNotifService: EventNotificationService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly notifService: NotifService,
        private readonly groupService: GroupService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly datasetService: DatasetService,
        private readonly datapointAggregateService: DatapointsAggregateService
    ) {
        this.filterFieldSearchString = "";
        this.filterFieldSearchFilter = (field: DatasetField) => {
            return field.name
                ?.toLowerCase()
                .includes(this.filterFieldSearchString.toLowerCase());
        };
        this.filterStatisticValuesString = "";
        this.filterStatisticValuesFilter = (value: string) => {
            return value
                .toLowerCase()
                .includes(this.filterStatisticValuesString.toLowerCase());
        };
        this.locationsFilterDisplayedValue = "";
        this.accumulationFilterDisplayedValue = "";
    }

    ngOnInit() {
        this.dataset = JSON.parse(JSON.stringify(this.dataset));

        this.breakdownFields = this.dataset.fields.filter(
            (field) =>
                field.baseType === DatasetFieldType.TEXT &&
                !field.isGenerated &&
                !field.tags.includes("ID")
        );

        this.eligibleOverlays = JSON.parse(
            JSON.stringify(this.eligibleOverlays)
        );
        this.eligibleAccumulationFields = JSON.parse(
            JSON.stringify(this.dataset.fields)
        );
        this.eligibleAccumulationFields =
            this.eligibleAccumulationFields.filter(
                (field) =>
                    field.baseType === DatasetFieldType.NUMBER &&
                    !field.isGenerated &&
                    !field.tags.includes("ID")
            );

        this.initNotifForm();
        this.distanceUnit = this.eventNotif.distanceUnit;
        this.filteredCurrencies =
            this.notifForm.controls.currency.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
            );

        if (
            this.eventNotif?.overlayFilter?.datasetID &&
            this.eventNotif?.type !== this.EXTERNAL_TYPE
        ) {
            this.datasetService
                .getDataset(this.eventNotif.overlayFilter.datasetID)
                .subscribe((dataset) => {
                    this.selectedOverlay = {
                        ...dataset,
                        overlayAlertType: this.eventNotif.type,
                    };

                    this.changeDetector.detectChanges();
                    this.eventNotif.overlayFilter.fields.forEach(
                        (filterField) => {
                            let datasetField = dataset.fields.find(
                                (field) => field.id === filterField.id
                            );
                            if (datasetField) {
                                datasetField.selected = true;
                                let filterBarItem =
                                    this.constructFilterItemForOverlay(
                                        datasetField,
                                        filterField.textValues
                                    );
                                this.populateFilterBarItem(
                                    filterBarItem,
                                    filterField
                                );
                                this.changeDetector.detectChanges();
                            }
                        }
                    );
                    this.selectedOverlayFilterDisplayedValue =
                        this.constructFilterDisplayedValue(
                            this.selectedOverlayFilterItems
                        );

                    if (this.eventNotif.fieldID) {
                        this.getRegions(this.eventNotif.fieldID);
                    }
                });
        }

        if (this.eventNotif.locationFilter) {
            this.eventNotif.locationFilter.fields.forEach((filterField) => {
                let datasetField = this.dataset.fields.find(
                    (field) => field.id === filterField.id
                );
                datasetField.selected = true;
                let filterBarItem = this.constructFilterItemForLocations(
                    datasetField,
                    filterField.textValues
                );
                this.populateFilterBarItem(filterBarItem, filterField);
            });
            this.locationsFilterDisplayedValue =
                this.constructFilterDisplayedValue(this.locationsFilterItems);
        }

        if (
            this.eventNotif.overlayFilter &&
            this.eventNotif?.type !== this.EXTERNAL_TYPE
        ) {
            this.eventNotif.overlayFilter.fields.forEach((filterField) => {
                let datasetField = this.dataset.fields.find(
                    (field) => field.id === filterField.id
                );
                if (datasetField) {
                    datasetField.selected = true;
                    let filterBarItem = this.constructFilterItemForLocations(
                        datasetField,
                        filterField.textValues
                    );
                    this.populateFilterBarItem(filterBarItem, filterField);
                }
            });
            this.locationsFilterDisplayedValue =
                this.constructFilterDisplayedValue(this.locationsFilterItems);
        }

        if (this.eventNotif?.type === this.EXTERNAL_TYPE) {
            this.selectedOverlay = this.eligibleOverlays.find(
                (dataset) => dataset.id === this.eventNotif.overlayId
            );

            this.eventNotif.overlayFilter.fields.forEach((filterField) => {
                let datasetField = this.selectedOverlay.fields.find(
                    (field) => field.id === filterField.id
                );
                datasetField.selected = true;
                let filterBarItem = this.constructFilterItemForOverlay(
                    datasetField,
                    filterField.textValues
                );
                this.populateFilterBarItem(filterBarItem, filterField);

                this.selectedOverlayFilterDisplayedValue =
                    this.constructFilterDisplayedValue(
                        this.selectedOverlayFilterItems
                    );

                this.changeDetector.detectChanges();
            });

            this.changeDetector.detectChanges();
        }

        if (this.eventNotif.locationsCumulatedFilter) {
            this.eventNotif.locationsCumulatedFilter.fields.forEach(
                (filterField) => {
                    let datasetField = this.eligibleAccumulationFields.find(
                        (field) => field.id === filterField.id
                    );
                    if (datasetField) {
                        datasetField.selected = true;
                        let filterBarItem =
                            this.constructFilterItemForAccumulation(
                                datasetField,
                                filterField.textValues
                            );
                        this.populateFilterBarItem(filterBarItem, filterField);
                    }
                }
            );
            this.accumulationFilterDisplayedValue =
                this.constructFilterDisplayedValue(
                    this.accumulationFilterItems
                );
        }

        if (this.eventNotif.regions) {
            this.selectedRegions = this.eventNotif.regions.map((region) => {
                return region;
            });
            this.regionsDisplayValue = this.updateRegionsDisplayValue();
        }

        if (this.eventNotif.emails) {
            this.alertsEmails = this.eventNotif.emails;
        }
    }

    private initNotifForm() {
        this.notifForm = this.formBuilder.group(
            new EventNotifControlsConfig(this.formBuilder, this.eventNotif)
        );
        this.onSaveFormWithoutFilter = false;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (this.currencies) {
            return this.currencies.filter(
                (option) => option.toLowerCase().indexOf(filterValue) === 0
            );
        }
    }

    saveEventNotification() {
        // check for float number to allow only two decimals
        this.selectedOverlayFilterItems.forEach((item) => {
            if (item.datasetField.baseType === DatasetFieldType.NUMBER) {
                /*if (item.statistics) {
                    this.assignStatisticalMinMaxValues(item);
                }*/
                item.minNumberValue = this.checkForFloatNumber(
                    item.minNumberValue
                );
                item.maxNumberValue = this.checkForFloatNumber(
                    item.maxNumberValue
                );
            }
        });
        this.accumulationFilterItems.forEach((item) => {
            if (item.datasetField.baseType === DatasetFieldType.NUMBER) {
                /*if (item.statistics) {
                    this.assignStatisticalMinMaxValues(item);
                }*/
                item.minNumberValue = this.checkForFloatNumber(
                    item.minNumberValue
                );
                item.maxNumberValue = this.checkForFloatNumber(
                    item.maxNumberValue
                );
            }
        });
        this.locationsFilterItems.forEach((item) => {
            if (item.datasetField.baseType === DatasetFieldType.NUMBER) {
                /*if (item.statistics) {
                    this.assignStatisticalMinMaxValues(item);
                }*/
                item.minNumberValue = this.checkForFloatNumber(
                    item.minNumberValue
                );
                item.maxNumberValue = this.checkForFloatNumber(
                    item.maxNumberValue
                );
            }
        });

        // Purpose: check min & max validation
        if (
            this.checkMinGreaterThanMax(this.selectedOverlayFilterItems) ||
            this.checkMinGreaterThanMax(this.accumulationFilterItems) ||
            this.checkMinGreaterThanMax(this.locationsFilterItems)
        ) {
            this.notifService.error(
                "Error. Min value cannot be greater than Max value."
            );
            return;
        }
        if (
            this.checkForMaxInputCharactersLength(
                this.selectedOverlayFilterItems
            ) ||
            this.checkForMaxInputCharactersLength(
                this.accumulationFilterItems
            ) ||
            this.checkForMaxInputCharactersLength(this.locationsFilterItems)
        ) {
            this.notifService.error("Error. Exceed the maximum for Max");
            return;
        }
        if (
            this.checkForMinInputCharactersLength(
                this.selectedOverlayFilterItems
            ) ||
            this.checkForMinInputCharactersLength(
                this.accumulationFilterItems
            ) ||
            this.checkForMinInputCharactersLength(this.locationsFilterItems)
        ) {
            this.notifService.error("Error. Exceed the maximum for Min");
            return;
        }
        if (
            this.checkForSearchValueInputCharactersLength(
                this.selectedOverlayFilterItems
            ) ||
            this.checkForSearchValueInputCharactersLength(
                this.accumulationFilterItems
            ) ||
            this.checkForSearchValueInputCharactersLength(
                this.locationsFilterItems
            )
        ) {
            this.notifService.error(
                "Error. Exceed the maximum accepted number of characters."
            );
            return;
        }

        if (this.isOpenedInProfile) {
            this.locationsFilterDisplayedValue = MMSI_FIELD_TAG;
        }

        if (
            !this.locationsFilterDisplayedValue &&
            !this.accumulationFilterDisplayedValue &&
            !this.selectedOverlayFilterDisplayedValue
        ) {
            this.onSaveFormWithoutFilter = true;
            this.notifService.error(
                "Please select at least one Filter option."
            );

            return;
        }

        this.onSaveFormWithoutFilter = false;
        this.eventNotif = this.notifForm.value;
        let overlayFilter =
            this.datapointsFilterService.constructNewFilterObjectFromFilterBarItems(
                this.selectedOverlay.id,
                this.selectedOverlayFilterItems
            );
        let locationsFilter =
            this.datapointsFilterService.constructNewFilterObjectFromFilterBarItems(
                this.dataset.id,
                this.locationsFilterItems
            );
        let accumulationFilter =
            this.datapointsFilterService.constructNewFilterObjectFromFilterBarItems(
                this.dataset.id,
                this.accumulationFilterItems
            );

        this.eventNotif.locationFilter = locationsFilter;
        this.eventNotif.locationsCumulatedFilter = accumulationFilter;
        this.eventNotif.overlayFilter = overlayFilter;
        this.eventNotif.distanceUnit = this.distanceUnit;
        this.eventNotif.regions = this.selectedRegions;
        this.eventNotif.locationDatapoints = [];
        this.eventNotif.emails = this.alertsEmails;

        if (this.isOpenedInProfile) {
            this.addOpenedProfileVesselInLocationFilter();
        }

        if (this.eventNotif.type !== AlertsType.POINT) {
            this.eventNotif.locationsCumulatedFilter = null;
        }

        if (!this.eventNotif.id) {
            this.createNotification();
        } else {
            this.updateNotification();
        }
    }

    createNotification() {
        const successMessage = "Notification trigger was successfully saved";
        if (
            this.selectedOverlay?.geometryType === DatasetGeometryType.COMPLEX
        ) {
            this.eventNotifService
                .createComplexNotification(this.eventNotif)
                .subscribe(
                    (notif) => {
                        this.showSuccess(notif, successMessage);
                    },
                    (error) => {
                        this.showError();
                    }
                );
        } else {
            this.eventNotifService
                .createNotification(this.eventNotif)
                .subscribe(
                    (notif) => {
                        this.showSuccess(notif, successMessage);
                    },
                    (error) => {
                        this.showError();
                    }
                );
        }
    }
    addOpenedProfileVesselInLocationFilter() {
        this.eventNotif?.locationFilter?.fields.push({
            id: FIRST_FIELD_ID,
            flags: 0,
            minNumberValue: 0,
            maxNumberValue: 0,
            minDateValue: 0,
            maxDateValue: 0,
            textValues: [this.MMSI],
            searchValue: null,
            distanceUnit: null,
        });
    }

    updateNotification() {
        const successMessage = "Notification trigger was successfully updated";
        if (
            this.selectedOverlay?.geometryType === DatasetGeometryType.COMPLEX
        ) {
            this.eventNotifService
                .updateComplexNotification(this.eventNotif)
                .subscribe(
                    (notif) => {
                        this.showSuccess(notif, successMessage);
                    },
                    (error) => {
                        this.showError();
                    }
                );
        } else {
            this.eventNotifService
                .updateNotification(this.eventNotif)
                .subscribe(
                    (notif) => {
                        this.showSuccess(notif, successMessage);
                    },
                    (error) => {
                        this.showError();
                    }
                );
        }
    }

    showSuccess(notif, message) {
        this.eventNotif.id = notif.id;
        this.initNotifForm();
        this.notifService.success(message);
    }

    showError() {
        this.notifService.error(
            "Something went wrong...Please recheck the fields"
        );
    }

    deleteEventNotification() {
        if (this.eventNotif.id) {
            this.eventNotifService
                .deleteNotification([this.eventNotif.id])
                .subscribe();
        }

        this.deleteEventNotif.emit(this.eventNotif);
    }

    populateFilterBarItem(
        filterBarItem: FilterBarItem,
        filterField: DatapointFilterField
    ): FilterBarItem {
        filterBarItem.maxNumberValue =
            filterField.maxNumberValue == MAX_NUMBER_VALUE
                ? undefined
                : filterField.maxNumberValue;
        filterBarItem.minNumberValue =
            filterField.minNumberValue == MAX_NUMBER_VALUE
                ? undefined
                : filterField.minNumberValue;
        filterBarItem.minDateValue =
            filterField.minDateValue > 0 ? filterField.minDateValue : null;
        filterBarItem.maxDateValue =
            filterField.maxDateValue > 0 ? filterField.maxDateValue : null;
        filterBarItem.searchValue = filterField.searchValue;

        return filterBarItem;
    }

    // --------------------------- Overlay ------------------------------
    onChangeFilterItemsForOverlay(selected: boolean, field: DatasetField) {
        field.selected = !field.selected;
        if (field.selected) {
            this.constructFilterItemForOverlay(field, []);
        } else {
            let start = this.selectedOverlayFilterItems.findIndex(
                (item) => item.id === field.id
            );
            this.selectedOverlayFilterItems.splice(start, 1);
        }

        this.selectedOverlayFilterDisplayedValue =
            this.constructFilterDisplayedValue(this.selectedOverlayFilterItems);
        this.checkForSelectedFilters(
            this.selectedOverlayFilterItems,
            this.locationsFilterItems,
            this.accumulationFilterItems
        );

        this.filterFieldSearchString = "";
    }

    checkForSelectedFilters(
        overlaysFilter: FilterBarItem[],
        locationsFilter: FilterBarItem[],
        accumulationFilter: FilterBarItem[]
    ) {
        if (
            overlaysFilter.length > 0 ||
            locationsFilter.length > 0 ||
            accumulationFilter.length > 0
        ) {
            this.onSaveFormWithoutFilter = false;
        } else {
            this.onSaveFormWithoutFilter = true;
            this.notifService.error(
                "Please select at least one Filter option."
            );
        }
    }

    toggleRegion(event, region) {
        if (event.checked) {
            region.selected = true;
            this.selectedRegions.push(region.name);
        } else {
            region.selected = false;
            this.selectedRegions = this.selectedRegions.filter(
                (element) => element !== region.name
            );
        }
        this.filterFieldSearchString = "";
        this.regionsDisplayValue = this.updateRegionsDisplayValue();
    }

    updateRegionsDisplayValue() {
        let displayValue = "";
        this.selectedRegions.forEach((region) => {
            displayValue += region + ", ";
        });
        return displayValue.slice(0, displayValue.length - 2);
    }

    private constructFilterItemForOverlay(
        field: DatasetField,
        selectedStatisticValues: string[]
    ): FilterBarItem {
        let filterBarItem = {
            id: field.id,
            dataset: this.selectedOverlay,
            datasetField: field,
        };
        if (!field.isHighCardinality) {
            this.datapointsFilterService.fetchFilterBarItemStatistics(
                filterBarItem,
                this.selectedOverlay,
                field,
                [],
                selectedStatisticValues
            );
        }
        this.selectedOverlayFilterItems.push(filterBarItem);

        return filterBarItem;
    }

    // --------------------------- End Overlay ------------------------------

    // --------------------------- Locations ------------------------------
    onChangeFilterItemForLocations(selected: boolean, field: DatasetField) {
        field.selected = !field.selected;
        if (field.selected) {
            this.constructFilterItemForLocations(field, []);
        } else {
            let start = this.locationsFilterItems.findIndex(
                (item) => item.id === field.id
            );
            this.locationsFilterItems.splice(start, 1);
        }

        this.locationsFilterDisplayedValue = this.constructFilterDisplayedValue(
            this.locationsFilterItems
        );
        this.checkForSelectedFilters(
            this.selectedOverlayFilterItems,
            this.locationsFilterItems,
            this.accumulationFilterItems
        );

        this.filterFieldSearchString = "";
    }

    private constructFilterItemForLocations(
        field: DatasetField,
        selectedStatisticValues: string[]
    ): FilterBarItem {
        let filterBarItem = {
            id: field.id,
            dataset: this.dataset,
            datasetField: field,
        };
        this.locationsFilterItems.push(filterBarItem);
        if (!field.isHighCardinality) {
            this.datapointsFilterService.fetchFilterBarItemStatistics(
                filterBarItem,
                this.dataset,
                field,
                this.groupsIds,
                selectedStatisticValues
            );
        }
        return filterBarItem;
    }

    // --------------------------- End Locations ------------------------------

    // --------------------------- Accumulation ------------------------------
    onChangeFilterItemForAccumulation(selected: boolean, field: DatasetField) {
        field.selected = !field.selected;
        if (field.selected) {
            this.constructFilterItemForAccumulation(field, []);
        } else {
            let start = this.accumulationFilterItems.findIndex(
                (item) => item.id === field.id
            );
            this.accumulationFilterItems.splice(start, 1);
        }
        this.checkForSelectedFilters(
            this.selectedOverlayFilterItems,
            this.locationsFilterItems,
            this.accumulationFilterItems
        );

        this.accumulationFilterDisplayedValue =
            this.constructFilterDisplayedValue(this.accumulationFilterItems);

        this.filterFieldSearchString = "";
    }

    private constructFilterItemForAccumulation(
        field: DatasetField,
        selectedStatisticValues: string[]
    ): FilterBarItem {
        let filterBarItem = {
            id: field.id,
            dataset: this.dataset,
            datasetField: field,
        };
        this.accumulationFilterItems.push(filterBarItem);
        if (!field.isHighCardinality) {
            this.datapointsFilterService.fetchFilterBarItemStatistics(
                filterBarItem,
                this.dataset,
                field,
                this.groupsIds,
                selectedStatisticValues
            );
        }
        return filterBarItem;
    }

    // --------------------------- End Accumulation ------------------------------

    private constructFilterDisplayedValue(
        filterBarItems: FilterBarItem[]
    ): string {
        let displayValue = "";
        filterBarItems.forEach((filterItem) => {
            displayValue += filterItem.datasetField.name + ", ";
        });
        return displayValue.slice(0, displayValue.length - 2); // remove last comma
    }

    onSelectedOverlayChange(event: any) {
        const overlayId = event.source.value;
        if (event.isUserInput) {
            this.selectedOverlayFilterItems = [];
            this.selectedOverlayFilterDisplayedValue = "";
            this.selectedOverlay = this.eligibleOverlays.find(
                (eligibleOverlay) => eligibleOverlay.id === overlayId
            );
            this.setAlertType();

            console.log(this.selectedOverlay);
        }
        // reset all overlays fields that can be checked
        this.eligibleOverlays.forEach((eligibleOverlay) => {
            if (
                this.selectedOverlay &&
                eligibleOverlay.id !== this.selectedOverlay.id
            ) {
                eligibleOverlay.fields.forEach((field) => {
                    field.selected = false;
                });
            }
        });
    }

    setAlertType() {
        this.notifForm
            .get("type")
            .setValue(this.selectedOverlay?.overlayAlertType);
    }

    // onSelectedFieldIdChange(event: any) {
    //     const fieldID = event.source.value;
    //     this.getRegions(fieldID);
    // }

    getRegions(fieldID: string) {
        const fieldStaticsFilterPayload =
            this.generateFieldStaticsFilterPayload();
        this.datapointAggregateService
            .getDatapointsFieldStatistics(
                this.selectedOverlay.id,
                fieldID,
                fieldStaticsFilterPayload
            )
            .subscribe(
                (response) => {
                    if (response?.values?.length) {
                        this.regions = response?.values?.map((field) => {
                            return {
                                selected: this.selectedRegions.includes(field),
                                name: field,
                            };
                        });
                    }
                },
                (error) => {
                    this.notifService.error("Error fetching field statistics");
                }
            );
    }

    generateFieldStaticsFilterPayload() {
        return {
            datasetID: this.selectedOverlay.id,
            groups: [],
        };
    }

    constructFilterItemDisplayValues(filterItem: FilterBarItem) {
        filterItem.displayedSearchValue =
            this.datapointsFilterService.constructFilterDisplayValue(
                filterItem
            );
    }

    search(value: string) {
        let filter = value.toLowerCase();
        return this.currencies.filter((option) =>
            option.toLowerCase().startsWith(filter)
        );
    }

    assignStatisticalMinMaxValues(item: FilterBarItem) {
        if (item.minNumberValue === undefined || item.minNumberValue === null) {
            item.minNumberValue = item.statistics.minValue;
        }
        if (item.maxNumberValue === undefined || item.maxNumberValue === null) {
            item.maxNumberValue = item.statistics.maxValue;
        }
    }

    /*
    Purpose: check min & max validation
    Date: 8 April 2022
    */
    checkMinGreaterThanMax(list: FilterBarItem[]) {
        let failCounter = 0;
        if (list !== undefined && list.length > 0) {
            for (let item of list) {
                if (item.datasetField.baseType === DatasetFieldType.NUMBER) {
                    if (item.minNumberValue !== undefined) {
                        // If item.minNumberValue is defined, check if item.maxNumberValue is also defined and greater than item.minNumberValue
                        if (
                            item.maxNumberValue === undefined ||
                            item.maxNumberValue === null ||
                            item.minNumberValue < item.maxNumberValue
                        ) {
                            // Validation failed - item.maxNumberValue should be greater than item.minNumberValue
                            return false;
                        } else {
                            failCounter++;
                        }
                    }
                    //    if ((item.minNumberValue !== undefined || item.maxNumberValue !== undefined) && (item.maxNumberValue === undefined || item.minNumberValue > item.maxNumberValue)) {
                    //        return true;
                    //    }
                }
            }
        }
        return failCounter > 0;
    }

    checkForMaxInputCharactersLength(list: FilterBarItem[]) {
        if (list !== undefined && list.length > 0) {
            for (let item of list) {
                if (item.datasetField.baseType === DatasetFieldType.NUMBER) {
                    if (
                        item.maxNumberValue !== undefined &&
                        item.maxNumberValue !== null
                    ) {
                        let maxStringValue;
                        maxStringValue = item.maxNumberValue.toString(10);
                        if (maxStringValue.length > LIMIT_NUMBER_LENGTH) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    checkForMinInputCharactersLength(list: FilterBarItem[]) {
        if (list !== undefined && list.length > 0) {
            for (let item of list) {
                if (item.datasetField.baseType === DatasetFieldType.NUMBER) {
                    if (
                        item.minNumberValue !== undefined &&
                        item.minNumberValue !== null
                    ) {
                        let minStringValue;
                        minStringValue = item.minNumberValue.toString(10);
                        if (minStringValue.length > LIMIT_NUMBER_LENGTH) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    checkForSearchValueInputCharactersLength(list: FilterBarItem[]) {
        if (list !== undefined && list.length > 0) {
            for (let item of list) {
                if (item.datasetField.baseType === DatasetFieldType.TEXT) {
                    if (
                        item.searchValue !== undefined &&
                        item.searchValue !== null
                    ) {
                        if (item.searchValue.length > LIMIT_STRING_LENGTH) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
    }

    checkForFloatNumber(value: number) {
        if (value && value % 1 !== 0) {
            let fixedValue = value.toFixed(2);
            return Number(fixedValue);
        } else {
            return value;
        }
    }

    addEmail(event: MatChipInputEvent): void {
        if (!this.emailsForm.invalid) {
            const value = (event.value || "").trim();
            if (value) {
                this.alertsEmails.push(value);
            }
            event.chipInput!.clear();
        }
    }

    removeEmail(fruit: string): void {
        const index = this.alertsEmails.indexOf(fruit);

        if (index >= 0) {
            this.alertsEmails.splice(index, 1);
        }
    }

    selectAllForVesselFilter(event, filterItemValues) {
        for (const key in filterItemValues.statisticValues) {
            if (filterItemValues.statisticValues.hasOwnProperty(key)) {
                filterItemValues.statisticValues[key] = event.checked;
            }
        }
    }
}

export class EventNotifControlsConfig {
    id = this.eventNotif.id;
    memberId = [this.eventNotif.memberId, [Validators.required]];
    notificationName = [
        this.eventNotif.notificationName,
        [Validators.required, Validators.maxLength(50)],
    ];
    overlayId = [this.eventNotif.overlayId, [Validators.required]];
    datasetId = [this.eventNotif.datasetId, [Validators.required]];
    radius = [
        this.eventNotif.radius,
        [
            Validators.required,
            Validators.max(this.MAXIMUM_RADIUS),
            this.allowOnlyPositiveNumbers.bind(this),
        ],
    ];
    distanceUnit = [this.eventNotif.distanceUnit, [Validators.required]];
    locationBreakdownFieldId = [this.eventNotif.locationBreakdownFieldId];
    currency = [this.eventNotif.currency];
    fieldID = [this.eventNotif.fieldID];
    regions = [this.eventNotif.regions];
    emails = [this.eventNotif.emails];
    type = [this.eventNotif.type];

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly eventNotif: EventNotificationSettings,
        private MAXIMUM_RADIUS = 10000
    ) {}
    // custom validator
    allowOnlyPositiveNumbers(control: UntypedFormControl): {
        [key: string]: boolean;
    } {
        if (control.value !== null) {
            // only positive integers and decimals with 2 decimals only
            let regExp = /^[+]?([0-9]+(?:[\.][0-9]{1,2})?|\.[0-9]+)$/;
            if (!control.value.toString().match(regExp)) {
                return { allowPositiveOnly: true };
            }
        }
        return null;
    }
}
