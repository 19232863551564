<div class="page">
    <div class="dialog">
        <div class="dialog-title">User settings</div>
        <div class="dialog-content user-setting-wrapper">
            <form [formGroup]="userSettingsForm">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input formControlName="email" readonly matInput/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Your current password</mat-label>
                    <input formControlName="password" matInput type="password" placeholder="Type here"/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>New password</mat-label>
                    <input formControlName="newPassword" matInput placeholder="Type here" type="password"/>
                    <mat-hint class="ac-form-field-description">
                        Between 6 and 24 characters.
                    </mat-hint>
                    <mat-error *ngIf="userSettingsForm.get('newPassword').hasError('required')">
                        Password is mandatory.
                    </mat-error>
                    <mat-error *ngIf="userSettingsForm.get('newPassword').hasError('minlength')">
                        Password with less than 6 characters.
                    </mat-error>
                    <mat-error *ngIf="userSettingsForm.get('newPassword').hasError('maxlength')">
                        Password with more than 24 characters.
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Repeat new password</mat-label>
                    <input [errorStateMatcher]="passwordsMatcher" formControlName="repeatNewPassword" matInput
                           type="password" placeholder="Type here"/>
                    <mat-error *ngIf="userSettingsForm.hasError('passwordsNotEqual')">
                        Passwords are different. They should be equal!
                    </mat-error>
                </mat-form-field>
                <span class="instructions">You will be automatically logged out from all devices upon submit.<br> Please re-login using the new password.</span>
                <button (click)="updateUserSettings()" mat-flat-button color="accent">
                    Save
                </button>
                <button (click)="back()" mat-flat-button color="primary">
                    Cancel
                </button>
            </form>
        </div>
    </div>
</div>
