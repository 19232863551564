import { Component } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { CustomErrorStateMatcher } from "../../shared/error-state-matcher";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TwoFactorAuthService } from "../two-factor-auth-service";
import { Constants } from "../../constants";
import { NotifService } from "../../core/notification/notif.service";
import LoginDto from "../../model/auth/login.dto";

@Component({
    selector: "map-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
    readonly loginForm: UntypedFormGroup;
    readonly matcher: CustomErrorStateMatcher;

    show2FACodeInput = false;
    isLoading = false;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authService: AuthService,
        private readonly twoFactorAuthService: TwoFactorAuthService,
        private readonly notifService: NotifService,
        public readonly dialog: MatDialog,
        private readonly router: Router
    ) {
        this.loginForm = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
            code: [""],
        });
        this.matcher = new CustomErrorStateMatcher();
    }

    onSubmit(): void {
        this.isLoading = true;
        const emailValue = this.loginForm.get("email").value;
        const passwordValue = this.loginForm.get("password").value;
        const codeValue = this.loginForm.get("code").value
            ? this.loginForm.get("code").value
            : null;

        this.twoFactorAuthService
            .getTwoFactorAuthUserInfo(emailValue)
            .subscribe(
                (userInfo) => {
                    let loginDto = new LoginDto(
                        emailValue,
                        passwordValue,
                        codeValue
                    );
                    if (
                        userInfo.hasTwoFactorAuthEnabled &&
                        !userInfo.hasConfirmedTwoFactorAuth
                    ) {
                        this.authService.setTemporaryLoginRequest(loginDto);
                        this.router.navigate([
                            `public/two-factor-auth/${emailValue}`,
                        ]);
                    } else {
                        this.authService.login(loginDto).subscribe(
                            (success) => {
                                this.router.navigate(["private/account"]);
                            },
                            (error) => {
                                this.isLoading = false;

                                if (
                                    error.error.errorId ===
                                    Constants.ERROR_CODES.LOGIN_INVALID_2FA_CODE
                                ) {
                                    this.show2FACodeInput = true;
                                } else if (
                                    error.status ===
                                    Constants.ERROR_CODES.FORBIDDEN
                                ) {
                                    this.notifService.error(
                                        error.error.errors[0]
                                    );
                                } else {
                                    this.notifService.error(
                                        error.error.message
                                    );
                                }
                            }
                        );
                    }
                },
                (error) => {
                    this.isLoading = false;
                    if (
                        error.status ===
                        Constants.ERROR_CODES.SERVICE_UNAVAILABLE
                    ) {
                        this.notifService.error(error.error.errors[0]);
                    } else {
                        this.notifService.error(error.error.message);
                    }
                }
            );
    }
}
