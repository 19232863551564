import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { ShipImage } from "src/app/dataset/datapoints/vessel-profile/models";
import { FetchShipsParams } from "src/app/map/map/map.component";

@Injectable({
    providedIn: "root",
})
export class ShipsService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    fetchShips(fetchShipsParams: FetchShipsParams): Observable<any> {
        const token = this.authService.getToken();

        const { datasetId, tile, filter, projection, renderingOptions } =
            fetchShipsParams;

        const stringifiedRenderingOptions = encodeURIComponent(
            JSON.stringify(renderingOptions)
        );
        const stringifiedFilter = JSON.stringify(filter);
        const stringifiedProjection = encodeURIComponent(
            JSON.stringify(projection)
        );

        const { x, y, zoom } = tile;

        return this.http.get(
            `/render/dataset/${datasetId}/datapointtile/${x}/${y}/${zoom}?token=${token}&filter=${stringifiedFilter}&projection=${stringifiedProjection}&renderingOptions=${stringifiedRenderingOptions}`
        );
    }

    getShipImageByMMSI(accountId: any, MMSI: string): Observable<ShipImage> {
        return this.http.get<ShipImage>(
            `/datapoints/${accountId}/${MMSI}/image`
        );
    }

    uploadVesselImage(
        file: any,
        accountId: any,
        MMSI: string
    ): Observable<any> {
        const formData = new FormData();
        for (let f of file) {
            formData.append("file", f);
        }
        return this.http.post<any>(
            `/datapoints/${accountId}/${MMSI}/upload-image`,
            formData
        );
    }

    removeVesselImage(accountId: any, MMSI: string): Observable<any> {
        return this.http.delete<any>(
            `/datapoints/${accountId}/${MMSI}/delete-image`
        );
    }
}
