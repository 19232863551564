import {Validators} from '@angular/forms';

export const fieldControlsConfig = {
    name: [{value: '', disabled: false}, Validators.required],
    type: [{value: '', disabled: false}, null],
    description: [{value: '', disabled: false}, null],
    isSummary: [{value: false, disabled: false}, null],
    isArray: [{value: false, disabled: false}, null],
    isHighCardinality: [{value: false, disabled: false}, null],
    isRequired: [{value: false, disabled: false}, null],
    isFloating: [{value: false, disabled: false}, null],
    floatingOption: [{value: '', disabled: false}, null],
};

export const STREET_FIELD_TAG = 'STREET';
export const CITY_FIELD_TAG = 'CITY';
export const COUNTRY_FIELD_TAG = 'COUNTRY';
export const ZIPCODE_FIELD_TAG = 'ZIPCODE';
export const ID_FIELD_TAG = 'ID';
export const CREATED_ON_FIELD_TAG = 'CREATED_ON';
export const UPDATED_ON_FIELD_TAG = 'UPDATED_ON';
export const MMSI_FIELD_TAG = 'MMSI';
