import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants, DatapointsViewMode } from "src/app/constants";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetService } from "src/app/data-access-layer/dataset/dataset.service";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { Subscription, forkJoin } from "rxjs";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { DatapointsPageStateService } from "../datapoints/datapoints-page-state.service";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { AccountService } from "src/app/data-access-layer/account/account.service";
import { WorkspaceItemService } from "src/app/data-access-layer/workspace-item/workspace-item.service";
import { WorkspaceItemType } from "src/app/model/workspace/workspace-item-type";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { ReportType } from "src/app/model/analytics/report-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { LinkDatasetService } from "src/app/data-access-layer/dataset/link-dataset.service";
import { ReportRequest } from "src/app/model/datapoint/report/report-request";
import { AggregateGroupRequest } from "src/app/model/datapoint/report/aggregate-group-request";
import { DatapointAggregateFieldType } from "src/app/model/datapoint/report/datapoint-aggregate-field-type";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { Aggregate } from "src/app/core/analytics/aggregate";
import { GroupTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import {
    ChartDisplayLabel,
    ChartDisplayType,
} from "src/app/model/analytics/chart-display-type";
import { DatasetFieldSpecificType } from "src/app/model/dataset/dataset-field-specific.type";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { OverlaysConstants } from "src/app/external-overlays/externaloverlays.constants";
import { Grouping } from "src/app/model/dataset/rendering/datapoint-converter-options";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { DatasetFieldFloatingOption } from "src/app/model/dataset/dataset-field-floating-option";
import { Count } from "src/app/core/analytics/count";
import { Ranking } from "src/app/core/analytics/ranking";
import { DatapointsService } from "src/app/data-access-layer/datapoints/datapoints.service";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { TessadataNriFields } from "src/app/core/tessadata/tessadata-nri-fields";
import { isNullOrUndefined, isUndefined } from "src/app/core/utils/util-master";
import { WorkspaceItemDialogComponent } from "../workspace-item/projection/workspace-item-dialog.component";
import { UserStateService } from "src/app/auth/user-state-service";
import { WorkspaceItemAccess } from "src/app/model/workspace/workspace-item-access";
import { RandomUtils } from "src/app/core/utils/random-utils";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import { DownloadReportRequest } from "src/app/model/download/download-report-request";
import { DownloadDatapointRequest } from "src/app/model/download/download-datapoint-request";
import { AttachmentUtils } from "src/app/core/utils/attachment-utils";
import { DownloadService } from "src/app/data-access-layer/download/download.service";
import { NotifService } from "src/app/core/notification/notif.service";
import { DownloadFileType } from "src/app/model/download/download-file-type";
import { DialogModel } from "src/app/model/dialog/dialog-model";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { take } from "rxjs/operators";
import { DownloadItemReportType } from "src/app/model/download/item/download-item-report-type";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { SidePanelComponent } from "src/app/core/side-panel/side-panel.component";
import { SidePanelService } from "src/app/shared/services/side-panel.service";
import { SidePanels } from "src/app/shared/services/side-panel.helper";
import jsPDF from "jspdf";
import { DownloadReport } from "src/app/core/analytics/download-report";
import { ResizeEvent } from "angular-resizable-element";
import { UpdateWorkspaceItemStateRequest } from "src/app/model/workspace/workspace-item-sequence/update-workspace-item-state-request";
import { UpdateWorkspaceItemSequenceRequest } from "src/app/model/workspace/workspace-item-sequence/update-workspace-item-sequence-request.";
import { sequence } from "@angular/animations";
import { ObjectUtils } from "src/app/core/utils/object-utils";
import { UpdateWorkspaceItemRequest } from "src/app/model/workspace/update-workspace-item-request";
import { IndicatorsEditDialog } from "./indicators-edit-dialog/indicators-edit-dialog.component";
import { DashboardService } from "src/app/data-access-layer/dataset/dashboard.service";
import {
    IndicatorField,
    IndicatorsData,
} from "src/app/model/dashboard/indicators-data";
import { StringUtils } from "src/app/core/utils/string-utils";
// import html2pdf from "html2pdf.js";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { NumberFloatingFilter } from "ag-grid-community/dist/lib/filter/provided/number/numberFloatingFilter";
import { Datapoints } from "../datapoints/datapoints";

type AnalitycsComponentType = {
    dataset: Dataset;
    nriFields: any;
    isDashboardCall: boolean;
    climateOverlays: any;
};

enum ReportViewType {
    NONE = 0,
    TABLE = 1,
    BAR_CHART = 2,
    PIE_CHART = 3,
}

enum ChartViewType {
    NONE = 0,
    AGGREGATE = 1,
    COUNT = 2,
    AVERAGE = 3,
}

enum ColumnNumber {
    FIRST_COLUMN = 1,
    SECOND_COLUMN = 2,
    THIRD_COLUMN = 3,
}
@Component({
    selector: "map-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceIemDialog: WorkspaceItemDialogComponent;
    @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
    MaptycsApplication = MaptycsApplication;
    filterFieldSearchFilter: (field: DatasetField) => boolean;
    dashboardWorkspaceItems: WorkspaceItem[] = [];

    dataset: Dataset;
    activeDataset: Dataset;
    thematicLevels: Dataset[] = [];
    linkedDatasets: Dataset[];
    datasetFields: DatasetField[];
    _analytics: WorkspaceItem[];
    _formulas: WorkspaceItem[];
    private readonly subscriptions: Subscription = new Subscription();
    private readonly floatingFieldsDataSubscription: Subscription =
        new Subscription();
    tableColumnsExcludedOverlayGroups: number[];

    aggregateModel = new Aggregate(
        this.datapointsPageStateService,
        this.aggregateService
    );

    rankingModel = new Ranking(
        this.datapointsPageStateService,
        this.datapointService
    );

    filterTreeStrcuture = new TreeStructureUtils();
    aggregateDatas: any = [];
    countDataArray: any = [];
    analyticData: any = [];
    cloneAnalyticData: any = [];
    analyticsColumnOne: any = [];
    analyticsColumnTwo: any = [];
    analyticsColumnThree: any = [];

    columnNumbersArray = [
        ColumnNumber.FIRST_COLUMN,
        ColumnNumber.SECOND_COLUMN,
        ColumnNumber.THIRD_COLUMN,
    ];

    isAnalyticsPrepared = false;

    groups: any[] = [];

    dropdownItems: TreeStructure[] = [];
    selectedDropdownItems: TreeStructure[] = [];
    selectedThematicLevel: any;
    groupAlias: string = "Groups";
    groupAliasForm: UntypedFormGroup;
    sidebarDataValues: any[] = [];
    sidebarCountDataValues: any[] = [];
    thematicMapGrouping: string = Grouping.SUM;
    thematicMapMetric: DatasetField;
    isSidebarDataLoaded: boolean = false;
    selectedGroupIds = [];

    countModel = new Count(
        this.datapointsPageStateService,
        this.aggregateService,
        this.overlayService
    );

    isSuperAdmin: boolean;
    hasAccess: boolean;

    columnNumbers = ColumnNumber;

    indicatorsData: IndicatorsData;

    readonly COUNT_COLUMN_ID = "count";
    readonly AGGREGATE_COLUMN_ID = "aggregate";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    readonly AVERAGE_COLUMN_ID = "average";
    readonly REPORT_TYPE_MASTER = ReportType;

    readonly RANKING_COLUMN_ID = "ranking";

    tessadataFields: {
        nriFields: DatasetField[];
        externalFields: DatasetField[];
    };
    downloadModel = new DownloadReport(
        this.downloadService,
        this.notifService,
        this.dialog
    );
    MIN_DIMENSIONS_PX: number = 320;
    accountId: number;
    overlaysGroupAsTree: any[] = [];
    parentOverlay: any;
    filterAccountOverlays: any = [];

    readonly DEFAULT_FIELD_ID = "0";
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly datasetService: DatasetService,
        private readonly overlayService: OverlaysService,
        private readonly groupService: GroupService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly accountService: AccountService,
        private readonly workspaceItemService: WorkspaceItemService,
        private readonly linkDatasetService: LinkDatasetService,
        private readonly aggregateService: DatapointsAggregateService,
        private readonly changeDetector: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private readonly datapointAggregateService: DatapointsAggregateService,
        private readonly datapointService: DatapointsService,
        private readonly userStateService: UserStateService,
        private readonly downloadService: DownloadService,
        private readonly notifService: NotifService,
        public readonly dialog: MatDialog,
        private readonly sidePanelService: SidePanelService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly dashboardService: DashboardService
    ) {
        this.groupAliasForm = this.formBuilder.group({
            groupAlias: [this.groupAlias, Validators.required],
        });
        this.isSuperAdmin = this.userStateService.isSuperadmin;
    }

    ngOnInit() {
        let datasetId = this.route.snapshot.paramMap.get("datasetId");
        let accountId = +this.route.snapshot.paramMap.get("accountId");
        this.accountId = +this.route.snapshot.paramMap.get("accountId");
        this.accountService.findAccount(accountId).subscribe((account) => {
            this.datapointsPageStateService.activeAccount = account;
            this.activeDataset = account.datasets.filter(
                (dataset) => dataset.id === datasetId
            )[0];
            this.findDataset(datasetId);
        });
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    get ReportType() {
        return ReportType;
    }

    get ChartDisplayType() {
        return ChartDisplayType;
    }

    public get groupsIds() {
        const groupIds = [];
        this.groups.forEach((rootGroupNode) => {
            this.recursiveOperation(rootGroupNode, (groupNode) => {
                groupIds.push(groupNode.value.id);
            });
        });
        return groupIds;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get datasetScope() {
        return DatasetFieldScope;
    }

    setDisplayType(counter, reportSubType: string, columnNumber: number) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        selectedColumnData[counter].reportSubType = reportSubType;

        if (reportSubType === ReportDisplayType.BAR_CHART) {
            if (
                selectedColumnData[counter].reportType ===
                this.REPORT_TYPE_MASTER.AGGREGATE
            ) {
                this.aggregateModel.populateChartData(
                    selectedColumnData[counter]
                );
            } else {
                this.countModel.populateChartData(selectedColumnData[counter]);
            }

            selectedColumnData[counter].chart.chartOptions.scales = {
                yAxes: [
                    {
                        ticks: {
                            callback: (value, index, values) => {
                                return StringUtils.numberWithCommas(value);
                            },
                        },
                    },
                ],
            };
        } else {
            selectedColumnData[counter].chart.chartOptions.scales = null;
        }
        this.saveState(counter, columnNumber);
    }

    findDataset(datasetID: string) {
        this.datasetService.getDataset(datasetID).subscribe((response) => {
            this.dataset = response;
            this.hasAccess =
                this.userStateService.isSuperadmin ||
                this.userStateService.isAccountAdmin(this.dataset.accountID);
            this.fetchDashboardWorkspaceItems();
            this.datapointsPageStateService.setActiveDataset(
                this.activeDataset
            );
            this.datapointsPageStateService.setFilterAccountDatasets([
                this.activeDataset,
            ]);
            this.subscriptions.add(
                forkJoin(
                    this.overlayService.getAllAccountOverlays(
                        this.dataset.accountID
                    ),
                    this.groupService.getGroupsAsTree(
                        this.dataset.id,
                        this.dataset.accountID
                    ),
                    this.workspaceItemService.getAllWorkspaceItemsForUser(
                        this.dataset.id,
                        WorkspaceItemType.DASHBOARD
                    ),
                    this.workspaceItemService.getAllWorkspaceItemsForUser(
                        this.dataset.id,
                        WorkspaceItemType.FORMULA
                    ),
                    this.linkDatasetService.getLinkedAccountApplications(
                        this.dataset.id
                    ),
                    this.overlayService.getOverlayGroupsByTag(
                        OverlaysConstants.HIDE_GROUP_FROM_TABLE_COLUMNS_TAG
                    ),
                    this.overlayService.getOverlayGroupsAsTree(
                        this.dataset.accountID
                    )
                ).subscribe(
                    ([
                        overlays,
                        groups,
                        analytics,
                        formulas,
                        linkedDatasets,
                        overlayGroups,
                        overlaysGroupAsTree,
                    ]) => {
                        this.linkDatasetService
                            .linkedGroupOverlays(this.dataset.id)
                            .subscribe((res) => {
                                this.filterAccountOverlays =
                                    this.sortFilterAccountOverlays(res);
                                this.filterAccountOverlays =
                                    this.prepareFilterAcccountOverlays(
                                        this.filterAccountOverlays
                                    );
                            });
                        this._analytics = analytics;
                        this._formulas = formulas;
                        this.tableColumnsExcludedOverlayGroups =
                            overlayGroups.map((group) => group.id);
                        this.groups = groups;
                        this.linkedDatasets = linkedDatasets;
                        this.prepareThematicLevels(overlays);
                        this.selectedGroupIds = this.groupsIds;
                        this.overlaysGroupAsTree = overlaysGroupAsTree;
                        this.prepareAnalytics();
                        this.prepareOverlays(overlays);
                        this.dropdownItems = this.convertToDropdownItems(
                            this.groups
                        );
                        this.selectedDropdownItems = this.dropdownItems;
                        this.sidebarDataValues = [];
                        this.sidebarCountDataValues = [];
                        this.loadReportData();
                    }
                )
            );
        });
    }

    getAnalyticsFromServer() {
        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.DASHBOARD
            )
            .subscribe((analytics) => {
                this._analytics = analytics;
                this.prepareAnalytics();
            });
    }

    prepareOverlays(overlays: Dataset[]) {
        this.thematicLevels = [];
        overlays.forEach((overlay) => {
            if (overlay?.thematicMapSettings?.isThematicMapDataset) {
                let parentId = overlay?.thematicMapSettings.parentDatasetId;
                this.thematicLevels.push(overlay);
            }
        });
        this.sortThematicLevels();
    }

    sortThematicLevels(): void {
        if (this.thematicLevels?.length) {
            this.thematicLevels = [...this.thematicLevels].sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            this.defaultSelectThematicLevel();
        }
    }

    defaultSelectThematicLevel() {
        if (this.thematicLevels && this.thematicLevels.length) {
            this.selectedThematicLevel = this.thematicLevels[0];
        }
    }

    onLevelChange(levelID: string): void {
        this.selectedThematicLevel = this.thematicLevels.find(
            (level) => level.id === levelID
        );
        this.sidebarDataValues = [];
        this.sidebarCountDataValues = [];
    }

    loadReportData(dialogIndicators: IndicatorField[] = null) {
        this.dashboardService
            .getDashboardIndicators(
                this.datapointsPageStateService.activeAccount.id
            )
            .subscribe((response) => {
                this.indicatorsData = response;
                if (dialogIndicators) {
                    this.indicatorsData.fields = dialogIndicators;
                }

                this.isSidebarDataLoaded = false;
                const datasetId = this.route.snapshot.paramMap.get("datasetId");
                let indicators = [];
                let indicatorsIds = [];

                if (this.indicatorsData?.fields?.length) {
                    indicators = this.indicatorsData.fields.filter(
                        (item) => item.isIndicators === true
                    );
                    indicatorsIds = indicators.map((item) => item.id);
                }

                const filter = {
                    datasetID: datasetId,
                    groups: this.getSelectedGroupIds(),
                    fields: [],
                    links: [],
                };

                const projection = {
                    datasetID: datasetId,
                    fields: indicatorsIds,
                    links: [
                        {
                            datasetID:
                                this.selectedThematicLevel?.id ||
                                this.dataset.id,
                            fields: [
                                this.selectedThematicLevel?.thematicMapSettings
                                    ?.mainThematicFieldId ||
                                    this.DEFAULT_FIELD_ID,
                            ],
                        },
                    ],
                };

                this.datapointAggregateService
                    .getDatapointsCount(datasetId, filter, projection)
                    .subscribe((countResult) => {
                        if (countResult && countResult.count) {
                            this.sidebarCountDataValues = [];
                            this.sidebarCountDataValues.push({
                                id: 0,
                                dataLabel: "Count",
                                dataValue: countResult.count,
                                sequence: 0,
                                disabled: true,
                            });
                        }
                    });

                if (indicators.length) {
                    this.thematicMapMetric = indicators[0];
                    this.addIndicatorsValuesForSidebar(
                        indicators,
                        filter,
                        projection
                    );
                }
            });
    }

    addIndicatorsValuesForSidebar(
        indicators: IndicatorField[],
        filter,
        projection
    ) {
        let loadedCount = 0;
        this.sidebarDataValues = [];
        if (indicators && indicators.length) {
            indicators.forEach((field) => {
                let groups: AggregateGroupRequest[] = [];
                groups.push({
                    datasetID:
                        (this.selectedThematicLevel &&
                            this.selectedThematicLevel.id) ||
                        this.dataset.id,
                    fieldID:
                        this.selectedThematicLevel?.thematicMapSettings
                            .mainThematicFieldId || this.DEFAULT_FIELD_ID,
                });

                let formulaId = `${this.dataset.id}.${field.id}`;
                let reportRequest: ReportRequest = {
                    datasetID: this.dataset.id,
                    aggregateFieldCodes: [
                        {
                            aggregateFieldCode: `VAR_${formulaId}`,
                            id: formulaId,
                        },
                    ],
                    groups: groups,
                    aggregateFieldType: DatapointAggregateFieldType.FIELD,
                };

                const subscription = this.datapointAggregateService
                    .getDatapointsReport(
                        this.dataset.id,
                        filter,
                        reportRequest,
                        projection
                    )
                    .subscribe((result) => {
                        let totalValue = 0;
                        result.groupResults.forEach((groupResult) => {
                            if (
                                field.indicatorsOption ===
                                DatasetFieldFloatingOption.SUM
                            ) {
                                totalValue += groupResult.values[0].result;
                            } else {
                                totalValue +=
                                    groupResult.values[0].result /
                                    groupResult.values[0].count;
                            }
                        });
                        totalValue = Math.round(totalValue * 100) / 100;
                        this.sidebarDataValues.push({
                            id: field.id,
                            dataLabel: `${field.name}`,
                            dataValue: totalValue,
                            sequence: field.sequence,
                            disabled: !this.hasAccess,
                        });

                        loadedCount++;

                        if (loadedCount === indicators.length) {
                            this.sidebarDataValues.sort(
                                (a, b) => a.sequence - b.sequence
                            );
                            this.isSidebarDataLoaded = true;
                        }
                    });

                this.floatingFieldsDataSubscription.add(subscription); // Add the subscription to the floatingFieldsDataSubscription object
            });
        } else {
            this.isSidebarDataLoaded = true;
        }
    }

    convertToDropdownItems(
        data: any[],
        parent?: TreeStructure
    ): TreeStructure[] {
        return data.map((item) => {
            const dropdownItem: TreeStructure = {
                id: item.value.id,
                params: {
                    accountID: item.value.accountID,
                    datasetID: item.value.datasetID,
                    type: item.value.type,
                    parent: parent,
                },
                name: item.value.name,
                selected: true,
                expanded: false,
            };

            if (item.children && item.children.length > 0) {
                dropdownItem.children = this.convertToDropdownItems(
                    item.children,
                    dropdownItem
                );
            }

            return dropdownItem;
        });
    }

    recursiveOperation(
        groupNode: GroupTreeNode,
        operation: (node: GroupTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) =>
            this.recursiveOperation(child, operation)
        );
        // this.changeEvent();
    }

    sortFields(fields: DatasetField[]) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            }
        });
        return fields;
    }

    generateReport(report: any) {
        let abc = this.aggregateModel.generateReport(
            report,
            this.dataset,
            this.selectedGroupIds
        );
    }

    onAggregateFieldSelected(
        fieldSelected: boolean,
        field: DatasetField,
        counter: number,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        let aggregateData = selectedColumnData[counter];
        if (fieldSelected) {
            aggregateData.selectedAggregateField = field;
            aggregateData.aggregateDatasetFields.forEach((f) => {
                f.selected = f.id === field.id;
            });
            aggregateData.selectedFormula = undefined;
            aggregateData.formulas.forEach(
                (formula) => (formula.selected = false)
            );
        } else {
            aggregateData.selectedAggregateField = undefined;
        }
        this.changeDetector.detectChanges();
    }

    onAggregateFieldModelChangeEvent(
        node,
        counter: number,
        columnNumber: number
    ) {
        if (!isUndefined(node.params)) {
            const field = node.params.field;
            if (node.params.callType == "dataset") {
                this.onAggregateFieldSelected(
                    node.selected,
                    node.params?.field,
                    counter,
                    columnNumber
                );
            } else if (node.params.callType == "formula") {
                this.onFormulaSelected(
                    node.selected,
                    node.params?.field,
                    counter,
                    columnNumber
                );
            }
        }
    }

    onFormulaSelected(
        fieldSelected: boolean,
        selectedFormula: WorkspaceItem,
        counter: number,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        let aggregateData = selectedColumnData[counter];
        if (fieldSelected) {
            aggregateData.formulas.forEach((formula) => {
                formula.selected = formula.id === selectedFormula.id;
            });
            aggregateData.selectedFormula = selectedFormula;
            aggregateData.selectedAggregateField = undefined;
            this.datasetFields.forEach((f) => (f.selected = false));
        } else {
            aggregateData.selectedFormula = undefined;
            selectedFormula.selected = false;
            aggregateData.selectedAggregateField = undefined;
        }
        this.changeDetector.detectChanges();
    }

    onFieldsMenuClick(
        fieldSelected: boolean,
        field: DatasetField,
        counter: number,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        let aggregateData = selectedColumnData[counter];

        let datasetFields =
            aggregateData.selectedBreakdownFieldsByDataset.get(
                this.activeDataset.id
            ) || [];

        if (fieldSelected) {
            datasetFields.push(field);
            field.selected = true;
            aggregateData.selectedBreakdownFieldsCount++;
        } else {
            datasetFields = datasetFields.filter((f) => f.id !== field.id);
            field.selected = false;
            aggregateData.selectedBreakdownFieldsCount--;
        }

        if (datasetFields.length === 0) {
            aggregateData.selectedBreakdownFieldsByDataset.delete(
                this.activeDataset.id
            );
        } else {
            aggregateData.selectedBreakdownFieldsByDataset.set(
                this.activeDataset.id,
                datasetFields
            );
        }
    }

    onChartDisplayTypeChanged(
        report: any,
        counter: number,
        columnNumber: number
    ) {
        this.aggregateModel.populateChartData(report);
        report.chartDownloadRequest =
            this.aggregateModel.getChartReportDownloadRequest(
                report,
                this.activeDataset
            );
        this.saveState(counter, columnNumber);
    }

    prepareCommanMenuOptions() {
        let tessadataFields: {
            nriFields: DatasetField[];
            externalFields: DatasetField[];
            tensorflightFields: DatasetField[];
            munichreFields: DatasetField[];
        };
        let tessadataGroupedFields: any[];
        tessadataFields = JSON.parse(
            JSON.stringify(
                this.datapointsPageStateService.getActiveTessadataFields()
            )
        );
        tessadataFields.externalFields.forEach(
            (field) => (field.selected = false)
        );
        tessadataFields.nriFields = this.sortFields(tessadataFields.nriFields);
        if (
            tessadataFields.externalFields &&
            tessadataFields.externalFields.length > 0
        ) {
            let externalDataset = this.sortFields(
                tessadataFields.externalFields
            );
            tessadataGroupedFields = externalDataset.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            for (const key in tessadataGroupedFields) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        tessadataGroupedFields,
                        key
                    )
                ) {
                    tessadataGroupedFields[key] = this.sortFields(
                        tessadataGroupedFields[key]
                    );
                }
            }
        }
        tessadataFields.tensorflightFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            tessadataFields.tensorflightFields &&
            tessadataFields.tensorflightFields.length > 0
        ) {
            let tensorflightDatasets = this.sortFields(
                tessadataFields.tensorflightFields
            );
            let tensorflightGroupFields = tensorflightDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            tessadataGroupedFields =
                !isUndefined(tessadataGroupedFields) &&
                Object.keys(tessadataGroupedFields).length
                    ? { ...tessadataGroupedFields, ...tensorflightGroupFields }
                    : tensorflightGroupFields;
        }
        tessadataFields.nriFields = this.groupByNriFields(
            tessadataFields.nriFields
        );
        this.tessadataFields = tessadataFields;
        return {
            tessadataFields: tessadataFields,
            tessadataGroupedFields: tessadataGroupedFields,
        };
    }

    prepareAnalytics() {
        let reportElementIndex = 0;
        this.analyticData = [];
        this.analyticsColumnOne = [];
        this.analyticsColumnTwo = [];
        this.analyticsColumnThree = [];
        this.analyticData = [];
        const datasetFields = this.dataset.fields;
        this.datasetFields = this.sortFields(
            JSON.parse(JSON.stringify(datasetFields))
        );
        let menuOptionData = this.prepareCommanMenuOptions();

        this._analytics.forEach((analytic) => {
            const analyticParseData = JSON.parse(analytic.data);
            if (analyticParseData.length) {
                analyticParseData.forEach((data) => {
                    switch (data.type) {
                        case ReportType.AGGREGATE: {
                            const aggregateData: any =
                                this.aggregateModel.process(
                                    this.dataset,
                                    reportElementIndex,
                                    analytic,
                                    data,
                                    JSON.parse(JSON.stringify(datasetFields)),
                                    JSON.parse(JSON.stringify(this._formulas)),
                                    this.selectedGroupIds,
                                    ObjectUtils.clone(menuOptionData)
                                );
                            if (aggregateData !== undefined) {
                                aggregateData.id = analytic.id;
                                aggregateData.reportType = ReportType.AGGREGATE;
                                this.analyticData.push(aggregateData);
                            }
                            break;
                        }
                        case ReportType.COUNT: {
                            const countData = this.countModel.process(
                                this.dataset,
                                reportElementIndex,
                                analytic,
                                data,
                                this.selectedGroupIds,
                                ObjectUtils.clone(menuOptionData),
                                this.overlaysGroupAsTree
                            );
                            if (countData) {
                                countData.reportType = ReportType.COUNT;
                                this.analyticData.push(countData);
                            }
                            break;
                        }
                        case ReportType.RANKING: {
                            const rankingData = this.rankingModel.process(
                                this.dataset,
                                reportElementIndex,
                                analytic,
                                data,
                                JSON.parse(JSON.stringify(datasetFields)),
                                JSON.parse(JSON.stringify(this._formulas)),
                                ObjectUtils.clone(menuOptionData),
                                this.selectedGroupIds
                            );
                            if (rankingData !== undefined) {
                                rankingData.id = analytic.id;
                                this.analyticData.push(rankingData);
                            }
                            reportElementIndex++;
                            break;
                        }
                    }

                    reportElementIndex++;
                });
            }
        });
        if (this.analyticData.length) {
            this.analyticData.sort((a, b) => a.sequence - b.sequence);
            this.cloneAnalyticData = ObjectUtils.clone(this.analyticData);
            this.divideAnalyticsData();
        }
    }

    prepareTesadata(tessadataGroups): TreeStructure[] {
        let sortData = [];
        for (const key in tessadataGroups) {
            if (Object.prototype.hasOwnProperty.call(tessadataGroups, key)) {
                const groups = tessadataGroups[key];
                sortData.push({
                    id: groups.value.id,
                    selected: true,
                    name: groups.value.name,
                    children: this.getGroups(groups.children),
                });
            }
        }
        sortData = sortData.sort((a, b) => (a.name < b.name ? -1 : 1));
        return sortData;
    }

    getGroups(groups): TreeStructure[] {
        let prepareGroups = [];
        groups.forEach((group) => {
            prepareGroups.push({
                id: group.value.id,
                name: group.value.name,
                selected: group.selected,
                params: {
                    field: group,
                    showCheckBox: true,
                    callType: "tesadataGroup",
                },
            });
        });
        return prepareGroups;
    }

    prepareThematicLevels(overlays: Dataset[]) {
        overlays.forEach((overlay) => {
            if (overlay?.thematicMapSettings?.isThematicMapDataset) {
                let parentId = overlay?.thematicMapSettings?.parentDatasetId;
                this.thematicLevels.push(overlay);
            }
        });
    }

    getThematicLevelNumber(levelName: string) {
        const levelNameArray: string[] = levelName.split(" ");
        return levelNameArray[levelNameArray.length - 1];
    }

    switchView(viewMode: string): void {
        if (
            viewMode.toUpperCase() === DatapointsViewMode.MAP ||
            viewMode.toUpperCase() === DatapointsViewMode.TABLE
        ) {
            this.router.navigate([
                `private/account/${this.dataset.accountID}/dataset/${this.dataset.id}/datapoints/${viewMode}`,
            ]);
        } else {
            return;
        }
    }

    onSelectedItemsChanged(items: TreeStructure[]): void {
        if (items.length > 0) {
            this.sidebarDataValues = [];
            this.sidebarCountDataValues = [];
            this.analyticsColumnOne = [];
            this.analyticsColumnTwo = [];
            this.analyticsColumnThree = [];
            this.selectedDropdownItems = items;
            this.selectedGroupIds = this.selectedDropdownItems.map((item) => {
                return item.id;
            });
            this.loadReportData();
            this.getAnalyticsFromServer();
        } else {
            this.notifService.error("Please select at least one group");
        }
    }

    removeAnalyticData(counter: number, columnNumber: number) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        const selectedReport = selectedColumnData[counter];
        selectedColumnData.splice(counter, 1);
        this.analyticData = this.analyticData.filter(
            (report) => selectedReport.id !== report.id
        );
    }

    isCountFieldSelected(fieldId: string, countReportData: any): boolean {
        if (
            countReportData.selectedFieldsByDataset
                .get(this.activeDataset.id)
                .find((field) => field.id === fieldId)
        ) {
            return true;
        }
        return false;
    }

    generateCountReport(data: any) {
        const params: any = {
            report: data,
            dataset: this.dataset,
            selectedFieldsByDataset: data.selectedFieldsByDataset,
            groupsIds: this.selectedGroupIds,
        };
        this.countModel.generateReportData(params);
    }

    onCountFieldsMenuClick(
        fieldSelected: boolean,
        field: any,
        reportElementIndex: number,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);

        let selectedFieldsArray = selectedColumnData[
            reportElementIndex
        ].selectedFieldsByDataset.get(this.activeDataset.id);

        if (!selectedFieldsArray) {
            (selectedFieldsArray = []),
                selectedColumnData[
                    reportElementIndex
                ].selectedFieldsByDataset.set(
                    this.activeDataset.id,
                    selectedFieldsArray
                );
        }

        if (fieldSelected) {
            selectedFieldsArray.push(field);
            selectedColumnData[reportElementIndex]
                .selectedFieldsByDatasetCount++;
        } else {
            const filteredArray = selectedFieldsArray.filter(
                (obj) => obj.id !== field.id
            );
            selectedColumnData[reportElementIndex]
                .selectedFieldsByDatasetCount--;
            selectedColumnData[reportElementIndex].selectedFieldsByDataset.set(
                this.activeDataset.id,
                filteredArray
            );
        }
    }

    onRankingFieldSelection(
        fieldSelected: boolean,
        field: DatasetField,
        counter: number,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        let rankingData = selectedColumnData[counter];

        if (fieldSelected) {
            rankingData.selectedRankingField = field;
            let foundIndex = rankingData.datasetFields.findIndex(
                (x) => x.id === field.id
            );
            rankingData.datasetFields[foundIndex].selected = true;
            let findExistingField = rankingData.selectedFields.find(
                (f) => f.id === rankingData.selectedRankingField.id
            );
            if (findExistingField) {
                let index = rankingData.datasetFields.findIndex(
                    (x) => x.id === field.id
                );
                rankingData.selectedFields.splice(index, 1);
            }
        } else {
            rankingData.selectedRankingField = undefined;
            let foundIndex = rankingData.datasetFields.findIndex(
                (x) => x.id === field.id
            );
            rankingData.datasetFields[foundIndex].selected = false;
        }
    }

    generateRankingReport(report: any) {
        let abc = this.rankingModel.generateReport(
            report,
            this.dataset,
            this.selectedGroupIds
        );
    }

    isNotAllDatasetSelected(
        fields: DatasetField[],
        isLocations?: boolean
    ): boolean {
        if (isLocations) {
            return (
                fields.filter(
                    (field) =>
                        !field.selected &&
                        field.scope === this.datasetScope.INTERNAL &&
                        field.id !== "created_on" &&
                        field.id !== "updated_on"
                ).length > 0
            );
        } else {
            return fields.filter((field) => !field.selected).length > 0;
        }
    }

    onRankingFieldsMenuClick(
        fieldSelected: boolean,
        field: DatasetField,
        report: any
    ) {
        if (fieldSelected) {
            report.selectedFields.push(field);
        } else {
            report.selectedFields.splice(
                report.selectedFields.indexOf(field),
                1
            );
        }
    }

    groupByNriFields(nriFields) {
        const groupBy = (array, key) => {
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                let symbol = null;
                if (currentValue[key].trim().split("-").length > 1) {
                    symbol = "-";
                } else if (currentValue[key].trim().split("–").length > 1) {
                    symbol = "–";
                }
                if (isNullOrUndefined(symbol)) {
                    (result[currentValue[key].trim()] =
                        result[currentValue[key].trim()] || []).push({
                        name: currentValue[key].trim(),
                        child: currentValue,
                    });
                } else {
                    (result[currentValue[key].trim().split(symbol)[0].trim()] =
                        result[
                            currentValue[key].trim().split(symbol)[0].trim()
                        ] || []).push({
                        name: currentValue[key].trim().split(symbol)[1],
                        child: currentValue,
                    });
                }
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
        };
        const groupByObjectResponse = groupBy(nriFields, "name");
        let tempArray = [];
        let nriSummaryArray = [
            { name: "NRI Summary", isParentSelected: true, child: [] },
        ];
        Object.keys(groupByObjectResponse).map(function (key) {
            let nameSplitValue = key.split(" ");
            groupByObjectResponse[key][0].child.isProjected = true;
            if (
                [
                    TessadataNriFields.SUMMARY_SOVI_RATING,
                    TessadataNriFields.SUMMARY_RESL_RATING,
                ].includes(groupByObjectResponse[key][0].child.id)
            ) {
                nriSummaryArray[0].child.push({
                    name:
                        nameSplitValue[1] + " " + nameSplitValue[2] + " Rating",
                    child: groupByObjectResponse[key][0].child,
                });
            } else if (
                groupByObjectResponse[key][0].child.id ==
                TessadataNriFields.SUMMARY_EAL_RATING
            ) {
                nriSummaryArray[0].child.push({
                    name: nameSplitValue[1] + " Rating",
                    child: groupByObjectResponse[key][0].child,
                });
            } else if (
                groupByObjectResponse[key][0].child.id ==
                TessadataNriFields.SUMMARY_RISK_RATING
            ) {
                nriSummaryArray[0].child.push({
                    name: "Overall " + nameSplitValue[1],
                    child: groupByObjectResponse[key][0].child,
                });
            } else {
                tempArray.push({
                    name: key,
                    isParentSelected: true,
                    child: groupByObjectResponse[key],
                });
                return tempArray;
            }
        });

        if (nriSummaryArray[0].child.length > 0) {
            tempArray = [...nriSummaryArray, ...tempArray];
        }
        return tempArray;
    }

    // Tree code - start
    hasChild = (_: number, node: TreeStructure) =>
        !!node.children && node.children.length > 0;
    setParent(data, parent) {
        data.parent = parent;
        if (data.children) {
            data.children.forEach((x) => {
                this.setParent(x, data);
            });
        }
    }

    checkAllParents(node, treeControl) {
        if (node.parent) {
            const descendants = treeControl.getDescendants(node.parent);
            node.parent.selected = descendants.every((child) => child.selected);
            node.parent.indeterminate = descendants.some(
                (child) => child.selected
            );
            this.checkAllParents(node.parent, treeControl);
        }
    }
    todoItemSelectionToggle(checked, node, treeControl) {
        node.selected = checked;
        if (node.children) {
            node.children.forEach((x) => {
                this.todoItemSelectionToggle(checked, x, treeControl);
            });
        }
        this.checkAllParents(node, treeControl);
    }
    // Tree code - end

    sortData(
        sort: Sort,
        counter: number,
        reportType: ReportType,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        if (reportType === ReportType.AGGREGATE) {
            selectedColumnData = this.aggregateModel.sortData(
                sort,
                selectedColumnData[counter],
                this.dataset
            );
        } else if (reportType === ReportType.COUNT) {
            selectedColumnData = this.countModel.sortData(
                sort,
                selectedColumnData[counter]
            );
        } else if (reportType === ReportType.RANKING) {
            selectedColumnData = this.rankingModel.sortData(
                sort,
                selectedColumnData[counter]
            );
        }
        this.updateWorkspaceItemName(sort, counter, columnNumber);
    }

    fetchDashboardWorkspaceItems() {
        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.DASHBOARD
            )
            .subscribe((items) => {
                this.dashboardWorkspaceItems = items;
                this.dashboardWorkspaceItems.forEach((item) => {
                    if (
                        this.userStateService.isSuperadmin ||
                        this.userStateService.isAccountAdmin(
                            this.dataset.accountID
                        )
                    ) {
                        item.isDeletable = true;
                    } else {
                        if (item.access === WorkspaceItemAccess.MEMBER) {
                            item.isDeletable = true;
                        }
                    }
                });
            });
    }

    saveWorkspaceItemToDashboard(i: number) {
        const dashboardReportItem = [];
        dashboardReportItem.push(
            this.prepareSpecificWorkspaceItemInfo(this.analyticData[i])
        );

        this.workspaceIemDialog.open(
            dashboardReportItem,
            WorkspaceItemType.DASHBOARD,
            true,
            true
        );
    }

    saveAsWorkspaceItemToDashboard(i: number, columnNumber: number) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        const dashboardReportItem = [];
        dashboardReportItem.push(
            this.prepareSpecificWorkspaceItemInfo(selectedColumnData[i])
        );

        this.workspaceIemDialog.open(
            dashboardReportItem,
            WorkspaceItemType.DASHBOARD,
            false,
            true
        );
    }

    private prepareSpecificWorkspaceItemInfo(report) {
        let formattedReportData: any = {};
        switch (report.reportType) {
            case ReportType.AGGREGATE:
                let aggregateReportComponent = report;
                let jsonObject: any = {};
                jsonObject[this.activeDataset.id] =
                    report.selectedBreakdownFieldsByDataset.get(
                        this.activeDataset.id
                    );

                formattedReportData = {
                    id: RandomUtils.generateUUID(),
                    name: aggregateReportComponent.reportName || "",
                    selectedAggregateField:
                        aggregateReportComponent.selectedFields,
                    selectedBreakdownFieldsByDataset: jsonObject,
                    type: ReportType.AGGREGATE,
                };

                if (aggregateReportComponent.selectedFormula) {
                    formattedReportData.selectedFormula =
                        aggregateReportComponent.selectedFormula;
                } else {
                    formattedReportData.selectedAggregateField =
                        aggregateReportComponent.selectedAggregateField;
                }
                formattedReportData.reportType =
                    aggregateReportComponent.reportType;
                formattedReportData.reportSubType =
                    aggregateReportComponent.reportSubType;
                formattedReportData.chartDisplayType =
                    aggregateReportComponent.chartDisplayType;

                break;
            case ReportType.RANKING:
                let rankingReportComponent = report;
                formattedReportData = {
                    id: RandomUtils.generateUUID(),
                    name: rankingReportComponent.reportName || "",
                    selectedFields: rankingReportComponent.selectedFields,
                    noOfItems: rankingReportComponent.noOfItems,
                    type: ReportType.RANKING,
                };

                if (rankingReportComponent.selectedFormula) {
                    formattedReportData.selectedFormula =
                        rankingReportComponent.selectedFormula;
                } else {
                    formattedReportData.selectedRankingField =
                        rankingReportComponent.selectedRankingField;
                }
                formattedReportData.reportType =
                    rankingReportComponent.reportType;
                formattedReportData.reportSubType =
                    rankingReportComponent.reportSubType;
                formattedReportData.chartDisplayType =
                    rankingReportComponent.chartDisplayType;
                break;
            case ReportType.COUNT:
                let countReportComponent = report;
                const keyValueArray: any[] = Array.from(
                    report.selectedFieldsByDataset.entries()
                );
                const keyValuePairs = {};
                keyValueArray.forEach(([key, value]) => {
                    keyValuePairs[key] = value;
                });
                formattedReportData = {
                    id: RandomUtils.generateUUID(),
                    name: report.reportName || "",
                    selectedFieldsByDataset: keyValuePairs,
                    type: ReportType.COUNT,
                };
                formattedReportData.reportType =
                    countReportComponent.reportType;
                formattedReportData.reportSubType =
                    countReportComponent.reportSubType;
                formattedReportData.chartDisplayType =
                    countReportComponent.chartDisplayType;

                break;
        }

        return formattedReportData;
    }

    downloadReport(reportElementIndex: number, columnNumber: number) {
        this.downloadModel.process(
            true,
            reportElementIndex,
            this.activeDataset,
            this.getSelectedGroupIds(),
            this.getAnalyticDataUsingCloumnNumber(columnNumber)
        );
        // const component = this.analyticData[reportElementIndex];
        // let downloadItems: DownloadReportItem[] = [];
        // let reportHasTwoDimensionPieChart = false;

        // if (component) {
        //     if (
        //         component.displayType === ReportDisplayType.PIE_CHART &&
        //         component.isTwoDimensionReport
        //     ) {
        //         reportHasTwoDimensionPieChart = true;
        //     }

        //     let reportDownloadRequest: DownloadReportItem =
        //         this.getReportDownloadRequest(component);

        //     if (reportDownloadRequest) {
        //         downloadItems.push(reportDownloadRequest);
        //     }
        // }

        // let reportRequest: DownloadReportRequest = {
        //     items: downloadItems,
        // };

        // let downloadRequest: DownloadDatapointRequest = {
        //     reportRequest: reportRequest,
        //     filter: this.getActiveFilter(),
        //     timezone: Constants.DEFAULT_TIMEZONE,
        //     dateFormat: Constants.DEFAULT_DATE_FORMAT,
        //     outputFileType: DownloadFileType.XLSX,
        // };

        // if (reportHasTwoDimensionPieChart) {
        //     const dialogRef = this.dialog.open(DialogComponent, {
        //         data: new DialogModel(
        //             "Confirm Download",
        //             `Your pie chart has multiple criteria, but the spreadsheet download will only display one. We recommend downloading the bar charts instead.`,
        //             "Cancel",
        //             "Download"
        //         ),
        //     });
        //     dialogRef
        //         .afterClosed()
        //         .pipe(take(1))
        //         .subscribe((dialogResult) => {
        //             if (dialogResult) {
        //                 this.downloadReportFile(downloadRequest);
        //             }
        //         });
        // } else {
        //     this.downloadReportFile(downloadRequest);
        // }
    }

    private downloadReportFile(downloadRequest: DownloadDatapointRequest) {
        this.downloadService
            .downloadDatapoints(
                this.dataset.id,
                downloadRequest,
                "Analytics.xlsx"
            )
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        "Analytics.xlsx"
                    );
                },
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }

    getActiveFilter() {
        return {
            datasetID: this.activeDataset.id,
            fields: [],
            groups: this.getSelectedGroupIds(),
            links: [],
        };
    }

    getSelectedGroupIds() {
        const selectedGroupsIds = this.selectedDropdownItems.map((item) => {
            return item.id;
        });
        return selectedGroupsIds || [];
    }

    getReportDownloadRequest(report): DownloadReportItem {
        if (report.reportSubType === ReportDisplayType.TABLE) {
            return report.tableDownloadRequest;
        } else if (
            report.reportSubType === ReportDisplayType.BAR_CHART ||
            report.reportSubType === ReportDisplayType.PIE_CHART
        ) {
            if (report.chartDownloadRequest) {
                if (report.reportSubType === ReportDisplayType.BAR_CHART) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_BAR_CHART;
                } else if (
                    report.reportSubType === ReportDisplayType.PIE_CHART
                ) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_PIE_CHART;
                }
            }
            return report.chartDownloadRequest;
        }
    }

    deleteWorkspaceItem(counter: number, columnNumber: number) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        const item: WorkspaceItem = selectedColumnData[counter];
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete "${item.reportName}"?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.workspaceItemService
                        .deleteWorkspaceItem(item.id)
                        .subscribe(
                            (success) => {
                                this.rightPanelCall(true);
                            },
                            (error) =>
                                this.notifService.error(error.error.message)
                        );
                }
            });
    }

    rightPanelCall(isShowDeleteNotification: boolean = false) {
        this.subscriptions.add(
            forkJoin(
                this.workspaceItemService.getAllWorkspaceItemsForUser(
                    this.dataset.id,
                    WorkspaceItemType.DASHBOARD
                ),
                this.workspaceItemService.getAllWorkspaceItemsForUser(
                    this.dataset.id,
                    WorkspaceItemType.FORMULA
                )
            ).subscribe(([analytics, formulas]) => {
                this._analytics = analytics;
                this._formulas = formulas;
                this.getAnalyticsFromServer();
                if (isShowDeleteNotification) {
                    this.notifService.success("Item was deleted successfully");
                }
            })
        );
    }

    indicatorsDrop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.sidebarDataValues,
            event.previousIndex,
            event.currentIndex
        );
        const idToSequenceMap = new Map<number, number>();

        this.sidebarDataValues.forEach((item, i) => {
            idToSequenceMap.set(item.id, i + 1);
        });

        this.indicatorsData.fields.forEach((item) => {
            const sequence = idToSequenceMap.get(item.id);
            if (sequence) {
                item.sequence = sequence;
            }
        });
        const doSequencing = false;
        this.saveIndicatorsConfiguration(
            this.indicatorsData.fields,
            doSequencing
        );
    }

    reportDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            const previousItem =
                event.previousContainer.data[event.previousIndex];
            const currentItem = event.container.data[event.currentIndex];

            event.container.data[event.currentIndex] = previousItem;
            event.previousContainer.data[event.previousIndex] = currentItem;
        }
        if (this.hasAccess) {
            this.saveSequence();
        }
    }

    openAnalyticsPanel() {
        this.analyticsPanel = undefined;
        this.showAnalyticsPanel();
        const { componentRef } = this.analyticsPanel.instance;
        if (componentRef) {
            componentRef.instance.removeAllReports();
            componentRef.instance.initialize();
        }
    }

    analyticsPanel: ComponentRef<SidePanelComponent>;

    showAnalyticsPanel() {
        if (this.analyticsPanel) {
            this.analyticsPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.analyticsPanel =
                this.sidePanelService.open<AnalitycsComponentType>(
                    SidePanels.ANALYTICS,
                    {
                        width: 459,
                        id: "analytics",
                        panelTitle: "Analytics",
                        resizeable: true,
                        panelIcon: "icon-analitycs",
                    },
                    {
                        dataset: this.dataset,
                        nriFields: this.tessadataFields.nriFields,
                        isDashboardCall: true,
                        climateOverlays: this.prepareClimateOverlays(),
                    }
                );
            this.workspaceItemService.isDashboardCallClose$.subscribe(
                (response) => {
                    if (response) {
                        this.rightPanelCall();
                        this.prepareAnalytics();
                        this.analyticsPanel.instance.closePanel();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.analyticsPanel) {
            this.analyticsPanel.instance.closePanel();
            this.analyticsPanel = null;
        }
    }

    downloadPanel() {
        //         const pages = document.querySelector('.dashboard-main-content') as HTMLElement;
        //         let pdfOptions = {orientation: 'p', unit: 'px', format: [595, 842],  pagesplit: true};
        //         var element = document.getElementById('pdfTable');
        // var opt = {
        //   margin:       0.1,
        //   filename:     'myfile.pdf',
        //   image:        { type: 'jpeg', quality: 0.20 },
        //   html2canvas:  { scale: 2 },
        //   jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
        // };
        //  console.log(element);
        // // New Promise-based usage:
        // html2pdf().from(element).set(opt).save();
        //     let doc = new jsPDF(pdfOptions);
        // doc.addHTML(this.pdfTable.nativeElement, function() {
        //    doc.save("obrz.pdf");
        // });
        //     const doc = new jsPDF();
        // const pdfTable = this.pdfTable.nativeElement;
        // var html = htmlToPdfmake(pdfTable.innerHTML);
        // const documentDefinition = { content: html };
        // pdfMake.createPdf(documentDefinition).open();
        // const doc = new jsPDF(pdfOptions);
        //     var printDoc = new jsPDF();
        // printDoc.fromHTML(pages);
        // printDoc.autoPrint();
        // printDoc.output("dataurlnewwindow");
        // globalThis.html2canvas = html2canvas;
        // console.log(pages);
        //   doc.html(pages, {
        //     callback: (doc: jsPDF) => {
        //       doc.deletePage(doc.getNumberOfPages());
        //       doc.save('pdf-export');
        //     }
        //   });
        // const doc = new jsPDF();
        // const specialElementHandlers = {
        //   '#editor': function (element, renderer) {
        //     return true;
        //   }
        // };
        // // const content = this.htmlContent.nativeElement;
        // doc.fromHTML(pages, 15, 15, {
        //   'width': 190,
        //   'elementHandlers': specialElementHandlers
        // });
        // doc.save('document.pdf');
    }

    downloadDashboardPDF() {
        let downloadItems = [];
        let reportHasTwoDimensionPieChart = false;
        const sequencedAnalyticData = this.combineAnalyticsData();

        sequencedAnalyticData.forEach((component) => {
            if (component) {
                if (
                    component.reportSubType === ReportDisplayType.PIE_CHART &&
                    component.isTwoDimensionReport
                ) {
                    reportHasTwoDimensionPieChart = true;
                }

                let reportDownloadRequest =
                    this.getReportDownloadRequestForPDF(component);

                if (reportDownloadRequest) {
                    downloadItems.push(reportDownloadRequest);
                }
            }
        });

        let reportRequest: DownloadReportRequest = {
            items: downloadItems,
        };

        let downloadRequest: DownloadDatapointRequest = {
            reportRequest: reportRequest,
            filter: this.getActiveFilter(),
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: DownloadFileType.PDF,
        };

        this.downloadDashboardPDFFile(downloadRequest);
    }

    private downloadDashboardPDFFile(
        downloadRequest: DownloadDatapointRequest
    ) {
        this.downloadService
            .downloadDashboardPDFFile(
                this.dataset.id,
                downloadRequest,
                "Analytics.pdf"
            )
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        "Analytics.pdf"
                    );
                },
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }

    getReportDownloadRequestForPDF(report): DownloadReportItem {
        if (report.reportSubType === ReportDisplayType.TABLE) {
            return {
                id: report.id,
                reportViewType: ReportViewType.TABLE,
                chartViewType: ChartViewType.NONE,
                type: report.type,
                ...report.tableDownloadRequest,
            };
        } else if (
            report.reportSubType === ReportDisplayType.BAR_CHART ||
            report.reportSubType === ReportDisplayType.PIE_CHART
        ) {
            // delete report.chartDownloadRequest.type;
            if (report.chartDownloadRequest) {
                if (report.reportSubType === ReportDisplayType.BAR_CHART) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_BAR_CHART;
                } else if (
                    report.reportSubType === ReportDisplayType.PIE_CHART
                ) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_PIE_CHART;
                }
            }
            if (!report.chartDisplayType) {
                report.chartDownloadRequest.chartViewType = ChartViewType.NONE;
            } else {
                if (report.chartDisplayType === ChartDisplayType.AGGREGATE) {
                    report.chartDownloadRequest.chartViewType =
                        ChartViewType.AGGREGATE;
                    report.chartDownloadRequest.columns.value =
                        ChartDisplayLabel.AGGREGATE;
                } else if (report.chartDisplayType === ChartDisplayType.COUNT) {
                    report.chartDownloadRequest.chartViewType =
                        ChartViewType.COUNT;
                    report.chartDownloadRequest.columns.value =
                        ChartDisplayLabel.COUNT;
                } else if (
                    report.chartDisplayType === ChartDisplayType.AVERAGE
                ) {
                    report.chartDownloadRequest.chartViewType =
                        ChartViewType.AVERAGE;
                    report.chartDownloadRequest.columns.value =
                        ChartDisplayLabel.AVERAGE;
                }
            }

            return {
                id: report.id,
                reportViewType:
                    report.reportSubType === ReportDisplayType.BAR_CHART
                        ? ReportViewType.BAR_CHART
                        : ReportViewType.PIE_CHART,
                type: report.type,
                ...report.chartDownloadRequest,
            };
        }
    }

    validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX = 320;
        this.MIN_DIMENSIONS_PX = MIN_DIMENSIONS_PX;
        if (
            event.rectangle.width &&
            event.rectangle.height &&
            (event.rectangle.width < MIN_DIMENSIONS_PX ||
                event.rectangle.height < MIN_DIMENSIONS_PX)
        ) {
            return false;
        }
        return true;
    }

    onResizeEnd(
        event: ResizeEvent,
        reportElementIndex: number,
        columnNumber: number
    ): void {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        let newWidth = event.rectangle.width;
        let newHeight = event.rectangle.height;

        if (event.edges.right || event.edges.left) {
            newHeight = this.calculateWidgetDimension(
                "height",
                event.rectangle.width
            );
        } else if (event.edges.bottom || event.edges.top) {
            newWidth = this.calculateWidgetDimension(
                "width",
                event.rectangle.height
            );
        }
        newWidth =
            newWidth > this.MIN_DIMENSIONS_PX
                ? newWidth
                : this.MIN_DIMENSIONS_PX;
        newHeight =
            newHeight > this.MIN_DIMENSIONS_PX
                ? newHeight
                : this.MIN_DIMENSIONS_PX;

        selectedColumnData.forEach((element) => {
            if (!element && !element.style) {
                element.style = {
                    position: "relative",
                    width: `500px`,
                    height: this.calculateWidgetDimension("height", 500),
                };
            }
        });

        selectedColumnData[reportElementIndex].style = {
            position: "relative",
            width: `${newWidth}px`,
            height: `${newHeight}px`,
        };
        selectedColumnData[reportElementIndex].isResizing = true;

        this.repaintDOM(reportElementIndex);
    }

    calculateWidgetDimension(
        dimension: "width" | "height",
        value: number
    ): number {
        const aspectRatio = 5 / 7;
        if (dimension === "width") {
            return value / aspectRatio;
        } else if (dimension === "height") {
            return value * aspectRatio;
        }
    }

    repaintDOM(reportElementIndex: number) {
        this.analyticsColumnOne.forEach((analytic) => {
            if (analytic) {
                let prevDisplayType = analytic?.reportSubType;
                analytic.reportSubType = ReportDisplayType.TABLE;
                setTimeout(() => {
                    analytic.reportSubType = prevDisplayType;
                    if (this.analyticsColumnOne[reportElementIndex]) {
                        this.analyticsColumnOne[reportElementIndex].isResizing =
                            false;
                    }
                }, 1000);
            }
        });

        this.analyticsColumnTwo.forEach((analytic) => {
            if (analytic) {
                let prevDisplayType = analytic?.reportSubType;
                analytic.reportSubType = ReportDisplayType.TABLE;
                setTimeout(() => {
                    analytic.reportSubType = prevDisplayType;
                    if (this.analyticsColumnTwo[reportElementIndex]) {
                        this.analyticsColumnTwo[reportElementIndex].isResizing =
                            false;
                    }
                }, 1000);
            }
        });

        this.analyticsColumnThree.forEach((analytic) => {
            if (analytic) {
                let prevDisplayType = analytic?.reportSubType;
                analytic.reportSubType = ReportDisplayType.TABLE;
                setTimeout(() => {
                    analytic.reportSubType = prevDisplayType;
                    if (this.analyticsColumnThree[reportElementIndex]) {
                        this.analyticsColumnThree[
                            reportElementIndex
                        ].isResizing = false;
                    }
                    if (this.analyticData[reportElementIndex]) {
                        this.analyticData[reportElementIndex].isResizing =
                            false;
                    }
                }, 1000);
            }
        });
    }

    swapDynamicStyle(previousIndex: number, currentIndex: number): void {
        // // Swap the values using a temporary variable
        // const temp = this.reportElemetDynamicStyle[previousIndex];
        // this.reportElemetDynamicStyle[previousIndex] =
        //     this.reportElemetDynamicStyle[currentIndex];
        // this.reportElemetDynamicStyle[currentIndex] = temp;
    }

    saveState(i: number, columnNumber: number) {
        if (this.hasAccess) {
            let selectedColumnData =
                this.getAnalyticDataUsingCloumnNumber(columnNumber);

            this._analytics.forEach((element) => {
                if (element.id == selectedColumnData[i].id) {
                    (element.reportType = selectedColumnData[i].reportType),
                        (element.reportSubType =
                            selectedColumnData[i].reportSubType),
                        (element.chartDisplayType =
                            selectedColumnData[i].chartDisplayType);
                }
            });
            let request: UpdateWorkspaceItemStateRequest = {
                workspaceItemID: selectedColumnData[i].id,
                reportType: selectedColumnData[i].reportType,
                accountId: this.datapointsPageStateService.activeAccount.id,
                reportSubType: selectedColumnData[i].reportSubType,
                chartDisplayType: selectedColumnData[i].chartDisplayType,
            };
            this.workspaceItemService
                .updateWorkspaceItemState(request)
                .subscribe((response) => {});
        }
    }

    saveSequence() {
        let request: UpdateWorkspaceItemSequenceRequest = {
            accountId: this.dataset.accountID,
            data: [],
        };
        const sequencedAnalyticData = this.combineAnalyticsData();
        sequencedAnalyticData.forEach((element, index) => {
            if (element) {
                request.data.push({
                    workspaceItemId: element.id,
                    sequence: index + 1,
                });
                this.workspaceItemService
                    .updateWorkspaceItemSequence(request)
                    .subscribe((response) => {});
            }
        });
    }

    updateWorkspaceItemName($event, counter: number, columnNumber: number) {
        if (this.hasAccess) {
            let selectedColumnData =
                this.getAnalyticDataUsingCloumnNumber(columnNumber);
            let report = this._analytics.find(
                (element) => element.id === selectedColumnData[counter].id
            );
            const data = JSON.parse(report.data);
            if (!isUndefined(data[0])) {
                data[0].sort = selectedColumnData[counter].sort;
            }
            if (report !== undefined) {
                const reportName = selectedColumnData[counter].reportName;
                let request: UpdateWorkspaceItemRequest = {
                    id: report.id,
                    data: JSON.stringify(data),
                    access: report.access,
                    name: reportName,
                    reportType: selectedColumnData[counter].reportType,
                    reportSubType: selectedColumnData[counter].reportSubType,
                    chartDisplayType:
                        selectedColumnData[counter].chartDisplayType,
                };
                this.workspaceItemService
                    .updateWorkspaceItem(request)
                    .subscribe(
                        (success) => {
                            this.getAnalyticDataUsingCloumnNumber(columnNumber)[
                                counter
                            ].tableDownloadRequest.title = request.name;
                            if (isUndefined($event.active)) {
                                this.notifService.success(
                                    "View was successfully updated"
                                );
                            }
                        },
                        (error) => this.notifService.error(error.error.message)
                    );
            } else {
                console.log("report data not found.");
            }
        }
    }

    divideAnalyticsData() {
        const numColumns = 3;

        this.analyticData.forEach((analytic, i) => {
            const columnIndex = i % numColumns;
            switch (columnIndex) {
                case 0:
                    this.analyticsColumnOne.push(analytic);
                    break;
                case 1:
                    this.analyticsColumnTwo.push(analytic);
                    break;
                case 2:
                    this.analyticsColumnThree.push(analytic);
                    break;
            }
        });
        this.isAnalyticsPrepared = true;
    }

    getAnalyticDataUsingCloumnNumber(columnNumber: number) {
        switch (columnNumber) {
            case 1:
                return this.analyticsColumnOne;
            case 2:
                return this.analyticsColumnTwo;
            case 3:
                return this.analyticsColumnThree;
            default:
                return this.analyticData;
        }
    }

    combineAnalyticsData() {
        const sequencedAnalyticData = [];
        const maxLength = Math.max(
            this.analyticsColumnOne.length,
            this.analyticsColumnTwo.length,
            this.analyticsColumnThree.length
        );

        for (let i = 0; i < maxLength; i++) {
            if (i < this.analyticsColumnOne.length) {
                sequencedAnalyticData.push(this.analyticsColumnOne[i]);
            }
            if (i < this.analyticsColumnTwo.length) {
                sequencedAnalyticData.push(this.analyticsColumnTwo[i]);
            }
            if (i < this.analyticsColumnThree.length) {
                sequencedAnalyticData.push(this.analyticsColumnThree[i]);
            }
        }

        return sequencedAnalyticData;
    }

    openIndicatorsEditDialog() {
        const dialogRef = this.dialog.open(IndicatorsEditDialog, {
            height: "400px",
            width: "800px",
            data: {
                indicators: this.indicatorsData?.fields.sort(
                    (a, b) => a.id - b.id
                ),
            },
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResponse: IndicatorField[]) => {
                if (dialogResponse) {
                    this.loadReportData(dialogResponse);
                    this.saveIndicatorsConfiguration(dialogResponse);
                }
            });
    }

    saveIndicatorsConfiguration(
        dialogResponse: IndicatorField[],
        doSequencing = true
    ) {
        if (doSequencing) {
            let maxExistingSequence = 0;
            this.indicatorsData.fields = dialogResponse;

            this.indicatorsData.fields.forEach((indicator) => {
                if (!indicator.isIndicators) {
                    indicator.sequence = 0;
                }

                if (indicator.sequence > maxExistingSequence) {
                    maxExistingSequence = indicator.sequence;
                }
            });

            this.indicatorsData.fields.sort((a, b) => a.sequence - b.sequence);

            this.indicatorsData.fields.forEach((indicator) => {
                if (indicator.isIndicators && !indicator.sequence) {
                    indicator.sequence = ++maxExistingSequence;
                }
            });
        }
        this.dashboardService
            .updateDashboardIndicators(this.indicatorsData)
            .subscribe((success) => {
                this.notifService.success("Indicators updated successfully");
            });
    }

    undoResize() {
        this.analyticsColumnOne.forEach((analytic) => {
            if (analytic) {
                analytic.style = null;
            }
        });
        this.analyticsColumnTwo.forEach((analytic) => {
            if (analytic) {
                analytic.style = null;
            }
        });
        this.analyticsColumnThree.forEach((analytic) => {
            if (analytic) {
                analytic.style = null;
            }
        });

        this.repaintDOM(
            this.analyticsColumnThree[this.analyticsColumnOne.length - 1]
        );
    }

    isTensorFlight(key: string) {
        return key.toUpperCase() === "TENSORFLIGHT";
    }

    dynamicFilterMenuEmitter($event, counter: number, columnNumber: number) {
        this.onCountsFieldsMenuClick(
            $event.event,
            $event.dataset,
            $event.field,
            counter,
            columnNumber
        );
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    onCountsFieldsMenuClick(
        fieldSelected: boolean,
        dataset: Dataset,
        field: DatasetField,
        counter: number,
        columnNumber: number
    ) {
        let selectedColumnData =
            this.getAnalyticDataUsingCloumnNumber(columnNumber);
        let countReport = selectedColumnData[counter];

        let datasetFields = countReport.selectedFieldsByDataset.get(dataset.id);
        if (!datasetFields) {
            (datasetFields = []),
                countReport.selectedFieldsByDataset.set(
                    dataset.id,
                    datasetFields
                );
        }

        if (fieldSelected) {
            datasetFields.push(field);
            countReport.selectedFieldsByDatasetCount++;
        } else {
            countReport.selectedFieldsByDataset.set(
                dataset.id,
                datasetFields.filter((f) => f.id !== field.id)
            );
            countReport.selectedFieldsByDatasetCount--;
        }
    }

    getDisplayName(node) {
        return new Datapoints().getDisplayName(node);
    }

    getDisabledTreeNode(node, data) {
        if (!isUndefined(node.params)) {
            const field = node.params.field;
            if (node.params.callType == "dataset") {
                return (
                    (data.selectedAggregateField &&
                        data.selectedAggregateField?.id !== field.id) ||
                    data.selectedFormula !== undefined
                );
            } else if (node.params.callType == "formula") {
                return (
                    (data.selectedFormula &&
                        data.selectedFormula.id !== field.id) ||
                    data.selectedAggregateField !== undefined
                );
            } else {
                return false;
            }
        }
    }
    sortFilterAccountOverlays(res) {
        const information = res.filter(
            (element) => element.group.name.toLowerCase() == "information"
        );
        res.forEach((element) => {
            if (element.children.length) {
                element.children.sort((item1, item2) => {
                    if (
                        item1 &&
                        item1.group.name &&
                        item2 &&
                        item2.group.name
                    ) {
                        return item1.group.name
                            .trim()
                            .toLowerCase()
                            .localeCompare(
                                item2.group.name.trim().toLowerCase()
                            );
                    }
                });
            }
        });
        res.sort((a, b) => (a.group.name > b.group.name ? -1 : 1));
        let index = res.findIndex(
            (group) => group.group.name.toLowerCase() === "information"
        );
        if (index !== -1) {
            res.splice(index, 1);
        }
        let filterAccountOverlays = res; //HidePrivateOverlays-ifexists-foranotsuperadminuseruntilBEcodeforthiswillbeimplemented
        if (!this.userStateService.isSuperadmin) {
            let index = filterAccountOverlays.findIndex(
                (group) => group.group.name === "Private Overlays"
            );
            if (index !== -1) {
                filterAccountOverlays.splice(index, 1);
            }
        }
        return !isUndefined(information)
            ? [...filterAccountOverlays, ...information]
            : filterAccountOverlays;
    }
    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
                return;
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            }
        });
        return overlays;
    }
    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });
            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }
    prepareClimateOverlays() {
        return ObjectUtils.clone(this.filterAccountOverlays).filter(
            (params) => !["Risk Maps"].includes(params.group.name)
        );
    }
}
