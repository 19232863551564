export enum AerisType {
    AIR_QUALITY = 'airquality',
    FORECASTS = 'forecasts',
    DROUGHTS = 'droughts/monitor',
    FIRES_OUTLOOk = 'fires/outlook',
    EARTHQUAKES = 'earthquakes',
    FIRES = 'fires',
    PLACES = 'places',
    RIVERS = 'rivers',
    RIVERS_GAUGES = 'rivers/gauges',
    STORMCELLS = 'stormcells',
    THREATS = 'threats',

    ALERTS_SUMMARY = 'alerts/summary',
    LIGHTNING_SUMMARY = 'lightning/summary',
    CLIMATE_NORMALS = 'normals',
    OBSERVATIONS = 'observations',
    STORM_REPORTS = 'stormreports',
    TIDES = 'tides',

    LIGHTNING = 'lightning',
    ALERTS = 'alerts',
    TROPICALCYCLONES = 'tropicalcyclones',
    CONDITIONS = 'conditions',
    CONVECTIVE = 'convective/outlook',
    MARITIME = "maritime"
}
