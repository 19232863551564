<div class="public-content">
    <div class="public-text">
        <img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
    </div>
    <div class="public-form">
        <div *ngIf="!isTokenValid" style="color: white;">
            <div>You have already reset the password. This link is expired.</div>
            <div class="go-to-login-btn" [routerLink]="['/public/login']">GO TO LOGIN</div>
        </div>
        <form [formGroup]="forgotPasswordForm" *ngIf="isTokenValid" (ngSubmit)="onSubmit()">
            <div class="link-button" [routerLink]="['/public/login']">Login instead?</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Create your new password</mat-label>
                <input matInput autocomplete="off" required formControlName="password" type="password">
                <mat-error *ngIf="forgotPasswordForm.controls.password.hasError('required')">
                    New Password is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Enter the password to confirm</mat-label>
                <input matInput autocomplete="off" required formControlName="confirmPassword" type="password">
                <mat-error *ngIf="forgotPasswordForm.controls.password.hasError('required')">
                    confirm password is required.
                </mat-error>
            </mat-form-field>
            <mat-error *ngIf="forgotPasswordForm.hasError('passwordsNotMatching')">
                Passwords do not match.
              </mat-error>
            <!-- <div class="forgot-description" [routerLink]="['/public/login']">You will receive an email to confirm the reset of the password</div> -->
            <button mat-stroked-button color="primary" >Reset</button>
        </form>
    </div>
</div>
