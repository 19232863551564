<map-drafts-upload #uploadComponent *ngIf="dataset" [dataset]="dataset" [IsInDraft]="true"
    (uploadCompleted)="onUploadComplete()"></map-drafts-upload>
<map-approve-draft #approveDraftComponent [dialogData]="dialogData"
    (approveCompleted)="onApproveComplete()"></map-approve-draft>
<map-header>
    <div class="header-option">
        <button [matMenuTriggerFor]="actionsMenu">
            Actions
            <mat-icon fontIcon="fa-angle-down" fontSet="far"></mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu" class="custom-menu nested-menu header-menu" backdropClass="mat-menu-header"
            [class.tree-invisible]="!showSwitchMenu">
            <hr class="menu-line-separator" />
            <div mat-menu-item (click)="openDownload()">
                <span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="fas"></mat-icon></span>
                Download
            </div>
            <hr *ngIf="!dataset?.isGeoserver" class="menu-line-separator" />
            <div mat-menu-item *ngIf="!dataset?.isGeoserver" (click)="openImport()">
                <span><mat-icon fontIcon="fa-arrow-to-top" fontSet="fas"></mat-icon></span>
                Upload
            </div>
            <hr *ngIf="dataset?.isGeoserver" class="menu-line-separator" />
            <div mat-menu-item *ngIf="dataset?.isGeoserver" (click)="fetchGlobalGeoserverOverlays()">
                <span><mat-icon fontIcon="fa-sync" fontSet="fas"></mat-icon></span>
                Fetch
            </div>
            <hr class="menu-line-separator" />
            <div mat-menu-item (click)="createDraft()">
                <span><mat-icon fontIcon="fa-plus" fontSet="fas"></mat-icon></span>
                Create
            </div>
            <hr class="menu-line-separator" />
            <div mat-menu-item (click)="openApprove()">
                <span><mat-icon fontIcon="fa-check" fontSet="fas"></mat-icon></span>
                Approve
            </div>
            <hr *ngIf="dataset && (dataset.geometryType === DatasetGeometryType.POINT)" class="menu-line-separator" />
            <div mat-menu-item *ngIf="dataset && (dataset.geometryType === DatasetGeometryType.POINT)"
                (click)="linkDrafts()">
                <span><mat-icon fontIcon="fa-link" fontSet="fas"></mat-icon></span>
                Link
            </div>
            <hr *ngIf="dataset && dataset.geometryType === DatasetGeometryType.COMPLEX && !dataset.isGeoserver" class="menu-line-separator" />
            <div mat-menu-item
                *ngIf="dataset && dataset.geometryType === DatasetGeometryType.COMPLEX && !dataset.isGeoserver"
                (click)="tileDrafts()">
                <span><mat-icon fontIcon="fa-square" fontSet="fas"></mat-icon></span>
                Tile & Link
            </div>
            <hr *ngIf="dataset && dataset.geometryType === DatasetGeometryType.COMPLEX && dataset.isGeoserver" class="menu-line-separator">
            <div mat-menu-item
                *ngIf="dataset && dataset.geometryType === DatasetGeometryType.COMPLEX && dataset.isGeoserver"
                (click)="tileDrafts()">
                <span><mat-icon fontIcon="fa-square" fontSet="fas"></mat-icon></span>
                Link
            </div>
            <hr class="menu-line-separator"/>
            <div mat-menu-item (click)="deleteSelectedDrafts()">
                <span><mat-icon fontIcon="fa-trash-alt" fontSet="fas"></mat-icon></span>
                Delete
            </div>
            <hr class="menu-line-separator"/>

            <div mat-menu-item (click)="toggleSwitchMenu($event)" style="padding-left: 6px !important;">
                <span style="width: 100%;">
                    <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
                        <div style="display: flex; align-items: center;">
							<div>
								<i class="fal fa-toggle-on" style="color: #0044f1; width: 20px; height: 20px;position: relative;top: 2px;"></i>
							</div>
							<div style="margin-left: 3px;">
								Switch to D
							</div>
						</div>
                        <div>
                            <mat-icon *ngIf="showSwitchMenu"
                                style="width: 16px; font-size: 16px;">expand_less</mat-icon>
                            <mat-icon *ngIf="!showSwitchMenu"
                                style="width: 16px; font-size: 16px;">expand_more</mat-icon>
                        </div>
                    </div>
                </span>
            </div>

            <ng-container *ngIf="showSwitchMenu" style="margin-top: 4px !important;">
                <ng-container *ngFor="let currentDataset of availableDatasetsInAccount; let i = index">
                    <div [class.list-item-active]="currentDataset?.id === dataset?.id"
                        (click)="switchApplication(currentDataset.id)" mat-menu-item>
                        {{ currentDataset?.name }}
                    </div>
                    <hr class="menu-line-separator" *ngIf="i !== availableDatasetsInAccount.length - 1" />
                </ng-container>
                <hr class="menu-line-separator" *ngIf="datapointsEnabled && availableDatasetsInAccount.length > 0" />
                <ng-container *ngIf="datapointsEnabled">
                    <div (click)="navigateToDatapoints()" mat-menu-item>Datapoints</div>
                </ng-container>
            </ng-container>

        </mat-menu>

    </div>


    <div class="header-option">
        <button [matMenuTriggerFor]="viewMenu">
            View
            <mat-icon fontIcon="fa-angle-down" fontSet="far"></mat-icon>
        </button>
        <mat-menu #viewMenu="matMenu" class="custom-menu nested-menu header-menu">
            <div class="nested-menu header-menu" (click)="toggleViewMenu($event)">
                <div class="nested-menu-item drafts-menu-table" [ngClass]="{ 'active': isProjectionMenuOpen }">
                    <div>
                        <span>
                            <mat-icon fontIcon="fa-table" fontSet="fas" style="margin-right: 4px;color: #0044f1;"></mat-icon>
                        </span>
                        Table Columns
                    </div>
                    <div>
                        <span>
                            <mat-icon *ngIf="isProjectionMenuOpen">expand_less</mat-icon>
                            <mat-icon *ngIf="!isProjectionMenuOpen">expand_more</mat-icon>
                        </span>
                    </div>
                </div>

                <ul *ngIf="isProjectionMenuOpen">
                    <li>
                        <div class="nested-menu-search">
                            <input (click)="$event.stopPropagation()" [(ngModel)]="projectionSearchString"
                                placeholder="Search">
                        </div>
                    </li>
                    <li *ngIf="projectionSelectionForm">
                        <form [formGroup]="projectionSelectionForm">
                            <ng-container
                                *ngFor="let field of dataset.fields | callback: filteredProjectionDatasetFieldsFilter">
                                <div (click)="$event.stopPropagation()" class="nested-menu-item">
                                    <mat-checkbox [formControlName]="field.id">
                                        <span>{{ field.name }}</span>
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </form>
                    </li>
                </ul>
            </div>
        </mat-menu>
        <mat-menu #projectionMenu="matMenu" class="nested-menu nested-menu-leaf">
            <div (click)="$event.stopPropagation()" class="nested-menu-search">
                <input [(ngModel)]="projectionSearchString" placeholder="Search">
            </div>
            <form *ngIf="projectionSelectionForm" [formGroup]="projectionSelectionForm">
                <ng-container *ngFor="let field of dataset.fields | callback: filteredProjectionDatasetFieldsFilter">
                    <div (click)="$event.stopPropagation()" mat-menu-item>
                        <mat-checkbox [formControlName]="field.id">
                            <span>{{ field.name }}</span>
                        </mat-checkbox>
                    </div>
                </ng-container>
            </form>
        </mat-menu>
    </div>

    <div class="header-option">
        <button [matMenuTriggerFor]="filterMenu">Filter</button>
        <mat-menu #filterMenu="matMenu" class="custom-menu nested-menu nested-menu-leaf">
            <div (click)="$event.stopPropagation()" class="nested-menu-search">
                <input [(ngModel)]="filterFieldSearchString" placeholder="Search">
            </div>
            <form *ngIf="filterSelectionForm" [formGroup]="filterSelectionForm">
                <ng-container *ngFor="let field of dataset.fields | callback: filterFieldSearchFilter">
                    <div (click)="$event.stopPropagation()" mat-menu-item>
                        <mat-checkbox [formControlName]="field.id" (change)="onFilterSelect(field, $event)">
                            <span>{{ field.name }}</span>
                        </mat-checkbox>
                    </div>
                </ng-container>
            </form>
        </mat-menu>
    </div>
</map-header>

<div class="page-root">
    <div class="page">
        <div class="flex-top-bar">
            <div *ngIf="pendingFilters.length" class="filters-container filters">
                <div class="flex filters">
                    <div class="filter-count">
                        <div>Filters ({{pendingFilters.length}})</div>
                    </div>
                    <div *ngFor="let field of pendingFilters; let i = index; trackBy: trackByIndexFunction"
                        class="filter-item">
                        <div [matTooltip]="field.displayedTitle">
                            <div [matMenuTriggerFor]="filterMenu"
                            class="filter-item-content" [tabindex]="0">
                            <div class="filter-title">{{field.datasetField.name}}&nbsp;({{field.dataset.name}})</div>
                            <div class="filter-value">{{field.displayedSearchValue || '-'}}</div>

                            <div class="filter-close" (click)="$event.stopPropagation(); removeFilter(field)">
                                <mat-icon fontIcon="fa-times" fontSet="far"></mat-icon>
                            </div>
                        </div>
                        </div>

                        <mat-menu xPosition="before" yPosition="below" #filterMenu="matMenu"
                            class="nested-menu nested-menu-leaf">
                            <div (click)="$event.stopPropagation()" [ngSwitch]="field.datasetField.baseType">
                                <div *ngSwitchCase="DatasetFieldType.DATE_TIME">
                                    <div class="dateMenuContent flex column">
                                        <mat-form-field class="date-form-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-label>Choose a starting date</mat-label>
                                            <input autocomplete="off" [(ngModel)]="field.minDateValue"
                                                [matDatepicker]="startAt" [max]="field.statistics.maxDateValue"
                                                [min]="field.statistics.minDateValue" matInput
                                                matTooltip="Statistical Minimum Date: {{!field.statistics.minDateValue ? '-' : field.statistics.minDateValue | date }}"
                                                matTooltipPosition="right">
                                            <mat-datepicker-toggle [for]="startAt" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #startAt
                                                [startAt]="field.statistics.minValue"></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="date-form-field" appearance="outline"
                                            floatLabel="always">
                                            <mat-label>Choose an ending date</mat-label>
                                            <input autocomplete="off" [(ngModel)]="field.maxDateValue"
                                                [matDatepicker]="endAt" [max]="field.statistics.maxDateValue"
                                                [min]="field.statistics.minDateValue" matInput
                                                matTooltip="Statistical Maximum Date: {{!field.statistics.maxDateValue ? '-' : field.statistics.maxDateValue | date }}"
                                                matTooltipPosition="right">
                                            <mat-datepicker-toggle [for]="endAt" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #endAt></mat-datepicker>
                                        </mat-form-field>
                                        <button (click)="applyFilters(); filterMenu.closed.emit()" mat-flat-button
                                            color="accent" class="apply-filter-button date-apply-button small">Apply
                                        </button>
                                    </div>
                                </div>

                                <div class="number-field-content flex column" (click)="$event.stopPropagation()"
                                    *ngSwitchCase="DatasetFieldType.NUMBER">
                                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                                        <mat-label>Min</mat-label>
                                        <input [(ngModel)]="field.minNumberValue" [max]="field.statistics.maxValue"
                                            [min]="field.statistics.minValue" matInput placeholder="Min Value"
                                            type="number" autocomplete="off"
                                            matTooltip="Statistical Minimum: {{field.statistics.minValue || '-' | commaFormat}}"
                                            matTooltipPosition="right">
                                    </mat-form-field>
                                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                                        <mat-label>Max</mat-label>
                                        <input [(ngModel)]="field.maxNumberValue" [max]="field.statistics.maxValue"
                                            [min]="field.statistics.minValue" matInput placeholder="Max Value"
                                            type="number" autocomplete="off"
                                            matTooltip="Statistical Maximum: {{field.statistics.maxValue || '-' | commaFormat}}"
                                            matTooltipPosition="right">
                                    </mat-form-field>
                                    <button class="apply-filter-button small"
                                        (click)="applyFilters(); filterMenu.closed.emit()" mat-flat-button
                                        color="accent">Apply
                                    </button>
                                </div>

                                <div style="max-height:600px; overflow:auto; display:block;"
                                    (click)="$event.stopPropagation()" *ngSwitchCase="DatasetFieldType.TEXT"
                                    class="ng-switch-short-text-field"
                                    [class.highCardinality]="field.datasetField.isHighCardinality">

                                    <!-- text field low cardinality-->
                                    <ng-container *ngIf="!field.datasetField.isHighCardinality else newDeb">
                                        <div (click)="$event.stopPropagation()" class="nested-menu-search">
                                            <input matInput [(ngModel)]="filterStatisticValuesString"
                                                placeholder="Search..." autocomplete="off">
                                        </div>
                                        <span *ngIf="field.statistics.values.length === 0 " class="no-values">
                                            No values available...
                                        </span>
                                        <ng-container
                                            *ngFor="let value of field.statistics.values | callback: filterStatisticValuesFilter">
                                            <div (click)="$event.stopPropagation()" mat-menu-item>
                                                <mat-checkbox [(ngModel)]="field.statisticValues[value]"
                                                    (ngModelChange)="applyFilters()">
                                                    <span>{{value}}</span>
                                                </mat-checkbox>

                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <!-- text field low cardinality-->


                                    <!-- text field high cardinality-->
                                    <ng-template #newDeb>
                                        <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                                            <mat-label>Enter value</mat-label>
                                            <input [(ngModel)]="field.searchValue" matInput autocomplete="off"
                                                placeholder="Search contains...">
                                        </mat-form-field>
                                        <button class="apply-filter-button small"
                                            (click)="applyFilters(); filterMenu.closed.emit()" mat-flat-button
                                            color="accent">
                                            Apply
                                        </button>
                                    </ng-template>
                                    <!-- text field high cardinality-->

                                </div>

                                <span (click)="$event.stopPropagation()" *ngSwitchDefault>
                                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                                        <mat-label>Search a text value</mat-label>
                                        <input matInput placeholder="Search contains..." autocomplete="off">
                                    </mat-form-field>
                                    <button>default</button>
                                </span>
                            </div>
                        </mat-menu>

                    </div>
                </div>
                <div class="close-btn-container">
                    <button style="display: block; margin-left: auto;" (click)="removeFilterBar()" mat-icon-button
                        class="remove-filters-btn">
                        <span class="material-icons">
                            clear
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="page-container">
            <div class="table-container">
                <div #content class="table-content">
                    <map-maptycs-table #maptycsTable [items]="drafts" [columns]="columns" [dataset]="dataset"
                        [draftsTable]="true" (rowClickEvent)="updateDraftById($event)" (sort)="onSort($event)">
                    </map-maptycs-table>
                </div>
                <map-paginator [numberOfItemsPerPage]="filterObject.limit"
                    (paginationChangeEvent)="onPaginationChange($event)"
                    [totalNumberOfElements]="paginationInfo?.count">
                </map-paginator>
            </div>
            <app-side-panel [width]="400" #updateDraftPanel panelTitle="Update Draft" id="update-draft-panel">
                <update-drafts (Updated)="onUpdated()" *ngIf="updatingDraftId" [dataset]="dataset"
                    [draftId]="updatingDraftId">
                </update-drafts>
            </app-side-panel>
            <app-side-panel [width]="400" #createDraftPanel panelTitle="Create Draft">
                <create-drafts (draftCreated)="onCreated()" *ngIf="dataset" [dataset]="dataset"
                    [fields]="dataset.fields">
                </create-drafts>
            </app-side-panel>
        </div>
    </div>
</div>