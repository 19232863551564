import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { isUndefined } from "src/app/core/utils/util-master";
import { DatapointField } from "src/app/model/datapoint/datapoint-field";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { ImageOverlay } from "src/app/model/overlay/external/image-overlay";
import { ImageOverlaysGroup } from "src/app/model/overlay/external/image-overlays-group";
import { interactiveOverlaysGroup } from "src/app/core/aeris/AerisConsts";
import * as _ from "agile";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";

export class Datapoints {

    parentOverlay: any;

    prepareClimateData(overlays, isProjectedCall: boolean, checkGeometryTypeComplexOnly: boolean = false): TreeStructure[] {

        if (isProjectedCall) {
            overlays = this.prepareViewFilterAccountOverlays(overlays, isProjectedCall);
        }
        let sortData = [];
        if (checkGeometryTypeComplexOnly) {
            overlays.forEach((group, key) => {
                if ((group.children.length > 0 && group.overlays.length <= 0) ||
                    (group.children.length <= 0 && group.overlays.length > 0 && this.pointOverlayGroupCount(group.overlays, [DatasetGeometryType.COMPLEX])) ||
                    (group.children.length > 0 && group.overlays.length > 0 && this.pointOverlayGroupCount(group.overlays, [DatasetGeometryType.COMPLEX]))) {
                    const children = this.climateRecursiveCall(group, isProjectedCall, checkGeometryTypeComplexOnly);
                    if (children.length) {
                        const data = children.length ? { id: group.group.id, name: group.group.name, children: children, params: { showCheckBox: true } } : { id: group.group.id, name: group.group.name, params: { showCheckBox: true } };
                        sortData.push(data);
                    }
                }
            });
        } else {
            overlays.forEach((group, key) => {
                if ((group.children.length > 0 && group.overlays.length <= 0) ||
                    (group.children.length <= 0 && group.overlays.length > 0 && this.pointOverlayGroupCount(group.overlays, [DatasetGeometryType.POINT, DatasetGeometryType.COMPLEX])) ||
                    (group.children.length > 0 && group.overlays.length > 0 && this.pointOverlayGroupCount(group.overlays, [DatasetGeometryType.POINT, DatasetGeometryType.COMPLEX]))) {
                    const children = this.climateRecursiveCall(group, isProjectedCall);
                    if (children.length) {
                        const data = children.length ? { id: group.group.id, name: group.group.name, children: children, params: { showCheckBox: true } } : { id: group.group.id, name: group.group.name, params: { showCheckBox: true } };
                        sortData.push(data);
                    }
                }
            });
        }
        return sortData;
    }

    climateRecursiveCall(group, isProjectedCall: boolean, climateRecursiveCall: boolean = false): TreeStructure[] {
        let climateData = [];
        if (group.children.length > 0 && group.overlays.length <= 0) {
            climateData = this.getClimateChildren(group, isProjectedCall, climateRecursiveCall);
        } else if (group.children.length <= 0 && group.overlays.length > 0) {
            climateData = this.getClimateOverlays(group, isProjectedCall, climateRecursiveCall);
        } else if (group.children.length > 0 && group.overlays.length > 0) {
            climateData = [...this.getClimateChildren(group, isProjectedCall, climateRecursiveCall), ...this.getClimateOverlays(group, isProjectedCall, climateRecursiveCall)];
        }
        return climateData;
    }

    private getClimateChildren(group, isProjectedCall: boolean, climateRecursiveCall: boolean): TreeStructure[] {
        let childrenData = [];
        group.children.forEach(overlay => {
            if (overlay.children.length > 0 || overlay.overlays.length > 0) {
                const children = this.climateRecursiveCall(overlay, isProjectedCall, climateRecursiveCall);
                const data = children.length ? { id: overlay.group.id, name: overlay.group.name, children: this.climateRecursiveCall(overlay, isProjectedCall, climateRecursiveCall), params: { showCheckBox: true } } : { id: overlay.group.id, name: overlay.group.name, params: { showCheckBox: true } };
                childrenData.push(data);
            }
        });
        return childrenData;
    }

    private getClimateOverlays(group, isProjectedCall: boolean, climateRecursiveCall: boolean): TreeStructure[] {
        let overlayData = [];
        group.overlays.forEach(overlay => {
            let geometryTypes = climateRecursiveCall ? [DatasetGeometryType.COMPLEX] : [DatasetGeometryType.POINT, DatasetGeometryType.COMPLEX];
            if (this.pointOverlayCount(overlay, isProjectedCall, geometryTypes)) {
                let fields;
                if (isProjectedCall) {
                    fields = overlay.fields.filter(field => !field.isGenerated && (field.tags && !field.tags.includes('ID')) && field.displayInDropdowns);
                } else {
                    if (climateRecursiveCall) {
                        fields = overlay.fields.filter(field => !field.isHighCardinality && !field.isGenerated && field.baseType === DatasetFieldType.TEXT);
                    } else {
                        fields = overlay.fields.filter(field => !field.isGenerated && (field.tags && !field.tags.includes('ID')) && field.displayInDropdowns);
                    }
                }

                let prepareFields = [];
                fields.forEach((element, key) => {
                    const field = prepareFields.length == 0 ? overlay : null;
                    const name = element.displayName == null || element.displayName == undefined ? element.name : element.displayName;
                    prepareFields.push({ id: element.id, name: name, params: { field: element, overlay: overlay, showCheckBox: true, callType: 'climate', key: key } });
                });
                const name = overlay.displayName == null || overlay.displayName == undefined ? overlay.name : overlay.displayName;
                const data = prepareFields.length ? { id: overlay.id, name: name, selected: false, children: prepareFields, params: { showCheckBox: true } } : { id: overlay.id, name: name, selected: false, children: [{ id: overlay.id, name: name, params: { showCheckBox: false, overlay: overlay } }], params: { showCheckBox: false } };
                overlayData.push(data);
            }
        });
        return overlayData;
    }

    pointOverlayGroupCount(overlays: Dataset[], geometryType: DatasetGeometryType[]) {
        const filteredOverlayGroups = overlays.filter(overlay => geometryType.includes(overlay.geometryType));
        return filteredOverlayGroups.length;
    }

    pointOverlayCount(overlay: Dataset, isProjectedCall: boolean, geometryTypes: DatasetGeometryType[]) {
        let fields;
        if (isProjectedCall) {
            fields = overlay.fields.filter(field => !field.isGenerated && (field.tags && !field.tags.includes('ID')) && field.displayInDropdowns);
        } else {
            fields = overlay.fields.filter(field => !field.isGenerated && (field.tags && !field.tags.includes('ID')) && field.displayInDropdowns);
        }
        return geometryTypes.includes(overlay.geometryType) || fields.length;
    }

    prepareClimateGridData(overlays) {
        let sortData = [];
        overlays.forEach((group, key) => {
            if (!isUndefined(group.children) && group.children.length > 0 || !isUndefined(group.overlays) && group.overlays.length > 0) {
                const children = this.climateGridRecursiveCall(group);
                if (children.length) {
                    sortData.push(children);
                }
            }
        });
        var merged = sortData.reduce(function (prev, next) {
            return prev.concat(next);
        });
        return { headerName: 'Climate', headerTooltip: 'Climate', marryChildren: true, children: merged } //{...parentLayer, ... {children: merged}};
    }

    climateGridRecursiveCall(group) {
        let childrenData = [];
        if (group.children !== undefined && group.children.length > 0) {
            group.children.forEach(overlay => {
                const children = this.climateGridRecursiveCall(overlay);
                const data = children.length ? { headerName: overlay.name, headerTooltip: overlay.name, children: children, marryChildren: true, filter: false, pivot: false, enableRowGroup: false, sortable: false } : { headerName: overlay.name, headerTooltip: overlay.name, field: group.id.toString(), coldId: group.id.toString(), marryChildren: false, hide: true, filter: false, pivot: false, enableRowGroup: false, sortable: false };
                childrenData.push(data);
            });
        }
        return childrenData;
    }

    prepareViewFilterAccountOverlays(overlays, isProjected: boolean = false) {
        let groupIds = [];
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
                return;
            } else if (element.children.length > 0) {
                this.filterAccountOverlaysCallback(element, isProjected);
            }

            if ((!isUndefined(element.overlays) && element.overlays.length > 0)) {
                const overlays = element.overlays.filter(overlay => ['ipcc', "basemap", "sealevel", "variation"].includes(overlay.geoWorkspace));
                element.overlays = overlays;
                if (element.overlays.length > 0) {
                    element.overlays.forEach((sub_element, sub_element_key) => {
                        let filterValue: any;
                        if (isProjected) {
                            filterValue = sub_element.fields.filter(field_element => field_element.displayInDropdowns == true);
                        } else {
                            filterValue = sub_element.fields.filter(field_element => field_element.displayInDropdowns == true);
                        }
                        sub_element.fields = [];
                        if (filterValue.length) {
                            sub_element.fields = filterValue;
                        } else {
                            element.overlays.splice(sub_element_key, 1);
                        }

                    });
                }

            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                groupIds.push(element.group.id);
            }
        });
        overlays = overlays.filter(overlay => !groupIds.includes(overlay.group.id));
        return overlays;
    }

    filterAccountOverlaysCallback(element, isProjected: boolean) {

        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (sub_element.children.length <= 0 && sub_element.overlays.length <= 0) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(params => !groupIds.includes(params.group.id))
                    this.filterAccountOverlaysCallback(sub_element, isProjected);
                    return;
                }

                if (sub_element.overlays.length) {
                    const overlays = sub_element.overlays.filter(overlay => ['ipcc', "basemap", "sealevel", "variation"].includes(overlay.geoWorkspace));
                    sub_element.overlays = overlays;
                    if (sub_element.overlays.length > 0) {
                        sub_element.overlays.forEach((overlay, sub_element_key) => {
                            let filterValue: any;
                            if (isProjected) {
                                filterValue = overlay.fields.filter(field_element => field_element.displayInDropdowns == true);
                            } else {
                                filterValue = overlay.fields.filter(field_element => field_element.displayInDropdowns == true);
                            }
                            overlay.fields = [];
                            if (filterValue.length) {
                                overlay.fields = filterValue;
                            } else {
                                sub_element.overlays.splice(sub_element_key, 1);
                            }
                        });
                    }
                }

                if (sub_element.children.length <= 0 && sub_element.overlays <= 0) {
                    groupIds.push(sub_element.group.id);
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(params => !groupIds.includes(params.group.id))
                this.filterAccountOverlaysCallback(this.parentOverlay, isProjected);
            }
        }

    }

    prepareFormulas(formulas: WorkspaceItem[]): TreeStructure[] {
        let sortData = [];
        formulas.forEach(formula => {
            sortData.push({
                id: formula.id,
                name: formula.name,
                selected: formula.selected,
                params: {
                    field: formula,
                    showCheckBox: true,
                    dataset: formula,
                    callType: "formula",
                },
            })
        });
        return sortData.length > 0 ? [{
            name: "Formula",
            children: sortData
        }] : [];
    }

    prepareDataset(data: any, dataset: Dataset, conditions?: {}): TreeStructure[] {
        let sortData = [];
        data.forEach((element, key) => {
            sortData.push({
                name: element.name,
                children: this.getDatasetDatapointFields(element.fields, dataset, conditions),
            });
        });
        return sortData;
    }

    getDatasetDatapointFields(fields: DatapointField[], dataset: Dataset, conditions?: {}): TreeStructure[] {
        let prepareFields = [];
        fields.forEach((field) => {
            if (!field.isGenerated && !field.tags.includes("ID") && !conditions) {
                prepareFields.push({
                    id: field.id,
                    name: field.name,
                    selected: field.selected,
                    params: {
                        field: field,
                        showCheckBox: true,
                        dataset: dataset,
                        callType: "dataset",
                    },
                });
            } else if(!field.isGenerated && !field.tags.includes("ID") && (conditions && Object.keys(conditions).length > 0)) {
                const matches = Object.keys(conditions).every(key => {
                    if (field.hasOwnProperty(key)) {
                      return field[key] === conditions[key];
                    }
                    return false; // If the key doesn't exist in the field object, consider it a mismatch
                  });
                  if(matches) {
                    prepareFields.push({
                        id: field.id,
                        name: field.name,
                        selected: field.selected,
                        params: {
                            field: field,
                            showCheckBox: true,
                            dataset: dataset,
                            callType: "dataset",
                        },
                    });
                  }
            }
        });
        prepareFields = prepareFields.sort((a, b) => (a.name < b.name ? -1 : 1));
        return prepareFields;
    }

    prepareTesadata(tessadataGroups, dataset: Dataset): TreeStructure[] {
        let sortData = [];
        for (const key in tessadataGroups) {
            if (Object.prototype.hasOwnProperty.call(tessadataGroups, key)) {
                const groups = tessadataGroups[key];
                if (key !== "undefined") {
                    sortData.push({
                        id: key,
                        name: key,
                        children: this.getGroups(groups, dataset, key),
                    });
                }
            }
        }
        sortData = sortData.sort((a, b) => (a.name < b.name ? -1 : 1));
        return sortData;
    }

    getGroups(groups, dataset: Dataset, key): TreeStructure[] {
        let prepareGroups = [];
        groups.forEach((group) => {
            prepareGroups.push({
                id: group.id,
                name: group.name,
                selected: group.selected,
                params: {
                    field: group,
                    showCheckBox: true,
                    dataset: dataset,
                    callType: "tesadataGroup",
                },
            });
        });
        return prepareGroups;
    }

    prepareNRIFields(
        filterAccountDatasets,
        tessadataFieldsByDataset,
        tessadataEnabled,
        parentDataset: Dataset
    ): TreeStructure[] {
        let sortData = [];
        filterAccountDatasets.forEach((dataset) => {
            if (
                tessadataEnabled &&
                dataset.geometryType === DatasetGeometryType.POINT &&
                dataset.application === MaptycsApplication.LOCATIONS &&
                tessadataFieldsByDataset[dataset.id].nriFields.length
            ) {
                sortData.push({
                    name: "NRI USA",
                    children: this.getNRIFields(
                        tessadataFieldsByDataset[dataset.id].nriFields,
                        parentDataset
                    ),
                });
            }
        });
        return sortData;
    }

    getNRIFields(fields: DatapointField[], dataset: Dataset): TreeStructure[] {
        let prepareFields = [];
        fields.forEach((field) => {
            prepareFields.push({
                id: field.id,
                name: field.name,
                params: { field: field, showCheckBox: true },
                children: this.getNRISubFields(field, dataset),
            });
        });
        return prepareFields;
    }

    getNRISubFields(fields: any, dataset: Dataset): TreeStructure[] {
        const prepareFields = [];
        fields.child.forEach((element) => {
            prepareFields.push({
                id: element.child.id,
                name: element.name,
                selected: element.child.selected,
                params: {
                    field: element.child,
                    showCheckBox: true,
                    dataset: dataset,
                    callType: "nriUSA",
                },
            });
        });
        return prepareFields;
    }

    filterAndDelete(tree: TreeStructure[], params: {isTextTypeCheck?: boolean, isNumberTypeCheck?: boolean, isBothTypeCheck?: boolean, geometryTypes?: DatasetGeometryType[]}): TreeStructure[] {
        return tree.reduce((acc: TreeStructure[], node: TreeStructure) => {
            if (node.children) {
                // Recursively filter and delete children
                node.children = this.filterAndDelete(node.children, params);
                // If children exist after filtering, keep the node
                if (node.children.length > 0) {
                    acc.push(node);
                }
            } else if (!params.geometryTypes && params.isTextTypeCheck && node.params && node.params['field'] && node.params['field']['scope'] === DatasetFieldScope.INTERNAL && node.params['field']['baseType'] === DatasetFieldType.TEXT && (!node.params['field']['isHighCardinality'] && !node.params['field']['isGenerated'])) {
                // If the node has params and baseType is TEXT, keep the node
                acc.push(node);
            } else if(!params.geometryTypes && params.isTextTypeCheck && node.params && node.params['field'] && [DatasetFieldScope.EXTERNAL].includes(node.params['field']['scope'])  && node.params['field']['baseType'] === DatasetFieldType.TEXT) {
                acc.push(node);
            } else if(params.isTextTypeCheck && (params.geometryTypes && node.params && node.params['overlay'] && params.geometryTypes.includes(node.params['overlay']['geometryType']))) {
                acc.push(node);
            } else if (params.isBothTypeCheck && node.params && node.params['field'] && node.params['field']['scope'] === DatasetFieldScope.INTERNAL && [DatasetFieldType.TEXT, DatasetFieldType.NUMBER].includes(node.params['field']['baseType']) && (!node.params['field']['isHighCardinality'] && !node.params['field']['isGenerated'])) {
                // If the node has params and baseType is TEXT, keep the node
                acc.push(node);
            } else if(params.isBothTypeCheck && node.params && node.params['field'] && [DatasetFieldScope.EXTERNAL].includes(node.params['field']['scope'])  && [DatasetFieldType.TEXT, DatasetFieldType.NUMBER].includes(node.params['field']['baseType'])) {
                acc.push(node);
            }
            return acc;
        }, []);
    }

    // Function to recursively iterate through the data and mark selected as true if field.id is found
    markSelected(data: any[], targetId: number | string): void {
        if(data !== undefined){
        data.forEach(item => {
        if (item.params?.field?.id.toString() === targetId.toString()) {
            item.params.selected = true;
            item.selected = true;
        }
        if (item.children) {
            this.markSelected(item.children, targetId);
        }
        });}
  }

    marksSelected(data: any[], targetId: number | string): any {
        data.forEach(item => {
        if (item.params?.field?.id.toString() === targetId.toString()) {
            item.params.selected = true;
            item.selected = true;
        }
        if (item.children) {
            this.markSelected(item.children, targetId);
        }
        });
        return data;
    }

    getDisplayName(node) {
        if (!isUndefined(node.params.field)) {
            const field = node.params.field;
            return field.displayName == null || field.displayName == undefined
                ? field.name
                : field.displayName;
        }
        return node.name;
    }

    // Global Overlay Groups
    formatGlobalOverlayData(data: any): TreeStructure[] {
        const result: TreeStructure[] = [];

        for (const item of data) {
          const groupNode: TreeStructure = {
            name: item.group.name,
            id: item.group.id,
            selected: item.selected || false,
            children: [],
            params: {
                callType: 'globalOverlay'
            }
          };

          if (item.children && item.children.length > 0) {
            groupNode.children = this.formatGlobalOverlayData(item.children);
          }

          if (item.overlays && item.overlays.length > 0) {
            for (const overlay of item.overlays) {
              const overlayNode: TreeStructure = {
                name: overlay.name,
                id: overlay.id,
                selected: overlay.isSelected || false,
                params: {
                    field: overlay,
                    showCheckBox: true,
                    dataset: item,
                    callType: "tesadataGroup",
                },
              };
              groupNode.children.push(overlayNode);
            }
          }

          result.push(groupNode);
        }

        return result;
      }

    // Tessadata
    formatExternalOverlayData(data: any): TreeStructure[] {
        const result: TreeStructure[] = [];

        for (const item of data) {
            const groupNode: TreeStructure = {
                        name: item.group.name,
                        id: item.group.id,
                        selected: item.selected || false,
                        children: [],
                        params: {
                            callType: 'externalOverlay'
                        }
            };

            if (item.children && item.children.length > 0) {
                groupNode.children = this.formatExternalOverlayData(item.children);
            }

            if (item.overlays && item.overlays.length > 0) {
                for (const overlay of item.overlays) {
                    const overlayNode: TreeStructure = {
                    name: overlay.datasetLabel,
                    id: overlay.datasetId,
                    selected: overlay.isSelected || false,
                    params: {
                        field: overlay,
                        showCheckBox: true,
                        dataset: item,
                        callType: "tesadataGroup",
                    },
                    };
                    groupNode.children.push(overlayNode);
                }
            }

            result.push(groupNode);
        }

        return result;
    }

    formatInterractiveOverlays() {
        let interactiveOverlaysGroups: ImageOverlaysGroup = interactiveOverlaysGroup;
        interactiveOverlaysGroups.overlays = _.orderBy(
            interactiveOverlaysGroups.overlays,
            "name"
        );

        let sortData = [];
        sortData.push({
            name: interactiveOverlaysGroups.name,
            params: {
                callType: 'interactiveOverlay'
            },
            children: this.getOverlays(interactiveOverlaysGroups.overlays),
        });
        return sortData;
    }

    private getOverlays(overlays: ImageOverlay[]) {
        let prepareOverlays = [];
        overlays.forEach(element => {
            prepareOverlays.push({
                id: element.id,
                name: element.name,
                selected: false,
                params: {
                    field: element,
                    showCheckBox: true,
                    dataset: element,
                    callType: "interactiveOverlays",
                },
            });
        });
        return prepareOverlays;
    }
}
