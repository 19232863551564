import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EventNotificationSettings } from "../../model/notifications/event-notification-settings";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class EventNotificationService {
    constructor(private readonly http: HttpClient) {}

    getNotificationsForMember(
        memberId: number
    ): Observable<EventNotificationSettings[]> {
        return this.http.get<EventNotificationSettings[]>(
            `/notifications/member/${memberId}`
        );
    }

    createNotification(
        request: EventNotificationSettings
    ): Observable<EventNotificationSettings> {
        return this.http.post<EventNotificationSettings>(
            `/notifications`,
            request
        );
    }

    updateNotification(
        request: EventNotificationSettings
    ): Observable<EventNotificationSettings> {
        return this.http.put<EventNotificationSettings>(
            `/notifications`,
            request
        );
    }

    deleteNotification(notificationsIds: number[]): Observable<void> {
        return this.http.delete<void>(
            `/notifications?notificationIds=${notificationsIds}`
        );
    }

    getCurrencies(): Observable<string[]> {
        return this.http.get<string[]>(`/notifications/currencies`);
    }

    createComplexNotification(
        request: EventNotificationSettings
    ): Observable<EventNotificationSettings> {
        return this.http.post<EventNotificationSettings>(
            `/notifications/complex`,
            request
        );
    }

    updateComplexNotification(
        request: EventNotificationSettings
    ): Observable<EventNotificationSettings> {
        return this.http.put<EventNotificationSettings>(
            `/notifications/complex`,
            request
        );
    }

    getNotificationsWithMMSI(
        memberId: number,
        MMSI: string
    ): Observable<EventNotificationSettings[]> {
        return this.http.get<EventNotificationSettings[]>(
            `/notifications/member/${memberId}/${MMSI}`
        );
    }
}
