<div class="form-container">
    <form [formGroup]="createGroupForm">
        <mat-form-field>
            <mat-label>Group Name</mat-label>
            <input formControlName="name" matInput autocomplete="off"/>
            <mat-error *ngIf="createGroupForm.controls.name.hasError('required') ">
                Group name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="showParentGroup()">
            <mat-label>Parent Group</mat-label>
            <mat-select formControlName="parentID">
                <mat-option *ngFor="let group of groups" [value]="group.id">
                    {{ group.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <button class="full-width" mat-flat-button color="accent" [disabled]="!createGroupForm.controls.name.valid || !createGroupForm.controls.type.valid"
            (click)="createGroup()">
        Create
    </button>
</div>
